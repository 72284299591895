import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import translations from '../../../translations/i18next';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';

const styles = (theme) => ({
  cssOutlinedInput: {
    borderColor: 'white',
    color: '#80888F',
    height: 40,
    letterSpacing: 1,
    backgroundColor: 'white',
    '&$cssFocused $notchedOutline': {
      borderColor: 'white',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: 'white',
    },
  },
  disabled: {},
  focused: {},
  error: {
    borderColor: 'red',
  },
  notchedOutline: {
    borderColor: 'white',
  },
  cssFocused: {},
  inputLabel: {
    fontFamily: 'Circular Book !important',
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = translations.t('forms.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.username)) {
    errors.username = translations.t('forms.emailNotValid');
  }

  if (!values.password) {
    errors.password = translations.t('forms.required');
  }
  return errors;
};

const LoginForm = ({ classes }) => (
  <div style={{ width: '100%' }}>
    <Field
      name="username"
      variant="outlined"
      component={MDTextInputField}
      onFormatText={(text) => text.toLowerCase()}
      placeholder={translations.t('login.insertEmail')}
      showErrorLabel
      label="Username"
      InputLabelProps={{
        shrink: true,
      }}
    />
    <div id="circular-bold-label" style={{ marginTop: 36 }} />
    <Field
      name="password"
      component={MDTextInputField}
      type="password"
      variant="outlined"
      placeholder={translations.t('login.insertPassword')}
      showErrorLabel
      label="Passoword"
      InputLabelProps={{
        shrink: true,
      }}
    />
  </div>
);

export default connect()(
  withStyles(styles)(
    reduxForm({
      form: 'LoginForm',
      validate,
    })(LoginForm),
  ),
);
