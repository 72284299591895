import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider } from '@material-ui/core';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import translations from '../../translations/i18next';
import RadarChart from '../Charts/RadarChart';
import MDAccordion from '../MDAccordion/MDAccordion';
import Spinner from '../Spinner/Spinner';
import StarRateView from '../StarRateView/StarRateView';
import { COMPONENT_BACKGROUND, EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import SkillDescription from './SkillDescription';
import SubSkillDescription from './SubSkillDescription';
import ChangeViewButton from '../Buttons/ChangeViewButton';
import ListSkillChart from '../Charts/ListSkillChart';
import DownloadPdfButton from '../Buttons/DownloadPdfButton';
import moment from 'moment';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import { captureChart, createReportLinkAndDownload } from '../../utils/Report/ReportUtils';
import { UserPdfReport } from './Report/UserPdfReport';
import InstructionLinkButton from '../Buttons/InstructionLinkButton';

class BaseUserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRadarView: true
    };
  }

  async componentDidMount() {
    const { dispatch, user } = this.props;
    try {
      const skills = await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      await dispatch(AnalyticsActions.fetchUserSkillsAnalytics(null, user && user.id));

      const subSkillsAnalyticsPromises = _.map(skills, async (skill) =>
        dispatch(AnalyticsActions.fetchUserSubSkillsAnalytics(skill.id))
      );
      await Promise.all(subSkillsAnalyticsPromises);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async generatePdfAndDownload(data, user) {
    const { dispatch } = this.props;

    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      const chart = await captureChart(document.getElementById(`chart`));
      const doc = <UserPdfReport data={{ ...data, user, chart }} />;
      await createReportLinkAndDownload(
        doc,
        `Edulai Report ${user.name} ${user.surname} ${moment().format('LLL')}.pdf`
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CREATE_PDF_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('analytics.errorCreatePDF')
          }
        })
      );
    }
  }

  render() {
    const {
      skills: {
        data: { content: skillsData },
        subSkills
      },
      userData,
      analytics: { user: userAnalytics }
    } = this.props;
    const { isLoading } = this.state;

    const subSkillsFlat = _(subSkills).flatten().groupBy('parentId').value();

    const skillsAnalytics = _.map(skillsData, (skill) => {
      const current = _.find(userAnalytics.skills, { skillId: skill.id });
      return {
        name: skill.name,
        id: skill.id,
        value: current ? current.score : 0
      };
    });
    const userSelfAssessmentData =
      userData && userData.selfAssessmentDTO && userData.selfAssessmentDTO.skillSelfAssessmentDTOSet
        ? userData.selfAssessmentDTO.skillSelfAssessmentDTOSet
        : [];

    const chartDatasets = {
      datasets: [
        {
          backgroundColor: 'rgba(72, 61, 139, 0.2)',
          borderColor: EDULAI_PURPLE,
          pointBackgroundColor: EDULAI_PURPLE,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.currentScores'),
          data: _.map(
            _.orderBy(skillsAnalytics, (skill) => skill.id),
            (item) => item.value
          )
        },
        {
          backgroundColor: 'rgba(245, 125, 36,0.2)', // It is the same orange with opacity
          borderColor: EDULAI_ORANGE,
          pointBackgroundColor: EDULAI_ORANGE,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.selfAssessmentScores'),
          data: _.map(
            _.orderBy(userSelfAssessmentData, (skill) => skill.skillId),
            (item) => item.score
          )
        }
      ],
      labels: _.map(
        _.orderBy(skillsAnalytics, (skill) => skill.id),
        (item) => item.name
      )
    };
    return (
      <div style={{ backgroundColor: COMPONENT_BACKGROUND }}>
        {isLoading && (
          <Spinner
            title={translations.t('general.loading')}
            hideLogo
            spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
            titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
          />
        )}
        {!isLoading && (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 8,
              margin: isMobileBrowser() ? 20 : 40,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <div style={{ marginTop: 30, alignSelf: 'flex-end', marginRight: 60 }}>
              <DownloadPdfButton
                onClick={() =>
                  this.generatePdfAndDownload(
                    {
                      skillsAnalytics,
                      skillsData,
                      subSkillsFlat,
                      subSkills: userAnalytics['subskills'],
                      selfAssessmentData: userSelfAssessmentData,
                      language: this.props.language
                    },
                    userData
                  )
                }
              />
            </div>
            <div
              style={{
                backgroundColor: '#F9FAFB',
                width: '90%',
                alignSelf: 'center',
                margin: 40,
                marginTop: 0,
                borderRadius: 8,
                padding: 20
              }}
            >
              {this.state.isRadarView ? (
                <RadarChart testId="chart" data={chartDatasets} min={0} max={5} stepSize={1} />
              ) : (
                <div style={{ width: '100%' }}>
                  <ListSkillChart
                    testId="chart"
                    skillsAnalytics={skillsAnalytics}
                    selfAssessmentData={_.orderBy(userSelfAssessmentData, (skill) => skill.skillId)}
                  />
                </div>
              )}
            </div>
            <ChangeViewButton onClick={() => this.setState({ isRadarView: !this.state.isRadarView })} />
            <SkillDescription
              skillsAnalytics={skillsAnalytics}
              selfAssessmentData={userSelfAssessmentData}
              style={{ paddingLeft: isMobileBrowser() ? 10 : 50, paddingRight: isMobileBrowser() ? 10 : 50 }}
              testId="skills-description"
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                paddingLeft: isMobileBrowser() ? 10 : 60,
                paddingRight: isMobileBrowser() ? 10 : 60
              }}
            >
              <h2 style={{ color: EDULAI_PURPLE }}>{translations.t('analytics.subskillsScores')}</h2>
              <InstructionLinkButton />
            </div>
            <div
              style={{
                width: '100%',
                paddingLeft: isMobileBrowser() ? 10 : 60,
                paddingRight: isMobileBrowser() ? 10 : 60,
                paddingBottom: 40
              }}
            >
              {_.map(skillsData, (skill) => {
                const skillData = _.find(skillsAnalytics, (d) => d.id === skill.id);
                const skillTotalPoints = skillData && skillData.value ? parseFloat(skillData.value).toFixed(1) : 0;
                return (
                  <MDAccordion
                    testId={`test-${skill.id}`}
                    title={skill.name}
                    containerstyle={{ marginTop: 10, boxShadow: 'none' }}
                    accordionStyle={{ boxShadow: 'none', border: '1px solid #D1D5DB' }}
                    titleStyle={{ fontSize: 20, color: EDULAI_PURPLE }}
                    key={skill.id}
                    subtitle={
                      <StarRateView
                        value={skillTotalPoints}
                        totalValues={5}
                        starStyle={{ width: 20 }}
                        unselectedStarStyle={{ width: 20 }}
                        titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0 }}
                      />
                    }
                  >
                    <SubSkillDescription
                      subSkills={subSkillsFlat[skill.id]}
                      userAnalytics={userAnalytics['subskills'][skill.id]}
                      skill={skill}
                    />
                    <Divider />
                    <div style={{ padding: 10, backgroundColor: '#FAFBFB' }}>
                      {_.map(
                        _.uniqBy(subSkillsFlat[skill.id], (s) => s.id),
                        (subSkill) => {
                          const subSkillValue = _.find(userAnalytics.subskills[skill.id], { skillId: subSkill.id });
                          return (
                            <div key={`subSkillsPoints-${subSkill.id}`} style={{ marginBottom: 20 }}>
                              <StarRateView
                                key={subSkill.id}
                                title={subSkill.name}
                                value={subSkillValue ? subSkillValue.score : 0}
                                totalValues={5}
                                starStyle={{ width: 20 }}
                                unselectedStarStyle={{ width: 20 }}
                                starContainerStyle={{ marginBottom: 15, marginTop: 10 }}
                                titleContainerStyle={{ marginBottom: 10 }}
                                titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </MDAccordion>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  analytics: state.analytics,
  skills: state.skills,
  userData: state.user.data
});

export default connect(mapStateToProps)(BaseUserAnalytics);
