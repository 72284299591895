import { Slider, withStyles } from '@material-ui/core';
import { EDULAI_BLUE } from '../../styles/styleConsts';

export default withStyles({
  root: {
    color: EDULAI_BLUE,
    height: 8
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%  - 1px)',
    top: -20,
    '& *': {
      background: 'transparent',
      color: '#000'
    }
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);
