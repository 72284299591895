import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import translations from '../../../../translations/i18next';

const CreatableView = ({
  onHandleChange,
  onLoadOptions,
  onValueClick,
  onNewOption,
  multi,
  input,
  noOptionsMessage,
  loadingMessage,
  placeholder,
  defaultOptions,
}) => (
  <AsyncCreatableSelect
    {...input}
    isMulti={multi}
    defaultOptions={defaultOptions || []}
    noOptionsMessage={noOptionsMessage || (() => translations.t('forms.noOptions'))}
    loadingMessage={loadingMessage || (() => translations.t('forms.loadingOptions'))}
    formatCreateLabel={(value) => `${translations.t('forms.createNewOption')} "${value}"`}
    placeholder={placeholder}
    loadOptions={(value) => onLoadOptions(value, input)}
    onCreateOption={(data) => onNewOption(data)}
    onValueClick={(value, actionType) => onValueClick(value, actionType)}
    onChange={(value) => onHandleChange(value, input)}
    onBlur={() => input.onBlur(input.value)}
    styles={{
      menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
      control: (styles) => ({ ...styles, height: 50 }),
    }} // zindex of the overlay
  />
);

export default CreatableView;
