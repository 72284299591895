//
// ────────────────────────────────────────────────────────────────── I ──────────
//   :::::: R O L E S   R E D U C E R : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────
//


import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_ROLES,
  SAVE_ROLE_PERMISSIONS,
  RESET_ROLES_AND_PERMISSIONS,
} from '../actions/actionTypes/roles';

const initialState = Immutable({
  data: [],
  userPermissions: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ROLES:
      return state.set('data', action.roles);
    case SAVE_ROLE_PERMISSIONS: {
      const index = _.findIndex(state.data, { id: action.roleId });
      if (index !== -1) {
        return state.setIn(['data', index], action.permissions).set('userPermissions', action.permissions.permissions);
      }
      return state.set('userPermissions', action.permissions.permissions);
    }
    case RESET_ROLES_AND_PERMISSIONS:
      return initialState;
    default:
      return state;
  }
}
