//
// ──────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: P L A T F O R M   U S E R S   R E D U C E R : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────
//


import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_USERS,
  APPEND_USERS,
  UPDATE_USER,
  SAVE_USERS_PAGINATION,
  SET_USERS_FILTER,
  RESET_USERS_FILTERS,
  DELETE_USER,
  RESET_USERS_DATA,
  SET_SELECTED_USER,
  SAVE_BASE_USERS,
  SAVE_BASE_USERS_PAGINATION,
  APPEND_BASE_USERS,
  RESET_BASE_USERS_DATA,
  SAVE_SUPERVISOR_USERS,
  SAVE_USER_TAGS,
  SAVE_USER_TAGS_PAGINATION,
  SET_SELECTED_USER_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  APPEND_USER_TAGS,
  SET_USER_TAGS_FILTER,
  RESET_USERS_TAGS_FILTERS,
  RESET_USERS_TAGS_DATA,
} from '../actions/actionTypes/platformUsers';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  tags: {
    content: [],
    pagination: {},
    filters: {},
  },
  baseUsers: {
    content: [],
    pagination: {},
  },
  supervisors: {
    content: [],
    pagination: {},
  },
  selectedUser: {},
  selectedTag: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_USERS:
      return state.setIn(['data', 'content'], action.users);
    case APPEND_USERS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.users]);
    case DELETE_USER: {
      const users = _.filter(state.data.content, (userState) => userState.id !== action.userId);
      return state.setIn(['data', 'content'], users);
    }
    case SET_SELECTED_USER:
      return state.set('selectedUser', action.user);
    case UPDATE_USER: {
      const index = _.findIndex(state.data.content, (data) => data.id === action.user.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.user);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.user]);
    }
    case SAVE_USERS_PAGINATION:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_USERS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case RESET_USERS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_USERS_DATA:
      return state.setIn(['data', 'content'], []);
    case SAVE_BASE_USERS:
      return state.setIn(['baseUsers', 'content'], action.users);
    case SAVE_BASE_USERS_PAGINATION:
      return state.setIn(['baseUsers', 'pagination'], action.pagination);
    case APPEND_BASE_USERS:
      return state.setIn(['baseUsers', 'content'], [...state.baseUsers.content, ...action.users]);
    case RESET_BASE_USERS_DATA:
      return state.setIn(['baseUsers', 'content'], []).setIn(['baseUsers', 'pagination'], {});
    case SAVE_SUPERVISOR_USERS:
      return state.setIn(['supervisors', 'content'], action.supervisors);
    case SAVE_USER_TAGS:
      return state.setIn(['tags', 'content'], action.tags);
    case APPEND_USER_TAGS:
      return state.setIn(['tags', 'content'], [...state.tags.content, ...action.tags]);
    case SAVE_USER_TAGS_PAGINATION:
      return state.setIn(['tags', 'pagination'], action.pagination);
    case SET_SELECTED_USER_TAG:
      return state.set('selectedTag', action.tag);
    case UPDATE_TAG: {
      const index = _.findIndex(state.tags.content, (data) => data.id === action.tag.id);
      if (index !== -1) {
        return state.setIn(['tags', 'content', index], action.tag);
      }
      return state.setIn(['tags', 'content'], [...state.tags.content, action.tag]);
    }
    case DELETE_TAG: {
      const tags = _.filter(state.tags.content, (tagState) => tagState.id !== action.tagId);
      return state.setIn(['data', 'content'], tags);
    }
    case SET_USER_TAGS_FILTER:
      return state.setIn(['tags', 'filters', action.field], action.value);
    case RESET_USERS_TAGS_FILTERS:
      return state.setIn(['tags', 'filters'], {});
    case RESET_USERS_TAGS_DATA:
      return state.set('tags', initialState.tags);
    default:
      return state;
  }
}
