import React from 'react';
import logo from '../../assets/edulai.png';

const EdulaiLogo = ({ height }) => (
  <img
    src={logo}
    alt="edulai"
    className="logo"
    style={{
      alignSelf: 'center',
      height,
    }}
  />
);

export default EdulaiLogo;
