import { Divider, Grid, withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm, submit } from 'redux-form';
import debounce from 'debounce-promise';
import { USER_ROLES } from '../../../config/configurations';
import * as ModalsActions from '../../../redux/actions/modal.actions';
import * as PlatformUserActions from '../../../redux/actions/platformUsers.actions';
import * as QuizzesActions from '../../../redux/actions/quizzes.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import MDCheckBoxField from '../FormsComponents/MDCheckbox/MDCheckBoxField';
import MDDateTimePickerField from '../FormsComponents/MDDateTimePicker/MDDateTimePickerField';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import SelectableField from '../FormsComponents/SelectableInput/SelectableField';
import SelectableRowField from '../FormsComponents/SelectableRowField/SelectableRowField';
import QuestionDetails from './QuestionDetails';

const validate = (values) => {
  const errors = {};
  const momentEndDate = moment(values.endDate);
  const momentStartDate = moment(values.startDate);
  if (!values.name) {
    errors.name = translations.t('forms.required');
  }
  if (!values.startDate) {
    errors.startDate = translations.t('forms.required');
  }
  if (!values.endDate) {
    errors.endDate = translations.t('forms.required');
  }
  if (moment() > momentStartDate) {
    errors.startDate = translations.t('forms.quizInPast');
  }
  if (momentEndDate - momentStartDate < 3600000 && momentEndDate - momentStartDate > 0) {
    errors.endDate = translations.t('forms.min1hr');
  }
  if (momentEndDate - momentStartDate <= 0) {
    errors.endDate = translations.t('forms.startBeforeEnd');
  }
  if (!values.participants || values.participants.length === 0) {
    errors.participants = translations.t('forms.required');
  }
  if (!values.questions || values.questions.length === 0) {
    errors.questions = translations.t('forms.required');
  }
  return errors;
};

const styles = () => ({
  formContainer: {
    margin: 20,
    paddingBottom: 20
  },
  headerTitle: {
    marginLeft: 20
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f'
  },
  text: {
    fontWeight: 'normal'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

class QuizForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    const {
      dispatch,
      user: { data: user }
    } = this.props;
    try {
      this.debouncedSearch = debounce(async (name) => this.onFilterUsers(name), 500);
      this.setState({ isLoading: true });
      await dispatch(PlatformUserActions.fetchNotDisabledBaseUsers());
      await dispatch(PlatformUserActions.fetchUsersTags());
      if (user && user.role && user.role.name === USER_ROLES.ROOT) {
        await dispatch(PlatformUserActions.fetchSupervisorsUsers());
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onFilterUsers(name) {
    const { dispatch } = this.props;
    const usersData = await dispatch(PlatformUserActions.fetchNotDisabledBaseUsers(0, 100, (name || '').trim()));
    const newOptions = _.map(usersData, (user) => ({
      value: user.id,
      label: `${user.name} ${user.surname}`
    }));
    return newOptions;
  }

  async onFilterSupervisorsUsers(name) {
    const { dispatch } = this.props;
    const usersData = await dispatch(PlatformUserActions.fetchSupervisorsUsers());
    const filteredOptions = _.filter(
      usersData,
      (user) =>
        user.name.toLowerCase().includes(name.toLowerCase()) ||
        user.surname.toLowerCase().includes(name.toLowerCase()) ||
        user.email.toLowerCase().includes(name.toLowerCase())
    );
    const newOptions = _.map(filteredOptions, (user) => ({
      value: user.id,
      label: `${user.name} ${user.surname}`
    }));
    return newOptions;
  }

  onInfoClickHandler(question) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('VIEW_QUESTION_DETAILS', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          hideCancel: true,
          content: <QuestionDetails question={question} />
        }
      })
    );
  }

  async onQuizSubmitHandler() {
    const {
      dispatch,
      quizzes: { selectedQuiz: quiz }
    } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuizzesActions.submitQuiz());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('SUBMIT_QUIZ_SUCCESS_MODAL', {
          modalType: 'MODAL_DIALOG',
          modalProps: {
            title: translations.t('forms.warning'),
            bodyText: translations.t('quizzes.quizSubmitSuccess')
          }
        })
      );
      dispatch(ModalsActions.hideModal('EDIT_QUIZ_MODAL'));
      await dispatch(QuizzesActions.fetchQuizzDetails(quiz));
    } catch (error) {
      dispatch(
        ModalsActions.showModal('SUBMIT_QUIZ_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('quizzes.submitQuizError')
          }
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  async onFilterUserTags(name) {
    const {
      platformUsers: {
        tags: { content: userTags }
      }
    } = this.props;
    const filteredOptions = _.filter(userTags, (tag) => tag.name.toLowerCase().includes(name.toLowerCase()));
    const newOptions = _.map(filteredOptions, (tag) => ({
      value: tag.id, // workaround for creatable component
      label: tag.name
    }));
    return newOptions;
  }

  async onUserTagChange(tags) {
    const { dispatch, form } = this.props;
    const selectedTags = (form.values && form.values.tags) || [];
    const selectedUsers = (form.values && form.values.participants) || [];
    const newTags = tags || [];
    const tag =
      newTags.length > selectedTags.length ? _.difference(newTags, selectedTags) : _.difference(selectedTags, newTags);
    const users = await dispatch(PlatformUserActions.fetchAllUsersByTag(tag[0].value));
    const options = _(users)
      .filter({ enabled: true, role: { name: USER_ROLES.USER } })
      .map((user) => ({
        value: user.id,
        label: `${user.name} ${user.surname}`
      }))
      .value();
    const newParticipants =
      newTags.length > selectedTags.length
        ? _.union([...options, ...selectedUsers])
        : _.remove(selectedUsers, (user) => _.find(options, (option) => option.value !== user.value));

    dispatch(change('QuizForm', 'participants', newParticipants));
  }

  render() {
    const {
      classes,
      edit,
      dispatch,
      form,
      onDeleteQuizHandler,
      platformUsers: {
        baseUsers: { content: usersData },
        supervisors: { content: supervisorsData },
        tags: { content: userTags }
      },
      questions: {
        data: { content: questionsData }
      },
      user: { data: user }
    } = this.props;

    const { isLoading } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.formContainer}>
          <Field
            name="name"
            variant="outlined"
            containerstyle={{ marginBottom: 20 }}
            component={MDTextInputField}
            label={translations.t('forms.title')}
            required
            multiline
          />
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '50%', marginRight: 8 }}>
              <Field
                name="startDate"
                containerstyle={{ marginBottom: 20 }}
                component={MDDateTimePickerField}
                label={translations.t('forms.startDateTime')}
                showErrorLabel
                required
                minDate={Date.now()}
              />
            </div>
            <div style={{ width: '50%', marginLeft: 8 }}>
              <Field
                name="endDate"
                containerstyle={{ marginBottom: 20 }}
                component={MDDateTimePickerField}
                label={translations.t('forms.endDateTime')}
                showErrorLabel
                required
                minDate={Date.now()}
              />
            </div>
          </div>
          <Divider style={{ height: 2, marginBottom: 20, marginTop: 10 }} />
          {!edit && user && user.role && user.role.name === USER_ROLES.ROOT && (
            <div>
              <Field
                name="supervisor"
                containerStyle={{ marginBottom: 20 }}
                bgColor="white"
                component={SelectableField}
                placeholder={translations.t('quizzes.selectSupervisor')}
                title={translations.t('quizzes.selectSupervisor')}
                titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
                mandatory
                onLoadOptions={(name) => this.onFilterSupervisorsUsers(name)}
                defaultOptions={_.map(supervisorsData, (supervisorOption) => ({
                  value: supervisorOption.id,
                  label: `${supervisorOption.name} ${supervisorOption.surname}`
                }))}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 20
                }}
              >
                <WarningIcon style={{ color: 'red' }} />
                <h4
                  style={{
                    fontSize: 15,
                    fontWeight: '100',
                    margin: 0,
                    marginLeft: 10
                  }}
                >
                  {translations.t('quizzes.selectSupervisorDescription')}
                </h4>
              </div>
            </div>
          )}
          <Divider style={{ marginBottom: 20, height: 2 }} />
          <Field
            name="tags"
            title={translations.t('forms.userTag')}
            placeholder={translations.t('forms.userTagPlaceholder')}
            component={SelectableField}
            bgColor="white"
            onSelect={(value) => this.onUserTagChange(value)}
            multi
            titleContainerStyle={{ marginBottom: 10 }}
            titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
            containerstyle={{ marginTop: 20, marginBottom: 20 }}
            onLoadOptions={(name) => this.onFilterUserTags(name)}
            defaultOptions={_.map(userTags, (tag) => ({
              value: tag.id, // workaround for creatable component
              label: tag.name
            }))}
          />
          <Field
            name="participants"
            containerStyle={{ marginBottom: 20, marginTop: 20 }}
            component={SelectableField}
            bgColor="white"
            placeholder={translations.t('quizzes.participants')}
            title={translations.t('quizzes.participants')}
            titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
            multi
            mandatory
            onLoadOptions={this.debouncedSearch}
            defaultOptions={_.map(usersData, (userOption) => ({
              value: userOption.id,
              label: `${userOption.name} ${userOption.surname}`
            }))}
          />
          <Divider style={{ marginBottom: 0, marginTop: 20, height: 2 }} />
          <Field
            name="questions"
            containerstyle={{ marginBottom: 20 }}
            component={SelectableRowField}
            placeholder={translations.t('quizzes.questions')}
            title={translations.t('quizzes.questions')}
            titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
            multi
            mandatory
            onLoadOptions={(name) => this.onFilterQuestions(name)}
            isLoading={isLoading}
            options={questionsData}
            onInfoClick={(question) => this.onInfoClickHandler(question)}
          />
          <p>
            {`${translations.t('quizzes.selectedQuestionsNumber')}: ${
              form.values && form.values.questions ? form.values.questions.length : 0
            }`}
          </p>
          <Divider style={{ marginBottom: 0, marginTop: 20, height: 2 }} />
          <Field
            name="hideScore"
            label={translations.t('quizzes.hideScoresToUsers')}
            title={translations.t('quizzes.hideScores')}
            titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
            component={MDCheckBoxField}
            containerstyle={{ marginTop: 20 }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 20
            }}
          >
            <WarningIcon style={{ color: 'red' }} />
            <h4
              style={{
                fontSize: 15,
                fontWeight: '100',
                margin: 0,
                marginLeft: 10
              }}
            >
              {translations.t('quizzes.hideScoresDescription')}
            </h4>
          </div>
          <Divider style={{ marginBottom: 0, marginTop: 20, height: 2 }} />
          <Field
            name="usersCanDownloadReport"
            label={translations.t('quizzes.usersCanDownloadReport')}
            title={translations.t('quizzes.downloadReport')}
            titleStyle={{ fontSize: 18, color: '#483D8B', fontWeight: 800 }}
            component={MDCheckBoxField}
            containerstyle={{ marginTop: 20 }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
              marginBottom: 20
            }}
          >
            <WarningIcon style={{ color: 'red' }} />
            <h4
              style={{
                fontSize: 15,
                fontWeight: '100',
                margin: 0,
                marginLeft: 10
              }}
            >
              {translations.t('quizzes.downloadReportDescription')}
            </h4>
          </div>
          <Divider style={{ marginBottom: 0, marginTop: 20, height: 2 }} />
          <MDButton
            title={translations.t('forms.saveDraft')}
            backgroundColor={EDULAI_BLUE}
            containerstyle={{
              marginBottom: 20,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={() => dispatch(submit('QuizForm'))}
            buttonStyle={{ width: '40%' }}
          />
          {edit && (
            <Grid container spacing={4}>
              <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <MDButton
                  title={translations.t('forms.delete')}
                  backgroundColor="#FF4500"
                  containerstyle={{ marginBottom: 20 }}
                  onClick={() => onDeleteQuizHandler()}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: 0 }}>
                <MDButton
                  title={translations.t('quizzes.submitDraft')}
                  backgroundColor="#008000"
                  containerstyle={{ marginBottom: 20 }}
                  onClick={() => this.onQuizSubmitHandler()}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  platformUsers: state.platformUsers,
  questions: state.questions,
  form: state.form.QuizForm,
  quizzes: state.quizzes,
  user: state.user
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'QuizForm',
    validate,
    destroyOnUnmount: false
  }),
  withStyles(styles)
])(QuizForm);
