import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import translations from '../../../translations/i18next';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';


const validate = (values) => {
  const errors = {};
  if (!values.newPassword || (values.newPassword && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(values.newPassword))) {
    errors.newPassword = translations.t('forms.passwordValidation');
  }
  if (!values.confirmPassword || (values.confirmPassword && values.newPassword && values.confirmPassword !== values.newPassword)) {
    errors.confirmPassword = translations.t('forms.passwordMustCoincide');
  }
  return errors;
};


const ConfirmPasswordForm = ({ classes }) => (
  <div style={{ marginRight: 0, width: '100%' }}>
    <h4 id="circular-bold-label" style={{ letterSpacing: 1, color: '#80888F', marginBottom: 14, marginLeft: 0, fontSize: 14, marginTop: 26 }}>
      PASSWORD
    </h4>
    <Field
      name="newPassword"
      variant="outlined"
      component={MDTextInputField}
      type="password"
      placeholder={translations.t('login.insertPassword')}
      showErrorLabel
    />
    <h4 id="circular-bold-label" style={{ letterSpacing: 1, color: '#80888F', marginBottom: 14, marginLeft: 0, fontSize: 14, marginTop: 26 }}>
      {translations.t('forms.confirmPasswordTitle').toUpperCase()}
    </h4>
    <Field
      name="confirmPassword"
      component={MDTextInputField}
      type="password"
      variant="outlined"
      placeholder={translations.t('forms.confirmPasswordLabel')}
      showErrorLabel
    />
  </div>
);

export default connect()((reduxForm({
  form: 'ConfirmPasswordForm',
  validate,
})(ConfirmPasswordForm)));
