import { withStyles, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import React from 'react';
import translations from '../../translations/i18next';
import MDButton from '../MDButton/MDButton';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import loginBackground from '../../assets/login.png';

const styles = (theme) => ({
  innerContainer: {
    padding: 30,
    paddingTop: 0,
  },
  actionsContainer: {
    marginBottom: 20,
  },
  bodyText: {
    margin: 0,
    fontWeight: '100',
    fontSize: 18,
  },
  dialogPaper: {
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

function Transition(props) {
  return <Grow {...props} />;
}

class MDSimpleBackgroundDialog extends React.Component {
  onClose() {
    const { onCancel, hideModal } = this.props;
    if (onCancel) {
      onCancel();
    } else {
      hideModal();
    }
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  render() {
    const {
      title,
      bodyText,
      fullWidth,
      fullScreen,
      cancelText,
      content,
      confirmText,
      onConfirm,
      titleStyle,
      bodyTextStyle,
      hideCancel,
      classes,
    } = this.props;
    const isMobile = isMobileBrowser();
    return (
      <Dialog
        open
        fullWidth={fullWidth}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullScreen={fullScreen}
        keepMounted
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zoom: isMobile ? '60%' : 'unset', zIndex: 1251 }}
        classes={{ paper: classes.dialogPaper }}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <IconButton onClick={() => this.onClose()} style={{ position: 'absolute', right: 5, top: 5 }}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
          <DialogTitle id="alert-dialog-slide-title" style={{ marginRight: 40, marginTop: -3 }}>
            <span style={{ fontWeight: 'bold', margin: 0, marginBottom: 20, ...titleStyle }}>{title}</span>
          </DialogTitle>
          <div className={classes.innerContainer}>
            <div id="alert-dialog-slide-description">
              <h5 className={classes.bodyText} style={{ ...bodyTextStyle }}>
                {bodyText || ''}
              </h5>
              {content || ''}
            </div>
          </div>
          <DialogActions className={classes.actionsContainer}>
            {!hideCancel && (
              <MDButton
                title={cancelText || translations.t('modals.close')}
                onClick={() => this.onClose()}
                backgroundColor="#BDBDBD"
                containerstyle={{ margin: 0, marginRight: 20 }}
              />
            )}
            {onConfirm && (
              <MDButton
                title={confirmText || translations.t('modals.confirm')}
                onClick={() => this.onConfirm()}
                backgroundColor={EDULAI_BLUE}
                containerstyle={{ margin: 0, marginRight: 20 }}
              />
            )}
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MDSimpleBackgroundDialog);
