import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FlagIcon from '@material-ui/icons/Language';
import Button from '@material-ui/core/Button';
import translations from '../../translations/i18next';
import * as UtilsActions from '../../redux/actions/utils.actions';

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  button: {
    display: 'block',
  },
  languageIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  primary: {},
  icon: {},
});

class LanguageSelector extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedLanguage: props.language,
    };
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  onToggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  setLanguage(language) {
    const { onSelectLanguage, dispatch } = this.props;
    onSelectLanguage(language);
    this.setState({ selectedLanguage: language });
    dispatch(UtilsActions.setLanguage(language));
    this.onClose();
  } 
  
  render() {
    const { classes, isMobile } = this.props;
    const { isOpen, selectedLanguage } = this.state;
    return (
      <div style={{ position: 'absolute', top: isMobile ? 0 : 20, right: isMobile ? 80 : 40 }}>
        <Button
          fullWidth
          style={{ backgroundColor: 'transparent', paddingTop: 7 }}
          onClick={() => this.onToggle()}
        >
          <FlagIcon style={{ color: 'white' }} />
          <h5 id="circular-black-label" style={{ fontSize: 14, margin: 0, color: 'white', marginLeft: 10 }}>
            {selectedLanguage || 'it'}
          </h5>
        </Button>
        {isOpen && (
          <Paper style={{ position: 'absolute', top: 39, right: 0 }}>
            <MenuList>
              <MenuItem className={classes.menuItem} onClick={() => this.setLanguage('en')}>
                {translations.t('languages.en')}
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={() => this.setLanguage('it')}>
                {translations.t('languages.it')}
              </MenuItem>
            </MenuList>
          </Paper>
        )}
      </div>
    );
  }
}
export default connect()(withStyles(styles)(LanguageSelector));