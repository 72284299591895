import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {
  RESET_SKILLS_DATA,
  RESET_SKILLS_STATE,
  SAVE_OR_UPDATE_SKILL,
  SAVE_SKILLS,
  SAVE_SUB_SKILLS,
  SET_SELECTED_SKILL,
  SET_SELECTED_SUB_SKILL,
  UPDATE_SUB_SKILL,
  UPDATE_SUB_SKILL_IN_SELECTED_SKILL,
  UPDATE_SUB_SKILL_IN_SUBSKILLS,
  RESET_SUB_SKILLS,
} from '../actions/actionTypes/skills';

const initialState = Immutable({
  data: [],
  subSkills: [],
  selectedSkill: null,
  selectedSubSkill: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_SKILLS:
      return state.set('data', action.skills);
    case SAVE_OR_UPDATE_SKILL: {
      const index = _.findIndex(state.data.content, (data) => data.id === action.skill.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.skill);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.skill]);
    }
    case SAVE_SUB_SKILLS: {
      const mainSkillIndex = _.findIndex(state.data.content, (data) => data.id === action.skillId);
      if (mainSkillIndex !== -1) {
        return state.setIn(['data', 'content', mainSkillIndex, 'subSkills'], action.subSkill).set('subSkills', [...state.subSkills, action.subSkill]);
      }
      return state.set('subSkills', [...state.subSkills, action.subSkill]);
    }
    case SET_SELECTED_SKILL:
      return state.set('selectedSkill', action.skill);
    case SET_SELECTED_SUB_SKILL:
      return state.set('selectedSubSkill', action.subSkill);
    case RESET_SKILLS_DATA:
      return state.set('data', []);
    case UPDATE_SUB_SKILL: {
      const parentIndex = _.findIndex(
        state.data.content,
        (data) => data.id === action.subSkill.parentId,
      );
      if (parentIndex !== -1) {
        const subSkillIndex = _.findIndex(
          state.data.content[parentIndex].subSkills,
          (data) => data.id === action.subSkill.id,
        );
        if (parentIndex !== -1) {
          return state.setIn(['data', 'content', parentIndex, 'subSkills', subSkillIndex], action.subSkill);
        }
      }
      return state;
    }
    case UPDATE_SUB_SKILL_IN_SUBSKILLS: {
      const parentIndex = _.findIndex(
        state.subSkills,
        (data) => data[0].parentId === action.subSkill.parentId,
      );
      if (parentIndex !== -1) {
        const subSkillIndex = _.findIndex(
          state.subSkills[parentIndex],
          (data) => data.id === action.subSkill.id,
        );
        if (parentIndex !== -1) {
          return state.setIn(['subSkills', parentIndex, subSkillIndex], action.subSkill);
        }
      }
      return state;
    }
    case UPDATE_SUB_SKILL_IN_SELECTED_SKILL: {
      const selectedSkillIndex = _.findIndex(
        state.selectedSkill.subSkills,
        (data) => data.id === action.subSkill.id,
      );
      if (selectedSkillIndex !== -1) {
        return state.setIn(['selectedSkill', 'subSkills', selectedSkillIndex], action.subSkill);
      }
      return state;
    }
    case RESET_SUB_SKILLS:
      return state.set('subSkills', []);
    case RESET_SKILLS_STATE:
      return initialState;
    default:
      return state;
  }
}
