import Immutable from 'seamless-immutable';

import { SAVE_QUIZ_SUBMISSIONS, SAVE_USER_SUBMISSIONS } from '../actions/actionTypes/submissions';

const initialState = Immutable({
  data: {
    quizzes: {},
    user: [],
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_QUIZ_SUBMISSIONS:
      return state.setIn(['data', 'quizzes', action.quizId], action.submissions);
    case SAVE_USER_SUBMISSIONS:
      return state.setIn(['data', 'user'], action.submissions);
    default:
      return state;
  }
}
