import React from 'react';
import { connect } from 'react-redux';
import Spinner from './Spinner';

const SpinnerOverlay = ({
  utils: {
    spinner: { title, isVisible },
  },
}) => (
  <span>
    {isVisible && (
      <div className="spinner-overlay">
        <Spinner className="inner-spinner-overlay" title={title} />
      </div>
    )}
  </span>
);

const mapStateToProps = (state) => ({
  utils: state.utils,
});

export default connect(mapStateToProps)(SpinnerOverlay);
