import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Bar } from 'react-chartjs-2';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const ScrollableBarChart = (props) => {
  const { data, dataSetSize, xAxeTitle, yAxeTitle, legend } = props;
  let maxWidth = window.screen.width - 280 - 80;
  maxWidth = isMobileBrowser() ? window.screen.width + 100 : maxWidth;
  const chartWidth = dataSetSize * 20 < maxWidth ? maxWidth : dataSetSize * 20;

  return (
    <div className="chartWrapper">
      <PerfectScrollbar style={{ paddingBottom: 15 }}>
        <div className="chartAreaWrapper" style={{ width: chartWidth }}>
          <Bar
            key={chartWidth}
            data={data}
            height={400}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: legend,
                position: 'bottom'
              },
              scales: {
                xAxes: [
                  {
                    categorySpacing: 2,
                    scaleLabel: {
                      display: xAxeTitle,
                      labelString: xAxeTitle
                    }
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      max: 5,
                      min: 0,
                      stepSize: 1
                    },
                    scaleLabel: {
                      display: yAxeTitle,
                      labelString: yAxeTitle
                    }
                  }
                ],
                x: {
                  offset: false,
                  barPercentage: 20, // Adjust this value to change the bar width
                  categoryPercentage: 20 // Adjust the space between bars
                }
              }
            }}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ScrollableBarChart;
