//
// ──────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: R O L E S   A C T I O N   T Y P E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────
//


module.exports = {
  SAVE_ROLES: 'SAVE_ROLES',
  SAVE_ROLE_PERMISSIONS: 'SAVE_ROLE_PERMISSIONS',
  RESET_ROLES_AND_PERMISSIONS: 'RESET_ROLES_AND_PERMISSIONS',
};