export const EDULAI_BLUE_OLD = '#3f65d4';
export const EDULAI_BLUE = '#5048E5';
export const EDULAI_ORANGE = '#F57D24';

export const EDULAI_BACKGROUND_GRADIENT_COLOR = `linear-gradient(to right, ${EDULAI_BLUE}, ${EDULAI_ORANGE})`;

export const EDULAI_BACKGROUND_COLOR = '#282155';

export const EDULAI_BLUE_LIGHT = '#5476d9';
export const EDULAI_BLUE_DARK = '#294db7';
export const RED = '#DB4437';
export const GREEN_LIGHT = '#5AC0B1';
export const EDULAI_PURPLE = '#483d8b';
export const ORANGE = '#ff9800';

export const COMPONENT_BACKGROUND = '#F9FAFB';

export const QUIZ_STATUS_COLORS = {
  PAUSED: EDULAI_ORANGE,
  RUNNING: '#17AD9C',
  READY: EDULAI_ORANGE,
  DRAFT: 'rgb(108, 114, 127)',
  BLOCKED: '#FF4500',
  DONE: EDULAI_PURPLE,
  EXPIRED_FOR_USER: RED,
};
