import { withStyles } from '@material-ui/core/styles';
import SkillsIcon from '@material-ui/icons/DynamicFeed';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, initialize } from 'redux-form';
import SkillForm from '../../components/Forms/SkillsForm/SkillForm';
import ListComponent from '../../components/ListComponents/ListComponent';
import SkillRow from '../../components/ListComponents/RowComponents/SkillRow';
import Spinner from '../../components/Spinner/Spinner';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import NavigationBar from '../NavigationViews/NavigationBar';
import SkillIcon from '../../components/Icons/SkillIcon';
import { EDULAI_BLUE } from '../../styles/styleConsts';

const styles = (theme) => ({
  container: {
    padding: 20
  },
  title: {
    margin: 10
  },
  chartsContainer: {
    marginTop: 10
  },
  noUserContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  noUserText: {
    margin: 0,
    color: '#7F888F'
  },
  noUserIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  listContainer: {
    paddingTop: 25,
    marginTop: 7
  }
});

class SkillsManagementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.containerDiv.scrollIntoView({ behavior: 'smooth' });
      this.setState({ isLoading: true });
      await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onNewSkillClicked() {
    const { dispatch } = this.props;
    dispatch(destroy('SkillForm'));
    dispatch(SkillsActions.setSelectedSkill({}));
    dispatch(
      ModalsActions.showModal('NEW_SKILL_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: <SkillForm onSubmit={(skillData) => this.onOperateSkill(skillData)} />,
          title: translations.t('skills.createNewSkill')
        }
      })
    );
  }

  onOperateSkill(skillData) {
    if (skillData.id) {
      this.onModifySkill(skillData);
    } else {
      this.onCreateNewSkill(skillData);
    }
  }

  async onCreateNewSkill(skill) {
    const { dispatch } = this.props;
    try {
      dispatch(ModalsActions.hideModal('NEW_SKILL_MODAL'));
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(SkillsActions.createSkill(skill));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('NEW_SKILL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.skillCreatedSuccess')
          }
        })
      );
      dispatch(SkillsActions.fetchSkillsAndSubSkills());
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('NEW_SKILL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.skillCreatedError')
          }
        })
      );
    }
  }

  onEditSkillClicked(skill) {
    const { dispatch } = this.props;
    dispatch(initialize('SkillForm', skill));
    dispatch(SkillsActions.setSelectedSkill(skill));
    dispatch(
      ModalsActions.showModal('EDIT_SKILL_MODAL', {
        modalType: 'OPERATIONAL_VIEW',
        modalProps: {
          content: (
            <SkillForm
              onSubmit={(skillData) => this.onOperateSkill({ ...skillData, id: skill.id })}
              onDeleteSkill={() => this.onDeleteSkillRequest(skill)}
            />
          ),
          title: translations.t('skills.systemSkills')
        }
      })
    );
  }

  async onModifySkill(skillData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(SkillsActions.updateSkill(skillData));
      await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('SKILL_UPDATE_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.skillUpdatedSuccess')
          }
        })
      );
      dispatch(ModalsActions.hideModal('EDIT_SKILL_MODAL'));
      dispatch(SkillsActions.setSelectedSkill({}));
    } catch (error) {
      dispatch(
        ModalsActions.showModal('SKILL_UPDATE_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('forms.skillUpdatedError')
          }
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onDeleteSkillRequest(skill) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('DELETE_SKILL_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('forms.skillDeleteWarning'),
          onConfirm: () => this.onDeleteSkill(skill)
        }
      })
    );
  }

  async onDeleteSkill(skill) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(SkillsActions.deleteSkill(skill));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('DELETE_SKILL_MODAL'));
      dispatch(ModalsActions.hideModal('EDIT_SKILL_MODAL'));
      dispatch(
        ModalsActions.showModal('DELETE_SKILL_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.skillDeleteSuccess')
          }
        })
      );
      dispatch(SkillsActions.fetchSkillsAndSubSkills());
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      const message =
        error.response && error.response.data && error.response.data.code === 5004
          ? translations.t('forms.cantDeleteReferencedSkill')
          : translations.t('forms.skillDeleteError');
      dispatch(
        ModalsActions.showModal('DELETE_SKILL_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message
          }
        })
      );
    }
  }

  render() {
    const {
      classes,
      skills: {
        data: { content: skillsData, pagination }
      }
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div
        ref={(c) => {
          this.containerDiv = c;
        }}
      >
        <NavigationBar title={translations.t('skills.systemSkills')} icon={<SkillIcon color="white" />} />
        <div className={classes.container}>
          <ListComponent
            pagination={pagination}
            containerstyle={{
              width: isMobileBrowser() ? 'unset' : '100%',
              paddingLeft: 0,
              overflowY: 'scroll',
              paddingBottom: 180
            }}
            actionCardStyle={{ padding: 20 }}
            newElementText={translations.t('skills.createNewSkill')}
            onLoadMore={(page) => null}
            onCreateNew={() => this.onNewSkillClicked()}
          >
            <div className={classes.listContainer}>
              {!isLoading &&
                _.map(skillsData, (skill) => (
                  <SkillRow
                    key={skill.id}
                    skill={skill}
                    outerContainerstyle={{ marginTop: 15 }}
                    onClick={() => this.onEditSkillClicked(skill)}
                  />
                ))}
              {(!skillsData || _.isEmpty(skillsData)) && !isLoading && (
                <div className={classes.noUserContainer}>
                  <SkillsIcon className={classes.noUserIcon} />
                  <h4 className={classes.noUserText}>{translations.t('skills.noSkillFound')}</h4>
                </div>
              )}
              {isLoading && (
                <Spinner
                  title={translations.t('general.loading')}
                  hideLogo
                  spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
                  titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                />
              )}
            </div>
          </ListComponent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  roles: state.roles,
  skills: state.skills
});
export default connect(mapStateToProps)(withStyles(styles)(SkillsManagementView));
