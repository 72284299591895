import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {
  APPEND_QUIZZES,
  DELETE_QUIZ,
  RESET_QUIZZES_DATA,
  RESET_QUIZZES_FILTERS,
  SAVE_QUIZZES,
  SAVE_QUIZZES_PAGINATION,
  SET_QUIZZES_FILTER,
  SET_SELECTED_QUIZ,
  UPDATE_QUIZ,
} from '../actions/actionTypes/quizzes';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  selectedQuiz: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_QUIZZES:
      return state.setIn(['data', 'content'], action.quizzes);
    case SAVE_QUIZZES_PAGINATION:
      return state.setIn(['data', 'pagination'], action.pagination);
    case UPDATE_QUIZ: {
      const index = _.findIndex(state.data.content, (data) => data.id === action.quizId);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.quiz);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.quiz]);
    }
    case RESET_QUIZZES_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_QUIZZES_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case RESET_QUIZZES_DATA:
      return state.setIn(['data', 'content'], []);
    case APPEND_QUIZZES:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.quizzes]);
    case SET_SELECTED_QUIZ:
      return state.set('selectedQuiz', action.quiz);
    case DELETE_QUIZ:
      return state.setIn(['data', 'content'], _.filter(state.data.content, (data) => data.id !== action.quiz));
    default:
      return state;
  }
}
