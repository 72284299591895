import React from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import LoginView from './MainViews/LoginView';
import SettingsView from './MainViews/SettingsView';
import AppContainer from './NavigationViews/AppContainer';
import { default as AuthRoute } from './NavigationViews/AuthRoute';
import { default as AppRoute } from './NavigationViews/AppRoute';
import SidebarLayout from './NavigationViews/SidebarLayout';
import UsersView from './MainViews/UsersView';
import QuizzesView from './MainViews/QuizzesView';
import QuestionsView from './MainViews/QuestionsView';
import StatisticsView from './MainViews/StatisticsView';
import SkillsManagementView from './MainViews/SkillsManagementView';
import ConfirmRegistrationView from './MainViews/ConfirmRegistrationView';
import ResetPasswordView from './MainViews/ResetPasswordView';
import UserOnboardingView from './UserOnboardingView';
import DomainManagementView from './MainViews/DomainManagementView';
import ConfigComponent from '../components/Config/ConfigComponent';
import DefaultRootView from './NavigationViews/DefaultRootView';
import { isMobileBrowser } from '../utils/utilsFunctions';
import { PDFViewer } from '@react-pdf/renderer';
import { UserPdfReport } from '../components/Analytics/Report/UserPdfReport';

class Viewer extends React.Component {
  render() {
    return (
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <UserPdfReport />
      </PDFViewer>
    );
  }
}

const AppRouter = ({ store }) => (
  <Provider store={store}>
    <Router>
      <ConfigComponent>
        <div className="app-container" style={{ zoom: isMobileBrowser() ? '63%' : '100%' }}>
          <AppRoute path="/login" layout={AppContainer} component={LoginView} />
          <AppRoute path="/activate" layout={AppContainer} component={ConfirmRegistrationView} />
          <AppRoute path="/passwordreset" layout={AppContainer} component={ResetPasswordView} />

          <Switch>
            <AuthRoute path="/report" layout={AppContainer} component={Viewer} />
            <AuthRoute path="/" exact layout={SidebarLayout} component={DefaultRootView} />
            <AuthRoute path="/quizzes" exact layout={SidebarLayout} component={QuizzesView} />
            <AuthRoute path="/users" exact layout={SidebarLayout} component={UsersView} />
            <AuthRoute path="/questions" exact layout={SidebarLayout} component={QuestionsView} />
            <AuthRoute path="/skills" exact layout={SidebarLayout} component={SkillsManagementView} />
            <AuthRoute path="/statistics" exact layout={SidebarLayout} component={StatisticsView} />
            <AuthRoute path="/settings" exact layout={SidebarLayout} component={SettingsView} />
            <AuthRoute path="/domain" exact layout={SidebarLayout} component={DomainManagementView} />
            <AuthRoute path="/userOnboarding" layout={AppContainer} component={UserOnboardingView} />
          </Switch>
        </div>
      </ConfigComponent>
    </Router>
  </Provider>
);

export default connect()(AppRouter);
