import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';
import { Check } from '@material-ui/icons';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});

const MDCheckBoxView = ({ checked, label, disabled, labelStyle, subtitleStyle, subtitle, onSelect }) => (
  <MuiThemeProvider theme={theme}>
    <div>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={checked}
            disabled={disabled}
            onChange={() => onSelect(!checked)}
            value="gilad"
            icon={
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  backgroundColor: '#fff',
                  border: '1px solid #6B7280',
                  borderRadius: '2px',
                }}
              />
            }
            checkedIcon={
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  backgroundColor: EDULAI_BLUE,
                  border: '1px solid #6B7280',
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Check style={{ color: 'white', fontSize: 16 }} />
              </div>
            }
          />
        }
        label={<h5 style={{ margin: 0, ...labelStyle }}>{label}</h5>}
      />
      {subtitle && <h5 style={{ marginTop: -15, marginLeft: 35, ...subtitleStyle }}>{subtitle}</h5>}
    </div>
  </MuiThemeProvider>
);

export default MDCheckBoxView;
