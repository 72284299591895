import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import translations from '../../../translations/i18next';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import { EDULAI_BLUE } from '../../../styles/styleConsts';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = translations.t('forms.required');
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: 20,
    marginTop: 20
  },
  title: {
    margin: 0,
    marginTop: 20
  },
  warningText: {
    fontWeight: 'normal',
    marginLeft: 10
  },
  headerTitle: {
    marginLeft: 20
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    secondary: { main: '#CC0033' }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

const EditSkillForm = ({ classes, dispatch }) => (
  <MuiThemeProvider theme={theme}>
    <h2 className={classes.headerTitle}>{translations.t('forms.editSkillName')}</h2>
    <div className={classes.formContainer}>
      <Field
        name="name"
        component={MDTextInputField}
        containerstyle={{ marginBottom: 20 }}
        label={translations.t('forms.skillName')}
        variant="outlined"
        required
      />
    </div>
  </MuiThemeProvider>
);

const mapStateToProps = (state) => ({
  form: state.form.SubSkillForm
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'EditSkillForm',
    validate,
    destroyOnUnmount: false
  }),
  withStyles(styles)
])(EditSkillForm);
