//
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A B I L I T Y   H E L P E R   F O R   C H E C K   P E R M I S S I O N S   A N D   R O L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//


import { AbilityBuilder } from '@casl/ability';
import _ from 'lodash';

export default class AbilityDomainHelper {
  ability = AbilityBuilder.define((can) => {});

  userRole = null;

  setUserRole(role) {
    this.userRole = role;
  }

  getAbility() {
    return this.ability;
  }

  updateAbilities(abilities) {
    this.ability.update(abilities);
  }

  hasRolesAndPermissions(roles, checkPermissions, entity) {
    if (!this.hasRoles(roles)) {
      return false;
    }

    return this.hasPermission(checkPermissions, entity);
  }

  hasPermission(checkPermissions, entity) {
    for (let i = 0; i < checkPermissions.length; i += 1) {
      if (this.ability.can(checkPermissions[i], entity)) return true;
    }
    return false;
  }

  hasRoles(roles) {
    if (!this.userRole) return false;
    return _.includes(roles, this.userRole);
  }
}
