import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import React from 'react';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';

const MDRadioRateView = ({
  value,
  totalValues,
  minimumValue,
  title,
  titleStyle,
  onSelectValue,
  starContainerStyle,
}) => {
  const radioStyle = {
    width: '18px',
    height: '18px',
    border: '1px solid #6B7280',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '5px',
  };

  const innerStyle = {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    backgroundColor: EDULAI_BLUE,
    transition: 'background-color 0.3s ease',
  };

  return (
    <div style={{ ...starContainerStyle }}>
      {title && <h4 style={{ margin: 0, marginBottom: 10, ...titleStyle }}>{title}</h4>}
      {_.times(totalValues, (currentValue) => {
        const isSelected = currentValue + 1 <= value || currentValue + 1 === minimumValue;
        return (
          <IconButton
            style={{ padding: isSelected ? 6 : 6 }}
            key={currentValue}
            onClick={onSelectValue ? () => onSelectValue(currentValue + 1) : null}
          >
            {isSelected ? (
              <div style={radioStyle}>
                <div style={innerStyle} />
              </div>
            ) : (
              <div style={radioStyle}>
                <div style={{ ...innerStyle, backgroundColor: 'transparent' }} />
              </div>
            )}
          </IconButton>
        );
      })}
    </div>
  );
};

export default MDRadioRateView;
