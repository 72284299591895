import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import layout from '../../../assets/layout_report.png';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations, { translationExists } from '../../../translations/i18next';
import _, { find, map } from 'lodash';
import { getEnvironment, SKILLS_MAP } from '../../../config/configurations';
import Footer from './Footer';
import Header from './Header';
import ReportText from './ReportText';
import { joinWithCommas, showUINumber } from '../../../utils/Report/ReportUtils';
import Stars from './Stars';
import ReportTextHtml from './ReportTextHtml';

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1
  },
  backgroundContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#5048E5',
    opacity: 0.12
  },
  section: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#fafafa'
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    color: EDULAI_BLUE,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
    color: EDULAI_PURPLE
  },
  rightColumn: {
    fontSize: 40,
    textAlign: 'center',
    color: EDULAI_BLUE,
    fontWeight: 'bold'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftColumn: {
    flex: 0.8
  },
  rightColumnContainer: {
    flex: 0.5,
    display: 'flex',
    justifyContent: 'center'
  },
  headerTable: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerText: {
    fontSize: 11,
    fontWeight: 'bold',
    color: 'white',
    marginLeft: 10
  },
  skillNameTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'white',
    marginLeft: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    borderColor: '#f1f1f3',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    backgroundColor: 'white',
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  textColumn: {
    flex: 3
  },
  ratingColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  stars: {
    flexDirection: 'row'
  },
  starImage: {
    width: 10,
    height: 10,
    marginHorizontal: 2
  },
  starImageUnselected: {
    width: 9,
    height: 9,
    marginHorizontal: 2
  },
  textRight: {
    fontSize: 12,
    color: EDULAI_BLUE,
    fontWeight: 'bold',
    marginLeft: 10
  }
});

const SkillsTablePage = ({ skillsAnalytics, subSkills, subSkillsAnalytics, skill, user, language, index }) => {
  const skillData = _.find(skillsAnalytics, (d) => d.id === skill.id);
  const skillTotalPoints = skillData && skillData.value ? parseFloat(skillData.value).toFixed(1) : 0;

  const highScores = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(subSkillsAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score >= 4;
  });

  const lowScores = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(subSkillsAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score <= 2 && subSkillValue.score > 0;
  });

  const threeScoresList = _.filter(subSkills, (skill) => {
    const subSkillValue = _.find(subSkillsAnalytics, { skillId: skill.id });
    return subSkillValue && subSkillValue.score && Number(subSkillValue.score) > 2 && Number(subSkillValue.score) < 4;
  });

  const skillTranslationKey = _.findKey(SKILLS_MAP[getEnvironment()], (sk) => sk == skill.id);

  return (
    <>
      <Image style={styles.backgroundImage} src={layout} fixed />
      <Header user={user} />
      {index === 0 ? (
        <ReportText style={{ marginBottom: 25, marginTop: 5, color: EDULAI_BLUE, fontSize: 12, fontWeight: 'bold' }}>
          {translations.t('skillsReport.skillsResultsTitle')}
        </ReportText>
      ) : (
        <View style={{ marginTop: 10 }} />
      )}
      <View style={{ justifyContent: 'center' }}>
        <View style={styles.headerTable}>
          <View style={{ flex: 0.8 }}>
            <View style={{ paddingRight: 3 }}>
              <View style={{ backgroundColor: EDULAI_PURPLE, padding: 10, borderTopLeftRadius: 8 }}>
                <ReportText style={styles.skillNameTitle}>{skill.name.toUpperCase()}</ReportText>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: 0.5,
              height: '100%',
              backgroundColor: EDULAI_BLUE,
              paddingTop: 10,
              paddingBottom: 10,
              borderTopRightRadius: 8
            }}
          >
            <ReportText style={styles.headerText}>{translations.t('skillsReport.skillsResultsValueTitle')}</ReportText>
          </View>
        </View>
        <View style={styles.mainContainer}>
          <View style={styles.leftColumn}>
            <View style={{ paddingRight: 2, paddingBottom: 3 }}>
              <View style={styles.section}>
                <ReportText style={styles.title}>1) {translations.t(`skillsReport.strenghts`)}</ReportText>
                {highScores.length ? (
                  <ReportTextHtml style={styles.text}>
                    {translations.t('skillsReport.subSkillStrenghtMessage', {
                      subSkills: joinWithCommas(_.map(highScores, 'name'), language)
                    })}
                  </ReportTextHtml>
                ) : (
                  <ReportText style={styles.text}>{translations.t(`skillsReport.noStrenghts`)}</ReportText>
                )}
              </View>
            </View>

            <View style={{ paddingRight: 2, paddingBottom: 3 }}>
              <View style={styles.section}>
                <ReportText style={styles.title}>2) {translations.t(`skillsReport.weaknesses`)}</ReportText>
                {lowScores.length || threeScoresList.length ? (
                  <>
                    {lowScores.length ? (
                      <ReportTextHtml style={styles.text}>
                        {translations.t(`skillsReport.subSkillWeaknessLowMessage`, {
                          subSkills: joinWithCommas(_.map(lowScores, 'name'), language)
                        })}
                      </ReportTextHtml>
                    ) : null}
                    {threeScoresList.length !== 0 ? (
                      <ReportTextHtml style={styles.text}>
                        {translations.t(`skillsReport.subSkillWeaknessMediumMessage`, {
                          subSkills: joinWithCommas(_.map(threeScoresList, 'name'), language)
                        })}
                      </ReportTextHtml>
                    ) : null}
                  </>
                ) : (
                  <ReportText style={styles.text}>{translations.t(`skillsReport.noWeaknesses`)}</ReportText>
                )}
              </View>
            </View>

            <View style={{ paddingRight: 2 }}>
              <View style={styles.section}>
                <ReportText style={styles.title}>3) {translations.t(`skillsReport.areasDevelopments`)}</ReportText>
                {(lowScores.length || threeScoresList.length) &&
                translationExists(`skillsReport.${skillTranslationKey}`) ? (
                  <ReportText style={styles.text}>{translations.t(`skillsReport.${skillTranslationKey}`)}</ReportText>
                ) : (
                  <ReportText style={styles.text}>{translations.t(`skillsReport.noAreasDevelopments`)}</ReportText>
                )}
              </View>
            </View>
          </View>

          <View style={styles.rightColumnContainer}>
            <View style={styles.backgroundContainer} />
            <ReportText style={styles.rightColumn}>{translations.t('forms.total')}</ReportText>
            <ReportText style={styles.rightColumn}>{showUINumber(skillTotalPoints)}</ReportText>
          </View>
        </View>
      </View>

      {map(subSkills, (subSkill, index) => {
        const subSkillValue = find(subSkillsAnalytics, { skillId: subSkill.id });
        return (
          <View key={index} style={[styles.row, index === 0 && { marginTop: 20 }]} minPresenceAhead={60}>
            <View style={styles.textColumn}>
              <View style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 25 }}>
                <ReportText style={{ fontSize: 10 }}>{index + 1}. </ReportText>
                <ReportText style={{ fontSize: 10 }}>{subSkill.name}</ReportText>
              </View>
            </View>
            <View style={styles.ratingColumn}>
              <Stars rating={showUINumber((subSkillValue && subSkillValue.score) || 0)} />
              <ReportText style={styles.textRight}>
                {showUINumber((subSkillValue && subSkillValue.score) || 0)}
              </ReportText>
            </View>
          </View>
        );
      })}
      <Footer />
    </>
  );
};

export default SkillsTablePage;
