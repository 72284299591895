import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import RoleIcon from '@material-ui/icons/VerifiedUserOutlined';
import { Button, Chip, Divider } from '@material-ui/core';
import translations from '../../translations/i18next';
import MDSelectView from '../Forms/FormsComponents/MDSelectedField/MDSelectView';
import { LANGUAGE_LOCAL_MAP } from '../../config/configurations';
import { EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import { ExitToApp } from '@material-ui/icons';

const styles = (theme) => ({
  cardContainer: {
    padding: 20,
    margin: 20,
    borderRadius: 8,
  },
  title: {
    margin: 0,
    color: EDULAI_PURPLE,
  },
  subtitle: {
    color: '#3f3f3f',
    fontWeight: '100',
    margin: 0,
    fontSize: 18,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
  },
  roleIcon: {
    color: 'white',
    marginRight: 10,
  },
});

const UserProfileCard = ({ classes, userData, onLogout, language, onChangeLanguage }) => {
  const languageOptions = _.map(LANGUAGE_LOCAL_MAP, (language) => ({
    label: `${translations.t(`languages.${language.translation}`)}`,
    value: `${language.key}`,
  }));
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{translations.t('user.profile')}</h1>
        <Button
          variant="contained"
          style={{ backgroundColor: EDULAI_BLUE, color: 'white', borderRadius: 8 }}
          startIcon={<ExitToApp />}
          onClick={() => onLogout()}
        >
          Logout
        </Button>
      </div>
      <Divider style={{ height: 1, marginTop: 30, marginBottom: 30, backgroundColor: '#D1D5DB' }} />
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <h4 className={classes.title}>{`${userData.name} ${userData.surname}`}</h4>
        <Chip
          avatar={<RoleIcon className={classes.roleIcon} style={{ color: 'white' }} />}
          label={translations.t(`roles.${userData.role.name}`)}
          style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: EDULAI_ORANGE, color: 'white', marginLeft: 20 }}
        />
      </div>
      <Divider style={{ height: 1, marginTop: 30, marginBottom: 30, backgroundColor: '#D1D5DB' }} />
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <h4 className={classes.title}>Email</h4>
        <h5 className={classes.subtitle} style={{ marginLeft: 20 }}>
          {userData.username}
        </h5>
      </div>
      <Divider style={{ height: 1, marginTop: 30, marginBottom: 30, backgroundColor: '#D1D5DB' }} />
      <div style={{ width: '50%', marginTop: 20 }}>
        <MDSelectView
          title={translations.t('languages.selectLanguage')}
          options={languageOptions}
          value={language}
          onSelect={(languageSelected) => onChangeLanguage(languageSelected)}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(UserProfileCard));
