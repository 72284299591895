import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { EDULAI_PURPLE } from '../../../styles/styleConsts';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    borderLeft: `3px solid ${EDULAI_PURPLE}`
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  },
  titleContainer: {
    alignItems: 'center'
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f'
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 16
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

const CustomFieldRow = ({ customField: { title, body }, onClick, onDelete, classes }) => (
  <MuiThemeProvider theme={theme}>
    <Card className={classes.outerContainer}>
      <CardActionArea className={classes.eventContainer} onClick={() => onClick()}>
        <div>
          <h3 className={classes.title}>{title}</h3>
          <h3 className={classes.body} style={{ margin: 0 }}>
            {body}
          </h3>
        </div>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardActionArea>
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(CustomFieldRow));
