import { Divider, Tooltip, withStyles } from '@material-ui/core';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as AnswersActions from '../../redux/actions/answers.actions';
import translations from '../../translations/i18next';
import Spinner from '../Spinner/Spinner';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../styles/styleConsts';

const styles = () => ({
  noMaxWidth: {
    maxWidth: 800
  }
});

class UserAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    const { dispatch, submission } = this.props;
    try {
      this.setState({ isLoading: true });
      await dispatch(AnswersActions.fetchAnswersBySubmissionId(submission.id));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      classes,
      quiz,
      submission,
      answers: { submissions: answersData }
    } = this.props;
    const { isLoading } = this.state;

    return (
      <div style={{ padding: 10 }}>
        {isLoading && (
          <Spinner
            customStyle={{ margin: 'auto' }}
            title={translations.t('general.loading')}
            hideLogo
            spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
            titleStyle={{ color: '#80888d', marginTop: 5, fontSize: 12 }}
          />
        )}
        {!isLoading &&
          _.map(answersData[submission.id], (answer) => {
            const question = _.find(quiz.questions, { id: answer.questionId });
            if (!question || !question.options) return null;
            const answerData = _.find(question.options.optionDTOS, { optionNumber: answer.optionNumber });
            return (
              <div key={answer.id}>
                <Tooltip title={question.body} arrow placement="right" classes={{ tooltip: classes.noMaxWidth }}>
                  <h3 style={{ display: 'inline', color: EDULAI_PURPLE }}>{`${translations.t('submissions.answer')} ${
                    question.name
                  }`}</h3>
                </Tooltip>
                <h3 style={{ fontWeight: '100', marginTop: 5 }}>{answerData && answerData.body}</h3>
                <Divider style={{ marginBottom: 20, height: 2 }} />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
  questions: state.questions
});

export default connect(mapStateToProps)(withStyles(styles)(UserAnswers));
