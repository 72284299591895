import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';

const radioStyle = {
  width: '18px',
  height: '18px',
  border: '1px solid #6B7280',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '5px',
};

const innerStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  backgroundColor: EDULAI_BLUE,
  transition: 'background-color 0.3s ease',
};

const MDRadioButtonView = ({ checked, colorIndex, label, value, handleChange, labelStyle }) => (
  <FormControlLabel
    checked={checked}
    color={colorIndex || 'primary'}
    value={value}
    onChange={(e) => handleChange(e)}
    control={
      <Radio
        icon={
          <div style={radioStyle}>
            <div style={{ ...innerStyle, backgroundColor: 'transparent' }} />
          </div>
        }
        checkedIcon={
          <div style={radioStyle}>
            <div style={innerStyle} />
          </div>
        }
      />
    }
    label={<h5 style={{ ...labelStyle }}>{label}</h5>}
  />
);

export default MDRadioButtonView;
