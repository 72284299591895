import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import WarningIcon from '@material-ui/icons/Warning';
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loginBackground from '../../assets/login.png';
import EdulaiLogo from '../../assets/logo.png';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import MDButton from '../MDButton/MDButton';

class NoServiceView extends React.Component {
  onChangeLanguage(language) {
    i18next.changeLanguage(language);
  }

  render() {
    return (
      <div
        style={{
          height: '109vh',
          paddingTop: 20,
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
        }}
      >
        <LanguageSelector
          language={i18next.language}
          onSelectLanguage={(language) => this.onChangeLanguage(language)}
        />
        <Card
          style={{
            width: '50%',
            margin: 'auto',
            padding: 10,
            paddingBottom: 0,
            marginTop: 180,
            borderRadius: 10,
          }}
        >
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={EdulaiLogo}
                alt="edulai"
                className="logo"
                style={{ margin: 10, marginTop: 0, alignSelf: 'center', height: 65 }}
              />
              <h1 style={{ margin: 5 }}>Skills Checker</h1>
              <WarningIcon style={{ fontSize: 40, marginTop: 10 }} />
              <h2 style={{ textAlign: 'center', marginTop: 5 }}>
                {translations.t('login.noService')}
              </h2>
              <div style={{ marginTop: 0 }}>
                <MDButton
                  className="gradient-button"
                  title={translations.t('modals.reloadPage')}
                  backgroundColor={EDULAI_BLUE}
                  titleStyle={{ fontSize: 18 }}
                  containerstyle={{ width: 300, margin: 'auto' }}
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withRouter(NoServiceView));
