import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';
import { QUIZ_STATUS_COLORS } from '../../styles/styleConsts';
import translations from '../../translations/i18next';

const QuizChip = ({ status }) => (
  <Tooltip title={translations.t(`quizzes.typesDescriptions.${status}`)} arrow placement="bottom">
    <Chip
      style={{
        backgroundColor: QUIZ_STATUS_COLORS[status],
        color: 'white',
        borderRadius: 20,
        width: 100,
      }}
      label={translations.t(`quizzes.types.${status}`)}
    />
  </Tooltip>
);

export default QuizChip;
