module.exports = {
  SAVE_SKILLS: 'SAVE_SKILLS',
  RESET_SKILLS_DATA: 'RESET_SKILLS_DATA',
  SAVE_OR_UPDATE_SKILL: 'SAVE_OR_UPDATE_SKILL',
  SET_SELECTED_SKILL: 'SET_SELECTED_SKILL',
  SAVE_SUB_SKILLS: 'SAVE_SUB_SKILLS',
  SET_SELECTED_SUB_SKILL: 'SET_SELECTED_SUB_SKILL',
  RESET_SKILLS_STATE: 'RESET_SKILLS_STATE',
  UPDATE_SUB_SKILL: 'UPDATE_SUB_SKILL',
  UPDATE_SUB_SKILL_IN_SELECTED_SKILL: 'UPDATE_SUB_SKILL_IN_SELECTED_SKILL',
  UPDATE_SUB_SKILL_IN_SUBSKILLS: 'UPDATE_SUB_SKILL_IN_SUBSKILLS',
  RESET_SUB_SKILLS: 'RESET_SUB_SKILLS',
};
