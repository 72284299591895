import React from 'react';
import translations from '../../translations/i18next';
import { INSTITUTION_TYPES } from '../../config/configurations';
import MDButton from '../MDButton/MDButton';
import { EDULAI_BACKGROUND_GRADIENT_COLOR, EDULAI_BLUE, EDULAI_PURPLE } from '../../styles/styleConsts';
import logo from '../../assets/report_soft_skill.png';
import { Divider } from '@material-ui/core';

const QuizSubmissionReady = ({ quiz, institutionType, onStart }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        margin: 'auto',
        backgroundColor: 'white',
        borderRadius: 8,
        width: '60%'
      }}
    >
      <div
        style={{
          background: EDULAI_BACKGROUND_GRADIENT_COLOR,
          height: 90,
          display: 'flex',
          alignItems: 'center',
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
      >
        <h1 style={{ color: 'white', marginLeft: 20 }}>{quiz.name}</h1>
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <h2 style={{ textAlign: 'left', color: EDULAI_PURPLE }}>{translations.t('submissions.title')}</h2>
        {institutionType && institutionType === INSTITUTION_TYPES.SMARTWORKING ? (
          <h3 style={{ textAlign: 'center', fontWeight: 'normal' }}>
            {translations.t('submissions.sybmissionGuidelinesSmartWorking')}
          </h3>
        ) : null}
        <h3 style={{ textAlign: 'left', fontWeight: 'normal', color: EDULAI_PURPLE, fontSize: 16 }}>
          {translations.t('submissions.description1')}
        </h3>
        <img
          src={logo}
          alt="edulai"
          className="logo"
          style={{
            margin: 10,
            marginTop: 0,
            alignSelf: 'center',
            height: 90
          }}
        />
        <Divider style={{ height: 2, color: '#D1D5DB', marginTop: 20, marginBottom: 30 }} />
        <h3 style={{ textAlign: 'left', fontWeight: 'normal', fontSize: 16, color: '#6B7280' }}>
          {translations.t('submissions.description2')}
        </h3>
      </div>
      <MDButton
        title={translations.t('submissions.startNewSubmission')}
        backgroundColor={EDULAI_BLUE}
        containerstyle={{
          marginTop: 40,
          paddingBottom: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={onStart}
        buttonStyle={{ width: '30%' }}
      />
    </div>
  );
};

export default QuizSubmissionReady;
