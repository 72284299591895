import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import starFull from '../../../assets/star_full.png';
import starEmpty from '../../../assets/star_empty.png';
import starHalf from '../../../assets/star_half.png';
import { times } from 'lodash';

const styles = StyleSheet.create({
  stars: {
    flexDirection: 'row'
  },
  starImage: {
    width: 12,
    height: 12,
    marginHorizontal: 2
  }
});

const Stars = ({ rating }) => {
  const totalStars = 5;
  const minimumValue = 0;

  return (
    <View style={styles.stars}>
      {times(totalStars, (currentValue) => {
        const normalizedCurrentValue = currentValue + 1;
        const isSelected = rating >= normalizedCurrentValue || normalizedCurrentValue === minimumValue;
        const isHalf = rating > currentValue && rating < normalizedCurrentValue;
        return (
          <View key={currentValue}>
            {isSelected && !isHalf && <Image style={styles.starImage} src={starFull} />}
            {isHalf && <Image style={styles.starImage} src={starHalf} />}
            {!isSelected && !isHalf && <Image style={styles.starImage} src={starEmpty} />}
          </View>
        );
      })}
    </View>
  );
};

export default Stars;
