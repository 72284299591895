import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import translations from '../../translations/i18next';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../styles/styleConsts';
import MDButton from '../MDButton/MDButton';
import UserCardIcon from '../Icons/UserCardIcon';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

const styles = () => ({
  outerContainer: {
    marginBottom: 15,
    position: 'relative',
    width: isMobileBrowser() ? '100%' : '100%',
    borderRadius: 8,
    boxShadow: 'none',
    border: '2px solid #D1D5DB',
    paddingBottom: 10
  },
  eventContainer: {
    padding: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  },
  titleContainer: {
    alignItems: 'center'
  },
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: 'black'
  },
  dateText: {
    margin: 0,
    color: '#979da1',
    fontSize: 15,
    marginTop: 5
  },
  disabledText: {
    margin: 0,
    marginTop: 5,
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    right: 10,
    top: 10
  },
  body: {
    margin: 0,
    marginTop: 10,
    color: '#979da1',
    textAlign: 'left',
    fontSize: 12
  },
  photoTypeTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 15
  }
});

const QuizUserRow = ({ user, classes, containerstyle, sendReminder, showSendReminder }) => (
  <MuiThemeProvider theme={theme}>
    <Card style={{ ...containerstyle }} className={classes.outerContainer}>
      <CardActionArea disabled className={classes.eventContainer}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <UserCardIcon />
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: 20 }}>
              <h3 className={classes.title}>{`${user.name} ${user.surname}`}</h3>
              <h5
                style={{
                  fontWeight: 'normal',
                  margin: 2,
                  marginLeft: 0,
                  maxWidth: '300px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontSize: 16
                }}
              >{`${user.email}`}</h5>
            </div>
          </div>
        </div>
      </CardActionArea>
      <MDButton
        title={translations.t('quizzes.sendReminder')}
        backgroundColor={EDULAI_BLUE}
        containerstyle={{ margin: 10, marginTop: 0 }}
        onClick={() => sendReminder()}
        buttonStyle={{ width: isMobileBrowser() ? '90%' : '50%', marginLeft: 45 }}
        disabled={!showSendReminder}
      />
    </Card>
  </MuiThemeProvider>
);

export default withStyles(styles)(withRouter(QuizUserRow));
