import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const styles = theme => ({
  textField: {
    width: '100%',
  },
});

class MDTextInputView extends React.Component {
  handleChange(event) {
    event.preventDefault();
    const { onChange } = this.props;
    if (onChange) onChange(event.currentTarget.value);
  }

  render() {
    const { label, variant, type, value } = this.props;
    return (
      <TextField
        variant={variant || 'outlined'}
        label={label}
        type={type || ''}
        style={{ width: '100%' }}
        value={value}
        {...this.props}
        onChange={event => this.handleChange(event)}
      />
    );
  }
}


export default withStyles(styles)(MDTextInputView);
