import React from 'react';
import ReportText from './ReportText';
import ReactHtmlParser from 'react-html-parser';

const transform = (node) => {
  if (node.type === 'tag' && node.name === 'strong') {
    return <ReportText style={{ fontWeight: 'bold' }}>{node.children[0].data}</ReportText>;
  }
  return undefined;
};

const ReportTextHtml = ({ children, style }) => {
  return <ReportText style={style}>{ReactHtmlParser(children, { transform })}</ReportText>;
};

export default ReportTextHtml;
