import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { EDULAI_ORANGE } from '../../../styles/styleConsts';
import cover from '../../../assets/report-front.png';
import skillsCheckerLogo from '../../../assets/logo_white.png';
import softSkillImage from '../../../assets/report_soft_skill.png';
import translations from '../../../translations/i18next';
import moment from 'moment';
import ReportText from './ReportText';

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
  },
  title: {
    color: EDULAI_ORANGE,
    fontSize: 26,
    fontWeight: 'bold'
  },
  name: {
    color: '#FFFFFF',
    fontSize: 20,
    fontWeight: 'bold'
  },
  date: {
    color: '#FFFFFF',
    fontSize: 12,
    marginTop: 10,
    fontWeight: 'light'
  },
  private: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 'light'
  },
  skillsCheckerLogo: {
    width: 150,
    height: 80,
    marginBottom: 20
  },
  logo: {
    width: 90,
    height: 70,
    marginBottom: 20
  }
});

const Cover = (props) => {
  return (
    <>
      <Image style={styles.backgroundImage} src={cover} />
      <View style={styles.container}>
        <Image style={styles.skillsCheckerLogo} src={skillsCheckerLogo} />
        <View>
          <Image style={styles.logo} src={softSkillImage} />
          <ReportText style={styles.title}>REPORT EDULAI</ReportText>
        </View>
        <View style={{ marginBottom: 50 }}>
          <ReportText style={styles.name}>
            {props.user.name} {props.user.surname}
          </ReportText>
          <ReportText style={styles.date}>{moment().format('LL')}</ReportText>
          <ReportText style={styles.private}>{translations.t('analytics.private')}</ReportText>
        </View>
      </View>
    </>
  );
};

export default Cover;
