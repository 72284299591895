import { Text } from '@react-pdf/renderer';
import React from 'react';

const ReportText = ({ style, children }) => {
  const textStyle = {
    ...style,
    fontFamily: 'Nunito'
  };
  return <Text style={textStyle}>{children}</Text>;
};

export default ReportText;
