/* eslint-disable indent */
import React from 'react';
import _ from 'lodash';
import { NavLink, Route, withRouter } from 'react-router-dom';
import { EDULAI_PURPLE } from '../../styles/styleConsts';

class HeaderButton extends React.Component {
  checkActivation = (match) => {
    if (match) {
      const { paths } = this.props;
      return _.indexOf(paths, match.url) !== -1;
    }
    return false;
  };

  render() {
    const { title, titleStyleSelected, titleNormalStyle, paths, iconSelected, icon } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Route
          to={_.first(paths)}
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                backgroundColor: this.checkActivation(match) ? null : null,
              }}
            >
              <NavLink
                exact
                to={_.first(paths)}
                isActive={() => this.checkActivation(match)}
                style={{
                  display: 'flex',
                  width: '100%',
                  textDecoration: 'none',
                  color: '#80888f',
                  alignItems: 'center',
                }}
                activeStyle={{
                  textDecoration: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <div
                  style={{
                    padding: 10,
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                    ...(this.checkActivation(match) && {
                      width: '92%',
                      height: '60%',
                      backgroundColor: EDULAI_PURPLE,
                      borderRadius: 8,
                    }),
                  }}
                >
                  {this.checkActivation(match) ? iconSelected : icon}
                  <h4
                    style={
                      this.checkActivation(match)
                        ? { fontSize: 16, marginLeft: 15, ...titleStyleSelected }
                        : {
                            fontSize: 16,
                            fontFamily: 'Oxygen',
                            fontWeight: 200,
                            color: 'white',
                            marginLeft: 15,
                            ...titleNormalStyle,
                          }
                    }
                  >
                    {title}
                  </h4>
                </div>
              </NavLink>
            </div>
          )}
        />
      </div>
    );
  }
}

export default withRouter(HeaderButton);
