import React from 'react';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import translations from '../../../translations/i18next';
import { ORANGE } from '../../../styles/styleConsts';

const QuizReadyView = ({ quiz }) => (
  <div style={{ textAlign: 'center', margin: 'auto' }}>
    <InfoIcon style={{ color: ORANGE, fontSize: 50 }} />
    <h2>{translations.t('submissions.submissionsFor')}</h2>
    <h1>{quiz.name}</h1>
    <h2>{`${translations.t('submissions.willStart')} ${moment() > moment(quiz.startDate) ? translations.t('submissions.soon') : moment(quiz.startDate).fromNow()}`}</h2>
  </div>
);

export default QuizReadyView;
