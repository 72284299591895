import { DateTimePicker } from '@material-ui/pickers';
import React from 'react';

class MDDateTimePickerView extends React.Component {
  handleChange(event) {
    event.preventDefault();
    const { onChange } = this.props;
    if (onChange) onChange(event.currentTarget.value);
  }

  render() {
    return (
      <DateTimePicker
        onChange={(event) => this.handleChange(event)}
        style={{ width: '100%' }}
        {...this.props}
      />
    );
  }
}


export default MDDateTimePickerView;
