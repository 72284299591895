import React from 'react';
import { Radar } from 'react-chartjs-2';
import { EDULAI_PURPLE } from '../../styles/styleConsts';

const RadarChart = (props) => {
  const { data, min, max, stepSize } = props;

  const options = {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 15,
        padding: 80
      },
      layout: {
        padding: {
          top: 50
        }
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index]
      }
    },
    scale: {
      ticks: {
        max,
        min,
        stepSize
      },
      pointLabels: {
        fontSize: 15,
        fontColor: EDULAI_PURPLE
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div
      id={props.testId}
      style={{ width: '100%', height: 550, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Radar data={data} options={options} height={500} />;
    </div>
  );
};

export default RadarChart;
