import html2canvas from 'html2canvas';
import { initial, isEmpty, join, last } from 'lodash';
import { pdf } from '@react-pdf/renderer';

export const captureElement = async (element) => {
  return html2canvas(element, {
    scale: 2,
    windowWidth: 1280,
    windowHeight: 1280
  }).then((canvas) => canvas.toDataURL('image/png'));
};

export const captureChart = async (element) => {
  return html2canvas(element, {
    scale: 2,
    windowWidth: 1600,
    windowHeight: 1600
  }).then((canvas) => canvas.toDataURL('image/png'));
};

export async function createReportLinkAndDownload(doc, fileName) {
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);

  const blob = await asPdf.toBlob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function joinWithCommas(arr, lang = 'it') {
  const and = lang === 'it' ? ', e ' : ', and ';
  if (isEmpty(arr)) {
    return '';
  }
  if (arr.length === 1) {
    return arr[0];
  }
  return join(initial(arr), ', ') + and + last(arr);
}

export const showUINumber = (value) => {
  if (!value) return 0;
  const num = parseFloat(value);
  if (isNaN(num)) {
    throw new TypeError('Input is not a valid number');
  }

  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
};
