//
// ──────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A P I   F O R   M A N A G E R   P L A T F O R M   U S E R S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────────────
//

import { edulaiInstance } from './instances/edulaiInstance';
import { API_VERSION } from '../config/configurations';

export function fetchUsers(params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/users`, paramsToSend);
}

/* User creation from backoffice
userInDTO
{
  "customFields": "string",
  "domainId": 0,
  "email": "string",
  "name": "string",
  "surname": "string",
  "userRoleType": "ROOT"
}
*/
export function createUser(userInDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/createEnabled`, userInDTO);
}

/* User registration
userInDTO
{
  "customFields": "string",
  "domainId": 0,
  "email": "string",
  "name": "string",
  "surname": "string",
  "userRoleType": "ROOT"
}
*/
export function registerUser(userRegisterDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/users`, userRegisterDTO);
}

/* User activation after landing from email
activationDTO { "activationToken": "string", "password": "string", "privacyAccepted": true }
*/
export function confirmUserRegistration(activationDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/activate`, activationDTO);
}

/* Reset password from Login view
passwordResetRequestDTO {"username": "string"}
*/
export function resetPassword(passwordResetRequestDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/resetPassword`, passwordResetRequestDTO);
}

/* Reset password from Registration view
passwordResetDTO { "password": "string", "resetToken": "string" }
*/
export function resetPasswordConfirm(passwordResetDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/resetPassword`, passwordResetDTO);
}

export function changeCurrentPassword(userId, passwordUpdateDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/changePassword`, passwordUpdateDTO);
}

// USER UPDATE FLOWS
/*
Update user profile information. This endpoint can be used for submit the onboarding answers
userUpdateInDTO {
  "customFields": "string",
  "name": "string",
  "surname": "string"
}
*/
export function updateUser(userId, userUpdateInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}`, userUpdateInDTO);
}

/*
Commit onboarding update
userUpdateOnBoardingInDTO { "onBoardingCompleted": true }
*/
export function commitOnboardingComplete(userId, userUpdateOnBoardingInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/onBoarding`, userUpdateOnBoardingInDTO);
}

export function setOnboardingStatus(userId, userUpdateOnBoardingInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/onBoarding`, userUpdateOnBoardingInDTO);
}

/*
Make user self assessment
*/
export function saveUserSelfAssessment(userId, selfAssessmentDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/selfAssessment`, selfAssessmentDTO);
}

/*
Change the enabling status of the user
userUpdateEnabledInDTO { "enabled": true/false }
*/
export function updateUserEnabling(userId, userUpdateEnabledInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/enabled`, userUpdateEnabledInDTO);
}

export function deleteUser(userId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/users/${userId}`);
}

/* Update User role
userRoleDTO {
  "userRoleType": "ROOT"
}
*/
export function updateUserRole(userId, userRoleDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}/updateRole`, userRoleDTO);
}

/*
------- USER TAGS ------
*/
export function fetchUserTags(params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/userTags`, paramsToSend);
}

export function createUserTag(tagDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/userTags`, tagDTO);
}

export function updateeUserTag(tagDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/userTags/${tagDTO.id}`, tagDTO);
}

export function fetchUserDetails(userId) {
  return edulaiInstance.get(`/api/${API_VERSION}/users/${userId}`);
}

export function addUserTag(userId, userTagId) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/${userId}/tags/${userTagId}`);
}

export function deleteUserTag(userId, userTagId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/users/${userId}/tags/${userTagId}`);
}

export function deleteTag(userTagId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/userTags/${userTagId}`);
}

export function getUsersByUserTag(userTagId, params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/users/byUserTag/${userTagId}`, paramsToSend);
}
