import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import translations from '../../../translations/i18next';
import { EDULAI_BLUE } from '../../../styles/styleConsts';

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.credits = translations.t('forms.required');
  }
  if (!values.body) {
    errors.credits = translations.t('forms.required');
  }
  return errors;
};

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});


const CustomFieldForm = () => (
  <MuiThemeProvider theme={theme}>
    <div style={{ marginRight: 20 }}>
      <h3>{translations.t('domain.customFieldDescription')}</h3>
      <Field
        name="title"
        component={MDTextInputField}
        label={translations.t('domain.customFieldTitle')}
        required
        variant="outlined"
        color="primary"
        containerstyle={{ marginTop: 20 }}
      />
      <Field
        name="body"
        component={MDTextInputField}
        label={translations.t('domain.customFieldBody')}
        required
        multiline
        rows="3"
        variant="outlined"
        color="primary"
        containerstyle={{ marginTop: 20 }}
      />
    </div>
  </MuiThemeProvider>
);


export default connect()(reduxForm({
  form: 'CustomFieldForm',
  validate,
})(CustomFieldForm));
