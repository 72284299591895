import React from 'react';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import { SwapHoriz } from '@material-ui/icons';
import translations from '../../translations/i18next';

const ChangeViewButton = ({ onClick }) => (
  <div
    role="button"
    tabIndex={0}
    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
    onClick={onClick}
    onKeyDown={onClick}
  >
    <div
      style={{
        backgroundColor: EDULAI_BLUE,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40
      }}
    >
      <SwapHoriz style={{ color: 'white', fontSize: 30 }} />
    </div>
    <h4 style={{ fontWeight: 800, fontSize: 16, color: '#6B7280', marginLeft: 8 }}>
      {translations.t('analytics.changeView')}
    </h4>
  </div>
);

export default ChangeViewButton;
