import React from 'react';
import circle from '../../assets/circle.png';

const CircleLogo = ({ height }) => (
  <img
    src={circle}
    alt="edulai"
    className="logo"
    style={{
      margin: 10,
      marginTop: 0,
      alignSelf: 'center',
      height,
    }}
  />
);

export default CircleLogo;
