//
// ────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: P L A T F O R M   U S E R S   A C T I O N   T Y P E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────
//


module.exports = {
  SAVE_USERS: 'SAVE_USERS',
  APPEND_USERS: 'APPEND_USERS',
  SAVE_USERS_PAGINATION: 'SAVE_USERS_PAGINATION',
  SET_USERS_FILTER: 'SET_USERS_FILTER',
  RESET_USERS_FILTERS: 'RESET_USERS_FILTERS',
  DELETE_USER: 'DELETE_USER',
  RESET_USERS_DATA: 'RESET_USERS_DATA',
  UPDATE_USER: 'UPDATE_USER',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SAVE_BASE_USERS: 'SAVE_BASE_USERS',
  SAVE_BASE_USERS_PAGINATION: 'SAVE_BASE_USERS_PAGINATION',
  APPEND_BASE_USERS: 'APPEND_BASE_USERS',
  RESET_BASE_USERS_DATA: 'RESET_BASE_USERS_DATA',
  SAVE_SUPERVISOR_USERS: 'SAVE_SUPERVISOR_USERS',
  SAVE_USER_TAGS: 'SAVE_USER_TAGS',
  SAVE_USER_TAGS_PAGINATION: 'SAVE_USER_TAGS_PAGINATION',
  SET_SELECTED_USER_TAG: 'SET_SELECTED_USER_TAG',
  UPDATE_TAG: 'UPDATE_TAG',
  DELETE_TAG: 'DELETE_TAG',
  APPEND_USER_TAGS: 'APPEND_USER_TAGS',
  SET_USER_TAGS_FILTER: 'SET_USER_TAGS_FILTER',
  RESET_USERS_TAGS_FILTERS: 'RESET_USERS_TAGS_FILTERS',
  RESET_USERS_TAGS_DATA: 'RESET_USERS_TAGS_DATA',
};
