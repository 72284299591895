import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import BaseUserAnalytics from '../../components/Analytics/BaseUserAnalytics';
import PrincipalAnalytics from '../../components/Analytics/PrincipalAnalytics';
import { USER_ROLES, PERMISSIONS, PERMISSION_ENTITIES } from '../../config/configurations';
import Permission from '../../components/Permission/Permission';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import NavigationBar from '../NavigationViews/NavigationBar';
import StatisticsIcon from '../../components/Icons/StatisticsIcon';
import translations from '../../translations/i18next';
import { COMPONENT_BACKGROUND } from '../../styles/styleConsts';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const styles = () => ({
  container: {
    padding: isMobileBrowser() ? 0 : 20,
    backgroundColor: COMPONENT_BACKGROUND
  },
  title: {
    color: '#5a5c69',
    fontWeight: '200'
  },
  chartsContainer: {
    marginTop: 10
  }
});

class StatisticsView extends React.Component {
  componentDidMount() {
    this.containerDiv.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const {
      classes,
      user: {
        data: {
          role: { name: userRole }
        }
      }
    } = this.props;
    return (
      <div
        ref={(c) => {
          this.containerDiv = c;
        }}
      >
        <NavigationBar title={translations.t('header.statistics')} icon={<StatisticsIcon color="white" />} />
        <div className={classes.container}>
          {userRole === USER_ROLES.USER && <BaseUserAnalytics />}
          <Permission
            do={[PERMISSIONS.READ]}
            on={PERMISSION_ENTITIES.ANALYTICS}
            roles={[USER_ROLES.PRINCIPAL, USER_ROLES.ROOT]}
            abilityHelper={AbilityProvider.getDomainAbilityHelper()}
          >
            <PrincipalAnalytics />
          </Permission>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default _.flow([connect(mapStateToProps), withStyles(styles)])(StatisticsView);
