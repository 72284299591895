import React from 'react';
import AsyncSelect from 'react-select/async';

import translations from '../../../../translations/i18next';

const SelectableView = ({
  onHandleChange,
  onLoadOptions,
  noCache,
  onValueClick,
  multi,
  input,
  isDisabled,
  defaultOptions,
  loadingMessage,
  noOptionsMessage,
  placeholder,
  isClearable,
  onBlur,
  bgColor,
}) => (
  <AsyncSelect
    {...input}
    defaultOptions={defaultOptions || []}
    isMulti={multi}
    noOptionsMessage={noOptionsMessage || (() => translations.t('forms.noOptions'))}
    loadingMessage={loadingMessage || (() => translations.t('forms.loadingOptions'))}
    placeholder={placeholder}
    cacheOptions={!noCache}
    isClearable={isClearable}
    styles={{
      menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
      control: (styles) => ({ ...styles, minHeight: 50, backgroundColor: bgColor }),
    }} // zindex of the overlay
    isDisabled={isDisabled}
    loadOptions={onLoadOptions ? (value) => onLoadOptions(value, input) : null}
    onValueClick={(value, actionType) => onValueClick(value, actionType)}
    onChange={(value) => onHandleChange(value, input)}
    onBlur={onBlur ? () => input.onBlur() : null}
  />
);

export default SelectableView;
