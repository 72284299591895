export const SAVE_USER_SKILLS_ANALYTICS = 'SAVE_USER_SKILLS_ANALYTICS';
export const SAVE_USER_SUBSKILLS_ANALYTICS = 'SAVE_USER_SUBSKILLS_ANALYTICS';
export const SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS = 'SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS';
export const SAVE_BEST_USER = 'SAVE_BEST_USER';
export const SAVE_BAD_USER = 'SAVE_BAD_USER';
export const SAVE_QUIZ_USER_SKILLS_ANALYTICS = 'SAVE_QUIZ_USER_SKILLS_ANALYTICS';
export const SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS = 'SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS';
export const SAVE_SKILLS_ANALYTICS = 'SAVE_SKILLS_ANALYTICS';
export const SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY = 'SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY';
export const SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME = 'SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME';
export const SAVE_USERS_SUMMARY_SCORES = 'SAVE_USERS_SUMMARY_SCORES';
export const SAVE_USERS_SKILLS_SCORES = 'SAVE_USERS_SKILLS_SCORES';
export const RESET_ANALYTICS_DATA = 'RESET_ANALYTICS_DATA';
