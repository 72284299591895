import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PERMISSIONS, PERMISSION_ENTITIES, USER_ROLES } from '../../config/configurations';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';

class DefaultRootView extends React.Component {
  
  componentDidMount() {
    const { history, user } = this.props;
    const canReadQuiz = AbilityProvider.getDomainAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.QUIZ);
    if (user.role.name === USER_ROLES.PRINCIPAL) return history.push('/statistics');
    if (canReadQuiz) return history.push('/quizzes');
    return history.push('/users');
  }

  render() {
    return (<div />);
  }
}

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default withRouter(connect(mapStateToProps)(DefaultRootView));
