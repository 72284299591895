import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 45,
    right: 45,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  footerText: {
    fontSize: 10,
    color: '#666',
    fontFamily: 'Nunito'
  }
});

const Footer = () => {
  return (
    <View style={styles.footer} fixed>
      <Text fixed style={styles.footerText} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
    </View>
  );
};

export default Footer;
