import _ from 'lodash';
import axios from 'axios';

export const edulaiInstance = axios.create({
  timeout: 30000,
});

export function getRequestInterceptors() {
  return edulaiInstance.interceptors.request ? edulaiInstance.interceptors.request.handlers : [];
}

export function interceptorEjectRequest() {
  _.each(
    _.keys(edulaiInstance.interceptors.request.handlers), (key) => {
      edulaiInstance.interceptors.request.eject(key);
    }
  );
}

export function setRequestInterceptor(accessToken) {
  return edulaiInstance.interceptors.request.use(
    (config) => {
      config['headers']['authorization'] = `Bearer ${accessToken}`;
      return config;
    });
}
export const replayRequest = (params) => {
  console.log('Replaying failed request');
  axios(params.initialRequest).then((response) => {
    console.log('Request replayed correctly');
    params.resolve(response);
  }).catch((reason) => {
    console.log('Request replayed with error');
    console.log(reason);
    params.reject(reason);
  });
};


export function setBaseUrl(baseURL) {
  edulaiInstance.defaults.baseURL = baseURL;
}

export function getBaseUrl() {
  return edulaiInstance.defaults.baseURL;
}
