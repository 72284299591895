import { withRouter } from 'react-router-dom';
import AppRoute from './AppRoute';
import * as UserActions from '../../redux/actions/user.actions';
import requireAuthentication from '../../hoc/requireAuthentication';
import { USER_ROLES } from '../../config/configurations';

const checkAuth = async (props) => {
  const { dispatch, history, location } = props;
  try {
    await UserActions.setInstanceConfigurations();
    const user = await dispatch(UserActions.setUserInfoFromLocalStorage());
    const { data: { role: { name: roleName }, onBoardingCompleted } } = user;
    // Check if Route it's forbidden for some role o set of permission
    if (!user.isAuthenticated) {
      history.push('/login');
      return false;
    }
    if (location.pathname !== '/userOnboarding' && roleName === USER_ROLES.USER && !onBoardingCompleted) {
      history.push('/userOnboarding');
      return true;
    }
    return true;
  } catch (error) {
    history.push('/login');
    return false;
  }
};

export default withRouter(requireAuthentication(checkAuth)(AppRoute));
