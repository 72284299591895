import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InfoButton from './InfoButton';
import * as UtilsActions from '../../redux/actions/utils.actions';

const InstructionLinkButton = () => {
  const dispatch = useDispatch();
  const [instructionsLink, setInstructionLink] = useState('');

  useEffect(() => {
    const instructionsLink = dispatch(UtilsActions.getInstructionLink());
    setInstructionLink(instructionsLink);
  }, [dispatch]);

  return <InfoButton onClick={() => window.open(instructionsLink)} />;
};

export default InstructionLinkButton;
