import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import i18next from 'i18next';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import { Divider } from '@material-ui/core';
import loginBackground from '../../assets/login.png';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import MDButton from '../../components/MDButton/MDButton';
import * as UserActions from '../../redux/actions/user.actions';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import ResetPasswordForm from '../../components/Forms/LoginRegisterForms/ResetPasswordForm';
import LoginForm from '../../components/Forms/LoginRegisterForms/LoginForm';
import RegisterForm from '../../components/Forms/LoginRegisterForms/RegisterForm';
import { LANGUAGE_BACKEND_MAP, LANGUAGE_LOCAL_MAP } from '../../config/configurations';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import EdulaiSkillCheckerLogo from '../../components/Logo/EdulaiSkillCheckerLogo';
import CircleLogo from '../../components/Logo/CircleLogo';

class LoginView extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', (e) => this.onSubmitPressed(e), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (e) => this.onSubmitPressed(e), false);
  }

  onChangeLanguage(language) {
    const { dispatch } = this.props;
    i18next.changeLanguage(language);
    dispatch(UtilsActions.setLanguage(language));
    dispatch(UserActions.updateUserLanguage(LANGUAGE_BACKEND_MAP[language]));
    this.forceUpdate();
  }

  onSubmitPressed(e) {
    const { dispatch } = this.props;
    if (e.keyCode === 13) {
      dispatch(submit('LoginForm'));
    }
  }

  async onLoginSubmit(userData) {
    const { history, dispatch } = this.props;
    const { username, password } = userData;
    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await dispatch(UserActions.login(username, password));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('LOGIN_ERROR_MODAL'));
      history.push('/');
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('LOGIN_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('login.loginError')
          }
        })
      );
    }
  }

  onOpenPasswordRecovery() {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('PASSWORD_RECOVERY_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('login.passwordRecovery'),
          hideCancel: true,
          content: (
            <ResetPasswordForm
              onSubmit={(userPasswordResetData) => this.onResetPasswordSubmit(userPasswordResetData)}
            />
          ),
          bodyTextStyle: { fontSize: 18 },
          bodyText: translations.t('login.passwordRecoveryBody'),
          onConfirm: () => dispatch(submit('ResetPasswordForm')),
          confirmText: translations.t('modals.confirm')
        }
      })
    );
  }

  async onResetPasswordSubmit(resetData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUsersActions.resetPasswordRequest(resetData.username));
      dispatch(ModalsActions.hideModal('PASSWORD_RECOVERY_MODAL'));
      dispatch(
        ModalsActions.showModal('REGISTER_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('login.resetPasswordSuccess')
          }
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('REGISTER_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('login.resetPasswordError')
          }
        })
      );
    }
  }

  onOpenRegisterUser() {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('REGISTER_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('login.createNewAccount'),
          hideCancel: true,
          content: <RegisterForm onSubmit={(userRegister) => this.onRegisterConfirm(userRegister)} />,
          bodyTextStyle: { fontSize: 20 },
          bodyText: `${translations.t('login.registerUserBody')}\n${translations.t('login.registerUserEmail')} `,
          onConfirm: () => dispatch(submit('RegisterForm')),
          confirmText: translations.t('modals.confirm')
        }
      })
    );
  }

  async onRegisterConfirm(userRegisterData) {
    const { dispatch, language } = this.props;
    try {
      const languageSelected = language || 'en';
      const userLanguage = _.find(LANGUAGE_LOCAL_MAP, (lan) => lan.key === languageSelected);
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(
        PlatformUsersActions.registerUser({
          ...userRegisterData,
          language: userLanguage ? userLanguage.backend : 'ENGLISH'
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('REGISTER_MODAL'));
      dispatch(
        ModalsActions.showModal('REGISTER_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('login.createAccountSuccess')
          }
        })
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('REGISTER_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('login.confirmRegistrationError')
          }
        })
      );
    }
  }

  render() {
    const { dispatch } = this.props;
    const isMobile = isMobileBrowser();

    return (
      <div
        className="login-background"
        style={{
          height: '100vh',
          width: '100%',
          paddingTop: isMobile ? 0 : 0,
          backgroundImage: `url(${loginBackground})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {isMobile ? null : (
          <LanguageSelector
            language={i18next.language}
            onSelectLanguage={(language) => this.onChangeLanguage(language)}
          />
        )}
        <Card
          style={{
            height: isMobile ? '150%' : 'unset',
            width: isMobile ? '100%' : '50%',
            margin: 'auto',
            padding: isMobile ? 0 : 10,
            paddingBottom: 0,
            borderRadius: isMobile ? 0 : 10,
            paddingTop: isMobile ? 30 : 0,
            alignSelf: 'center',
            verticalAlign: 'center',
            marginTop: 100
          }}
        >
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
                marginBottom: 20,
                position: 'relative'
              }}
            >
              <EdulaiSkillCheckerLogo height={isMobile ? 70 : 100} />
              <div style={{ position: 'absolute', right: 0 }}>
                <CircleLogo height={isMobile ? 70 : 100} />
              </div>
            </div>
            {!isMobile && <Divider />}
            <div
              style={{
                paddingTop: 40,
                paddingBottom: 50,
                width: isMobile ? '100%' : '70%',
                alignSelf: 'center'
              }}
            >
              <LoginForm onSubmit={(loginData) => this.onLoginSubmit(loginData)} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <h4
                  className="link-label"
                  onClick={() => this.onOpenPasswordRecovery()}
                  style={{
                    letterSpacing: 1,
                    cursor: 'pointer',
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: 14,
                    textAlign: 'right',
                    color: EDULAI_BLUE
                  }}
                >
                  {translations.t('login.passwordRecovery')}
                </h4>
              </div>
            </div>

            <div style={{ marginTop: 0, marginBottom: 40 }}>
              <MDButton
                className="gradient-button"
                title="LOGIN"
                backgroundColor={EDULAI_BLUE}
                titleStyle={{ fontSize: 17 }}
                containerstyle={{
                  width: isMobile ? 'unset' : 300,
                  height: 40,
                  margin: 'auto'
                }}
                buttonStyle={{
                  borderRadius: 8
                }}
                onClick={() => dispatch(submit('LoginForm'))}
              />
            </div>
            {!isMobile && <Divider />}
            <h4
              className="link-label"
              onClick={() => this.onOpenRegisterUser()}
              style={{
                letterSpacing: 1,
                fontSize: 14,
                cursor: 'pointer',
                marginTop: 20,
                marginBottom: 0,
                textAlign: 'center',
                color: EDULAI_BLUE
              }}
            >
              {translations.t('login.registerNow')}
            </h4>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage
});

export default connect(mapStateToProps)(withRouter(LoginView));
