import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit, change } from 'redux-form';

import * as PlatformUserActions from '../../../redux/actions/platformUsers.actions';
import { EDULAI_PURPLE, RED, EDULAI_BLUE, GREEN_LIGHT } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import MDButton from '../../MDButton/MDButton';
import MDDateTimePickerField from '../FormsComponents/MDDateTimePicker/MDDateTimePickerField';
import SelectableField from '../FormsComponents/SelectableInput/SelectableField';

const validate = (values) => {
  const errors = {};
  const momentEndDate = moment(values.endDate);
  const momentStartDate = moment(values.startDate);
  if (!values.startDate) {
    errors.startDate = translations.t('forms.required');
  }
  if (!values.endDate) {
    errors.endDate = translations.t('forms.required');
  }
  if (momentEndDate - momentStartDate <= 0) {
    errors.endDate = translations.t('forms.startBeforeEnd');
  }
  return errors;
};

const styles = () => ({
  title: {
    margin: 0,
    textAlign: 'left',
    fontWeight: 800,
    fontSize: 18,
    color: '#3f3f3f'
  },
  text: {
    fontWeight: 'normal'
  },
  titleText: {
    margin: 0,
    marginRight: 5,
    fontSize: 15,
    letterSpacing: 1
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    seconday: EDULAI_PURPLE
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

class PrincipalChartFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      await dispatch(PlatformUserActions.fetchBaseUsers());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onFilterUsers(name) {
    const { dispatch } = this.props;
    const usersData = await dispatch(PlatformUserActions.fetchBaseUsers());
    const filteredOptions = _.filter(
      usersData,
      (user) =>
        user.name.toLowerCase().includes(name.toLowerCase()) ||
        user.surname.toLowerCase().includes(name.toLowerCase()) ||
        user.email.toLowerCase().includes(name.toLowerCase())
    );
    const newOptions = _.map(filteredOptions, (user) => ({
      value: user.id,
      label: `${user.name} ${user.surname}`
    }));
    return newOptions;
  }

  async onFilterSkills(name) {
    const {
      skills: {
        data: { content: skills }
      }
    } = this.props;
    const filteredOptions = _.filter(skills, (skill) => skill.name.toLowerCase().includes(name.toLowerCase()));
    const newOptions = _.map(filteredOptions, (skill) => ({
      value: skill.id,
      label: skill.name
    }));
    return newOptions;
  }

  render() {
    const {
      classes,
      dispatch,
      users: {
        baseUsers: { content: usersData }
      },
      skills: {
        data: { content: skillsData }
      },
      onResetFilters
    } = this.props;

    const { isLoading } = this.state;

    const participantsOptions = [
      ..._.map(usersData, (userOption) => ({
        value: userOption.id,
        label: `${userOption.name} ${userOption.surname}`
      })),
      {
        value: 'all',
        label: translations.t('analytics.everyone')
      }
    ];

    const skillsOptions = [
      ..._.map(skillsData, (skill) => ({
        value: skill.id,
        label: skill.name
      }))
    ];

    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ padding: 10, paddingTop: 0 }}>
          <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Field
              name="participants"
              component={SelectableField}
              containerstyle={{ width: '100%', marginRight: 20 }}
              placeholder={translations.t('analytics.everyone')}
              title={translations.t('quizzes.participants')}
              onLoadOptions={(name) => this.onFilterUsers(name)}
              isLoading={isLoading}
              defaultOptions={participantsOptions}
              defaultValue={_.find(participantsOptions, { value: 'all' })}
            />
            <Field
              name="skills"
              component={SelectableField}
              containerstyle={{ width: '100%', marginLeft: 20 }}
              placeholder={translations.t('analytics.all')}
              multi
              title={translations.t('analytics.skills')}
              onLoadOptions={(name) => this.onFilterSkills(name)}
              defaultOptions={skillsOptions}
            />
          </div>
          <div className={classes.titleContainer}>
            <h4 className={classes.titleText} style={{ color: EDULAI_PURPLE }}>
              {translations.t('forms.dateInterval')}
            </h4>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '50%', marginRight: 8 }}>
              <Field
                name="startDate"
                containerstyle={{ marginBottom: 0 }}
                component={MDDateTimePickerField}
                label={translations.t('forms.startDateTime')}
                showErrorLabel
                defaultValue={moment().subtract(1, 'week').valueOf()}
                required
              />
            </div>
            <div style={{ width: '50%', marginLeft: 8 }}>
              <Field
                name="endDate"
                containerstyle={{ marginBottom: 0 }}
                component={MDDateTimePickerField}
                label={translations.t('forms.endDateTime')}
                showErrorLabel
                defaultValue={moment().valueOf()}
                required
              />
            </div>
          </div>
          <div style={{ marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <MDButton
              title={translations.t('forms.today')}
              backgroundColor={EDULAI_BLUE}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginRight: 10, width: '100%' }}
              onClick={() => {
                dispatch(change('PrincipalChartFilterForm', 'startDate', moment().startOf('day').valueOf()));
                dispatch(change('PrincipalChartFilterForm', 'endDate', moment().endOf('day').valueOf()));
              }}
            />
            <MDButton
              title={translations.t('forms.thisWeek')}
              backgroundColor={EDULAI_BLUE}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginRight: 10, width: '100%' }}
              onClick={() => {
                dispatch(change('PrincipalChartFilterForm', 'startDate', moment().startOf('week').valueOf()));
                dispatch(change('PrincipalChartFilterForm', 'endDate', moment().endOf('week').valueOf()));
              }}
            />
            <MDButton
              title={translations.t('forms.thisMonth')}
              backgroundColor={EDULAI_BLUE}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginRight: 10, width: '100%' }}
              onClick={() => {
                dispatch(change('PrincipalChartFilterForm', 'startDate', moment().startOf('month').valueOf()));
                dispatch(change('PrincipalChartFilterForm', 'endDate', moment().endOf('month').valueOf()));
              }}
            />
            <MDButton
              title={translations.t('forms.last6Months')}
              backgroundColor={EDULAI_BLUE}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginRight: 10, width: '100%' }}
              onClick={() => {
                dispatch(
                  change(
                    'PrincipalChartFilterForm',
                    'startDate',
                    moment().startOf('month').subtract(6, 'months').startOf('month').valueOf()
                  )
                );
                dispatch(change('PrincipalChartFilterForm', 'endDate', moment().endOf('month').valueOf()));
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <MDButton
              title={translations.t('forms.applyFilters')}
              backgroundColor={GREEN_LIGHT}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginRight: 10, width: '100%' }}
              onClick={() => dispatch(submit('PrincipalChartFilterForm'))}
            />
            <MDButton
              title={translations.t('forms.resetFilters')}
              backgroundColor={RED}
              containerstyle={{ marginBottom: 20, marginTop: 0, marginLeft: 10, width: '40%' }}
              onClick={() => onResetFilters()}
            />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.platformUsers,
  skills: state.skills
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'PrincipalChartFilterForm',
    validate,
    destroyOnUnmount: true
  }),
  withStyles(styles)
])(PrincipalChartFilterForm);
