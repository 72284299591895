import ReactGA from 'react-ga4';
import * as AnswersAPI from '../../api/answersAPI';
import { SAVE_ANSWERS, SAVE_SUBMISSION_ANSWERS } from './actionTypes/answers';

const saveAnswers = (answers) => ({
  type: SAVE_ANSWERS,
  answers,
});

const saveSubmissionAnswers = (submission, answers) => ({
  type: SAVE_SUBMISSION_ANSWERS,
  submission,
  answers,
});

export const fetchAnswers = (page = 0, pageSize = 50) => (
  async (dispatch, getState) => {
    const params = {
      page,
      pageSize,
    };
    const {
      quizzes: { selectedQuiz: quiz },
      user: { data: user },
    } = getState();
    try {
      const response = await AnswersAPI.getAnswers(quiz.id, user.id, params);
      if (response.data) {
        dispatch(saveAnswers(response.data.content));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const createAnswer = (questionId, answer) => (
  async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
      user: { data: user },
    } = getState();
    const answerInDTO = {
      optionNumber: answer.answer,
      questionId,
      quizId: quiz.id,
      userId: user.id,
    };
    try {
      const response = await AnswersAPI.createAnswer(answerInDTO);
      if (response.data) {
        ReactGA.event({
          category: 'Answer',
          action: 'USER_QUESTION_RESPONSE_QUIZ',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${user.name} ${user.surname}`,
        });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const updateAnswer = (questionId, answer) => (
  async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
      user: { data: user },
    } = getState();
    const answerInDTO = {
      optionNumber: answer.answer,
      questionId,
      quizId: quiz.id,
      userId: user.id,
    };
    try {
      const response = await AnswersAPI.updateAnswer(answer.id, answerInDTO);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);

export const fetchAnswersBySubmissionId = (submissionId, page = 0, pageSize = 100) => (
  async (dispatch) => {
    const params = {
      page,
      pageSize,
    };
    try {
      const response = await AnswersAPI.getAnswersBySubmission(submissionId, params);
      if (response.data) {
        dispatch(saveSubmissionAnswers(submissionId, response.data.content));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);
