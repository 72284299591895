import React from 'react';
import translations from '../../../translations/i18next';

const QuizPausedView = ({ quiz }) => (
  <div style={{ textAlign: 'center', margin: 'auto' }}>
    <h2>{translations.t('submissions.submissionsFor')}</h2>
    <h1>{quiz.name}</h1>
    <h2>{translations.t('submissions.arePaused')}</h2>
    <h3
      style={{ marginTop: 30 }}
    >
      {translations.t('submissions.waitForSupervisor')}
    </h3>
  </div>
);

export default QuizPausedView;
