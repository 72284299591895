import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import MDStarRateView from '../FormsComponents/MDStarRateField/MDStarRateView';
import { EDULAI_BLUE } from '../../../styles/styleConsts';

const styles = () => ({
  outerContainer: {
    height: '100%',
    marginBottom: 15,
    position: 'relative'
  },
  eventContainer: {
    padding: 15,
    width: '100%'
  },
  title: {
    fontSize: 20,
    margin: 5,
    marginLeft: 0
  },
  optionBody: {
    marginTop: 0,
    fontWeight: 'normal'
  },
  skillOption: {
    margin: 'auto',
    marginLeft: 20,
    paddingTop: 1,
    fontWeight: 'normal'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    secondary: { main: '#CC0033' }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

const OptionItem = (props) => {
  const {
    classes,
    option,
    skills: { subSkills }
  } = props;

  const subskillsFlatten = _.flattenDeep(subSkills);
  const scores = _.map(option.skills, (skill) => ({
    skill: skill.skillId,
    value: skill.score
  }));

  return (
    <MuiThemeProvider theme={theme}>
      <Card className={classes.outerContainer}>
        <CardActionArea className={classes.eventContainer} disabled>
          <h5 className={classes.optionBody}>{option.body}</h5>
          {_.map(_.sortBy(scores, ['skill']), (score) => {
            const skill = _.find(subskillsFlatten, { id: score.skill });
            return (
              <div style={{ display: 'inline-flex', width: '100%' }}>
                <MDStarRateView totalValues={5} value={score.value} starContainerStyle={{ display: 'flex' }} />
                <h5 className={classes.skillOption}>{skill.name}</h5>
              </div>
            );
          })}
        </CardActionArea>
      </Card>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  skills: state.skills
});

export default _.flow([connect(mapStateToProps), withStyles(styles)])(OptionItem);
