import React from 'react';
import { Field, reduxForm } from 'redux-form';
import translations from '../../../translations/i18next';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { EDULAI_BLUE } from '../../../styles/styleConsts';
import MDSelectField from '../FormsComponents/MDSelectedField/MDSelectField';

const validate = (values) => {
  const errors = {};
  if (!values.domainType) {
    errors.domainType = translations.t('forms.required');
  }
  return errors;
};

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});

const DomainLicenseForm = ({ options }) => (
  <MuiThemeProvider theme={theme}>
    <div style={{ marginRight: 20 }}>
      <Field
        name="domainType"
        component={MDSelectField}
        title={translations.t('forms.subscriptionType')}
        required
        variant="outlined"
        color="primary"
        options={[
          {
            value: 'FLAT',
            label: translations.t('forms.flatSubscriptionDescription'),
          },
          {
            value: 'PPU',
            label: translations.t('forms.ppuSubscriptionDescription'),
          },
        ]}
        containerstyle={{ marginTop: 20 }}
      />
    </div>
  </MuiThemeProvider>
);

export default reduxForm({
  form: 'DomainLicenseForm',
  validate,
})(DomainLicenseForm);
