import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import React from 'react';
import SelectedStar from '../../../../assets/star_full.png';
import UnselectedStar from '../../../../assets/star_empty.png';

const MDStarRateView = ({
  value,
  totalValues,
  minimumValue,
  title,
  titleStyle,
  onSelectValue,
  starStyle,
  unselectedStarStyle,
  starContainerStyle
}) => (
  <div style={{ ...starContainerStyle }}>
    {title && <h4 style={{ margin: 0, marginBottom: 10, ...titleStyle }}>{title}</h4>}
    {_.times(totalValues, (currentValue) => {
      const isSelected = currentValue + 1 <= value || currentValue + 1 === minimumValue;
      return (
        <IconButton
          style={{ padding: isSelected ? 6 : 6 }}
          key={currentValue}
          onClick={onSelectValue ? () => onSelectValue(currentValue + 1) : null}
        >
          {isSelected ? (
            <img alt="evaluation" src={SelectedStar} style={{ ...starStyle, width: 20 }} />
          ) : (
            <img alt="evaluation" src={UnselectedStar} style={{ ...unselectedStarStyle, width: 20 }} />
          )}
        </IconButton>
      );
    })}
  </div>
);

export default MDStarRateView;
