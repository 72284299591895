import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import analyticsReducers from './analytics.reducers';
import answersReducers from './answers.reducers';
import domainReducers from './domain.reducers';
import modalReducers from './modal.reducers';
import platformUsersReducers from './platformUsers.reducers';
import questionsReducers from './questions.reducers';
import quizzesReducers from './quizzes.reducers';
import rolesReducers from './roles.reducers';
import skillsReducers from './skills.reducers';
import submissionsReducers from './submissions.reducers';
import tagsReducers from './tags.reducers';
import userReducers from './user.reducers';
import utilsReducers from './utils.reducers';


const rootReducers = combineReducers({
  answers: answersReducers,
  domain: domainReducers,
  analytics: analyticsReducers,
  form: formReducer,
  modals: modalReducers,
  platformUsers: platformUsersReducers,
  questions: questionsReducers,
  quizzes: quizzesReducers,
  roles: rolesReducers,
  skills: skillsReducers,
  submissions: submissionsReducers,
  tags: tagsReducers,
  user: userReducers,
  utils: utilsReducers,
});

export default rootReducers;
