import Immutable from 'seamless-immutable';
import {
  SAVE_USER_DATA,
  LOGOUT,
  SET_USER_AUTHENTICATION,
  SAVE_PERSONAL_ACCESS_RULES,
  SET_INSTITUTION_TYPE,
} from '../actions/actionTypes/user';

const initialState = Immutable({
  data: {},
  accessRules: {},
  isAuthenticated: false,
  institutionType: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_DATA:
      return state.set('data', action.userData);
    case SET_USER_AUTHENTICATION:
      return state.set('isAuthenticated', action.authenticated);
    case SAVE_PERSONAL_ACCESS_RULES:
      return state.setIn(['accessRules', action.companyId], action.accessRules);
    case SET_INSTITUTION_TYPE:
      return state.set('institutionType', action.institutionType);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
