export function createHTMLElement(element) {
  return document.createElement(element);
}

export function createSpan() {
  return createHTMLElement("span");
}

export function createDiv() {
  return createHTMLElement("div");
}

export function createTableHead() {
  return createHTMLElement("thead");
}

export function createTableBody() {
  return createHTMLElement("tbody");
}

export function createTableColumn() {
  const td = createHTMLElement("td");
  td.style.borderWidth = 0;
  td.style.padding = 0;
  td.style.margin = 0;
  td.style.display = 'flex';
  td.style.flexDirection = 'row';
  return td;
}

export function createTableRow() {
  const tr = createHTMLElement("tr");
  tr.style.backgroundColor = 'inherit';
  tr.style.borderWidth = 0;
  return tr;
}

export function createTextElement(text) {
  return  document.createTextNode(text);
}

export function getTooltip() {
  return document.getElementById('chartjs-tooltip');
}

export function createTooltip() {
  const tooltipEl = createDiv();
  tooltipEl.id = 'chartjs-tooltip';
  tooltipEl.innerHTML = '<table></table>';
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '6px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 0)';
  tooltipEl.style.transition = 'all .1s ease';
  return tooltipEl;
}

export function createTooltipElementSquare(color) {
  const span = document.createElement('span');
  span.style.background = color;
  span.style.borderColor = color;
  span.style.borderWidth = '2px';
  span.style.marginRight = '2px';
  span.style.height = '12px';
  span.style.width = '12px';
  span.style.display = 'flex';
  span.style.alignItems = 'center';
  span.style.justifyContent = 'center';
  span.style.flexDirection = 'row';
  return span;
}

export function createTooltipElementInnerSquare(color) {
  const span = document.createElement('span');
  span.style.background = color;
  span.style.borderColor = "white";
  span.style.borderWidth = '0.5px';
  span.style.borderStyle = 'solid';
  span.style.height = '11px';
  span.style.width = '11px';
  return span;
}

export function setTooltipPositionStyle(tooltipEl, tooltipModel, position) {
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
}

export function createUserTableRow(index, el, tooltipModel) {
  var colors = tooltipModel.labelColors[index];
  const square = createTooltipElementSquare(colors.backgroundColor);
  const innerSquare = createTooltipElementInnerSquare("transparent");
  square.appendChild(innerSquare);
  const tr = createTableRow()
  const td = createTableColumn();
  td.style.lineHeight = 0.8;
  const text = createTextElement(el)
  td.appendChild(square);
  td.appendChild(text);
  tr.appendChild(td);
  return tr;
}

export function createTitle(title) {
  const tr = createTableRow()
  const td =  createTableColumn();
  
  const text = document.createTextNode(title);
  const spanText = document.createElement('span');
  spanText.style.fontWeight = 'bold';
  spanText.appendChild(text);
  td.appendChild(spanText);
  tr.appendChild(td);
  return tr;
}