import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import translations from '../../../translations/i18next';
import { EDULAI_BLUE } from '../../../styles/styleConsts';
import MDSelectField from '../FormsComponents/MDSelectedField/MDSelectField';

const validate = (values) => {
  const errors = {};
  if (!values.credits) {
    errors.credits = translations.t('forms.required');
  }
  if (!values.operationType) {
    errors.operationType = translations.t('forms.required');
  }

  return errors;
};

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
  },
});



const DomainCreditsForm = () => (
  <MuiThemeProvider theme={theme}>
    <div style={{ marginRight: 20 }}>
      <Field
        name="operationType"
        component={MDSelectField}
        mandatory
        options={[
          {
            value: 'SET',
            label: translations.t('forms.setCredits'),
          },
          {
            value: 'ADD',
            label: translations.t('forms.addCredits'),
          },
        ]}
        title={translations.t('forms.selectOperation')}
      />
      <Field
        name="credits"
        component={MDTextInputField}
        label={translations.t('forms.creditsLabel')}
        required
        variant="outlined"
        color="primary"
        type="number"
        containerstyle={{ marginTop: 20 }}
      />
    </div>
  </MuiThemeProvider>
);


export default connect()(reduxForm({
  form: 'DomainCreditsForm',
  validate,
})(DomainCreditsForm));
