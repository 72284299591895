import React from 'react';
import { View, StyleSheet, Image, Link, Page } from '@react-pdf/renderer';
import layout from '../../../assets/layout_report.png';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import Header from './Header';
import Footer from './Footer';
import ReportText from './ReportText';
import { map } from 'lodash';
import translations from '../../../translations/i18next';

// Create styles
const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1
  },
  logoLeft: {
    width: 40,
    height: 40
  },
  logoRight: {
    width: 80,
    height: 30
  },
  sectionTitle: {
    color: EDULAI_BLUE,
    fontSize: 18,
    marginBottom: 25,
    marginTop: 5,
    fontWeight: 'bold'
  },
  paragraph: {
    fontSize: 11,
    color: '#333',
    marginBottom: 10,
    lineHeight: 1.5
  },
  listItem: {
    fontSize: 11,
    color: EDULAI_PURPLE,
    marginLeft: 20,
    fontFamily: 'Nunito'
  },
  list: {
    marginBottom: 15
  },
  page: {
    position: 'relative',
    paddingLeft: 64,
    paddingRight: 50
  }
});

// Create Document Component for Page 2
const Intro = ({ user, skills }) => (
  <Page size="A4" style={styles.page} wrap={true}>
    <Image style={styles.backgroundImage} src={layout} />
    <Header user={user} />

    <ReportText style={styles.sectionTitle}>{translations.t('skillsReport.reportIntroductionTitle')}</ReportText>
    <ReportText style={styles.paragraph}>{translations.t('skillsReport.reportIntroduction')}</ReportText>

    <View style={styles.list}>
      <ReportText style={styles.paragraph}>{translations.t('skillsReport.reportSkillsListIntro')}</ReportText>
      {map(skills, (skill, index) => (
        <Link key={index} style={styles.listItem}>
          • {skill.name}
        </Link>
      ))}
    </View>

    <ReportText style={styles.paragraph}>{translations.t('skillsReport.reportSkillsValueListIntro')}</ReportText>
    <View style={styles.list}>
      <Link style={styles.listItem}>{translations.t('skillsReport.reportIntroductionSkillsValue')}</Link>
    </View>
    <ReportText style={styles.paragraph}>{translations.t('skillsReport.reportIntroductionEnd')}</ReportText>
    <Footer />
  </Page>
);

export default Intro;
