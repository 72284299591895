import i18next from 'i18next';
import moment from 'moment';
import { SET_SELECTED_LANGUAGE, SET_SPINNER_VISIBILITY } from './actionTypes/utils';
import { INSTITUTION_TYPES, SKILLS_INSTRUCTION_LINKS } from '../../config/configurations';

export function setSpinnerVisible(spinnerVisible, title = null) {
  return {
    type: SET_SPINNER_VISIBILITY,
    spinnerVisible,
    title
  };
}

export function setSelectedLanguage(language) {
  return {
    type: SET_SELECTED_LANGUAGE,
    language
  };
}

export function setMomentLocaleRule(language) {
  switch (language) {
    case 'en': {
      moment.locale('en-GB', {
        week: {
          dow: 1 // Monday is the first day of the week.
        }
      });
      break;
    }
    case 'it': {
      moment.locale('it', {
        week: {
          dow: 1 // Monday is the first day of the week.
        }
      });
      break;
    }
    default: {
      moment.locale('en-GB', {
        week: {
          dow: 1 // Monday is the first day of the week.
        }
      });
      break;
    }
  }
}

export function setLanguage(language) {
  return (dispatch, getState) => {
    i18next.changeLanguage(language);
    moment.locale(language);
    setMomentLocaleRule(language);
    dispatch(setSelectedLanguage(language));
  };
}

export function getInstructionLink() {
  return (dispatch, getState) => {
    const { institutionType } = getState().user;
    const { selectedLanguage } = getState().utils;
    if (institutionType && institutionType === INSTITUTION_TYPES.SMARTWORKING) {
      return SKILLS_INSTRUCTION_LINKS[INSTITUTION_TYPES.SMARTWORKING][selectedLanguage || 'en'];
    }
    return SKILLS_INSTRUCTION_LINKS.REGULAR[selectedLanguage || 'en'];
  };
}
