import React, { Component } from 'react';

import MDStarRateView from '../MDStarRateField/MDStarRateView';
import { isMobileBrowser } from '../../../../utils/utilsFunctions';
import MDRadioRateView from '../MDStarRateField/MDRadioRateView';

export default class SubSkillScoreField extends Component {
  onHandleChange(value, input) {
    const { onHandleChange, minimumValue } = this.props;
    const finalValue = value < minimumValue ? minimumValue : value;
    if (input) {
      input.onChange(finalValue);
    }
    if (onHandleChange) {
      onHandleChange(finalValue);
    }
  }

  render() {
    const { input, containerstyle, meta, label, minimumValue, textStyle, isRadio } = this.props;
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <div style={{ display: 'inline-flex', ...containerstyle }}>
        {isRadio ? (
          <MDRadioRateView
            value={input.value}
            totalValues={5}
            minimumValue={minimumValue}
            onSelectValue={(value) => this.onHandleChange(value, input)}
            starContainerStyle={{ width: isMobileBrowser() ? 400 : 250 }}
            {...this.props}
          />
        ) : (
          <MDStarRateView
            value={input.value}
            totalValues={5}
            minimumValue={minimumValue}
            onSelectValue={(value) => this.onHandleChange(value, input)}
            starContainerStyle={{ width: isMobileBrowser() ? 400 : 200 }}
            {...this.props}
          />
        )}
        <div style={{ display: 'inline-flex', margin: 'auto 0', width: 530 }}>
          <h4 style={{ margin: 0, marginLeft: 10, ...textStyle, color: hasError ? 'red' : 'unset' }}>{label}</h4>
        </div>
      </div>
    );
  }
}
