import axios from 'axios';
import qs from 'query-string';
import { getClientID, getClientSecret } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export const authenticate = (username, password) => {
  const clientId = getClientID();
  const clientSecret = getClientSecret();
  const config = {
    auth: {
      username: clientId,
      password: clientSecret,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const credentials = {
    grant_type: 'password',
    username,
    password,
  };
  return edulaiInstance.post('/oauth/token', qs.stringify(credentials), config);
};

const configurationInstance = axios.create({
  timeout: 30000,
  baseURL: window.location.origin,
});

export function fetchConfiguration() {
  return configurationInstance.get('/api/v1/backendInfo');
}
