import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';


export default function requireAuthentication(checkAuth) {
  return (Component) => {
    class AuthenticatedComponent extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          authorized: false,
        };
      }

      async componentDidMount() {
        if (checkAuth) {
          const authorized = await checkAuth(this.props);
          this.setState({ authorized });
        }
      }

      async componentDidUpdate(prevProps) {
        const { location } = this.props;
        const { location: oldLocation } = prevProps;
        const locationChanged = location !== oldLocation;
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        if (locationChanged && checkAuth) {
          const authorized = await checkAuth(this.props);
          this.setState({ authorized });
        }
      }

      render() {
        const { authorized } = this.state;
        return (
          <div>
            {authorized
              ? <Component {...this.props} />
              : null
            }
          </div>
        );
      }
    }

    return connect()(AuthenticatedComponent);
  };
}
