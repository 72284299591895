import { withStyles } from '@material-ui/core/styles';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy, initialize } from 'redux-form';

import QuestionForm from '../../components/Forms/QuestionsForm/QuestionForm';
import ListComponent from '../../components/ListComponents/ListComponent';
import QuestionRow from '../../components/ListComponents/RowComponents/QuestionRow';
import Spinner from '../../components/Spinner/Spinner';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as QuestionsActions from '../../redux/actions/questions.actions';
import * as SkillsActions from '../../redux/actions/skills.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import translations from '../../translations/i18next';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import NavigationBar from '../NavigationViews/NavigationBar';
import QuestionIcon from '../../components/Icons/QuestionIcon';
import { COMPONENT_BACKGROUND, EDULAI_BLUE } from '../../styles/styleConsts';

const styles = () => ({
  container: {
    padding: 20
  },
  title: {
    margin: 10
  },
  noQuestionsContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  noQuestionsText: {
    margin: 0,
    color: '#7F888F'
  },
  noQuestionsIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20
  },
  listContainer: {
    paddingTop: 25,
    marginTop: 7,
    padding: 0
  }
});

class QuestionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      this.containerDiv.scrollIntoView({ behavior: 'smooth' });
      this.setState({ isLoading: true });
      await dispatch(SkillsActions.fetchSkillsAndSubSkills());
      await dispatch(QuestionsActions.fetchQuestions());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onSearchQuestion(filterValue) {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      if (filterValue) dispatch(QuestionsActions.setQuestionsFilter('name', filterValue.name));
      dispatch(QuestionsActions.resetQuestionsData());
      await dispatch(QuestionsActions.fetchQuestions());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onResetQuestionsFilters() {
    const { dispatch } = this.props;
    try {
      this.setState({ isLoading: true });
      dispatch(QuestionsActions.resetQuestionsFilters());
      await dispatch(QuestionsActions.fetchQuestions());
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onAppendQuestions(page) {
    const { dispatch } = this.props;
    await dispatch(QuestionsActions.fetchQuestions(page));
  }

  async onCreateNewQuestion(questionData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuestionsActions.createQuestion(questionData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CREATE_QUESTION_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('questions.createQuestionSuccess')
          }
        })
      );
      dispatch(ModalsActions.hideModal('NEW_QUESTION_FORM_MODAL'));
      await dispatch(QuestionsActions.fetchQuestions());
    } catch (error) {
      dispatch(
        ModalsActions.showModal('CREATE_QUESTION_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('questions.createQuestionError')
          }
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onNewQuestionClicked() {
    const { dispatch } = this.props;
    dispatch(destroy('QuestionForm'));
    dispatch(QuestionsActions.setSelectedQuestion({}));
    dispatch(
      ModalsActions.showModal('NEW_QUESTION_FORM_MODAL', {
        modalType: 'MODAL_DIALOG2',
        modalProps: {
          fullScreen: true,
          cancelText: translations.t('modals.closeWithoutSave'),
          content: <QuestionForm onSubmit={(questionData) => this.onCreateNewQuestion(questionData)} />,
          title: translations.t('questions.newQuestion')
        }
      })
    );
  }

  async onModifyQuestion(questionData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuestionsActions.modifyQuestion(questionData));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('MODIFY_QUESTION_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('questions.modifyQuestionSuccess')
          }
        })
      );
      dispatch(ModalsActions.hideModal('EDIT_QUESTION_FORM_MODAL'));
      await dispatch(QuestionsActions.fetchQuestions());
    } catch (error) {
      dispatch(
        ModalsActions.showModal('MODIFY_QUESTION_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('questions.modifyQuestionError')
          }
        })
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  onEditQuestionClicked(question) {
    const {
      dispatch,
      skills: { subSkills }
    } = this.props;
    const selectedQuestion = {
      ...question,
      options: _.map(question.options.optionDTOS, (option) => {
        const skills = {};
        _.forEach(option.skills, (skill) => {
          skills[`_${skill.skillId}`] = skill.score;
        });
        return {
          ...option,
          skills
        };
      })
    };

    dispatch(QuestionsActions.setSelectedQuestion(selectedQuestion));

    const { options } = selectedQuestion;

    const subskillsList = _.map(_.first(options).skills, (value, key) => parseInt(key.slice(1), 10));
    const subskills = {};

    _.forEach(subskillsList, (skill) => {
      const selectedSubskill = _.find(_.flattenDeep(subSkills), { id: skill });
      if (!selectedSubskill) return null;
      subskills[`_${selectedSubskill.parentId}`] = {
        value: selectedSubskill.id,
        label: selectedSubskill.name
      };
    });

    dispatch(
      initialize('QuestionForm', {
        ...question,
        skills: _.map(question.measuredSkills, (skill) => ({
          value: skill.id,
          label: skill.name
        })),
        subskills
      })
    );

    dispatch(
      ModalsActions.showModal('EDIT_QUESTION_FORM_MODAL', {
        modalType: 'MODAL_DIALOG2',
        modalProps: {
          fullScreen: true,
          cancelText: translations.t('modals.closeWithoutSave'),
          content: (
            <QuestionForm
              edit
              onSubmit={(questionData) => this.onModifyQuestion(questionData)}
              onDeleteQuestion={() => this.onDeleteQuestionRequest(question)}
            />
          ),
          title: translations.t('questions.editQuestion')
        }
      })
    );
  }

  onDeleteQuestionRequest(questionData) {
    const { dispatch } = this.props;
    dispatch(
      ModalsActions.showModal('DELETE_QUESTION_MODAL', {
        modalType: 'MODAL_DIALOG',
        modalProps: {
          title: translations.t('forms.warning'),
          bodyText: translations.t('forms.deleteQuestionWarning'),
          onConfirm: () => this.onDeleteQuestionConfirm(questionData)
        }
      })
    );
  }

  async onDeleteQuestionConfirm(questionData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(QuestionsActions.deleteQuestion(questionData.id));
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('DELETE_QUESTION_MODAL'));
      dispatch(ModalsActions.hideModal('EDIT_QUESTION_FORM_MODAL'));
      dispatch(
        ModalsActions.showModal('DELETE_QUESTION_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: translations.t('forms.deleteQuestionSuccess')
          }
        })
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(ModalsActions.hideModal('DELETE_QUESTION_MODAL'));
      const message =
        error && error === 6004
          ? translations.t('forms.deleteQuestionInUseError')
          : translations.t('forms.deleteQuestionError');
      dispatch(
        ModalsActions.showModal('DELETE_SKILL_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message
          }
        })
      );
    }
  }

  render() {
    const {
      classes,
      questions: {
        data: { content: questionsData, pagination }
      }
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div
        ref={(c) => {
          this.containerDiv = c;
        }}
        style={{ backgroundColor: COMPONENT_BACKGROUND }}
      >
        <NavigationBar title={translations.t('header.questions')} icon={<QuestionIcon color="white" />} />
        <div className={classes.container}>
          <ListComponent
            pagination={pagination}
            containerstyle={{ width: isMobileBrowser() ? 'unset' : '100%' }}
            newElementText={translations.t('questions.newQuestion')}
            searchFieldLabel={translations.t('questions.findInTitle')}
            onLoadMore={(page) => this.onAppendQuestions(page)}
            onCreateNew={() => this.onNewQuestionClicked()}
            onSearch={(values) => this.onSearchQuestion(values)}
            onResetFilters={() => this.onResetQuestionsFilters()}
            newButtonStyle={{ marginTop: 20 }}
            actionCardStyle={{ padding: 20 }}
          >
            <div
              className={classes.listContainer}
              style={{
                overflowY: 'scroll'
              }}
            >
              {(!questionsData || _.isEmpty(questionsData)) && !isLoading && (
                <div className={classes.noQuestionsContainer}>
                  <QuestionAnswerIcon className={classes.noQuestionsIcon} />
                  <h4 className={classes.noQuestionsText}>{translations.t('questions.noQuestionsFound')}</h4>
                </div>
              )}
              {_.map(questionsData, (question) =>
                question ? (
                  <QuestionRow
                    key={question.id}
                    question={question}
                    outerContainerstyle={{ marginTop: 15 }}
                    onClick={() => this.onEditQuestionClicked(question)}
                  />
                ) : null
              )}
              {isLoading && _.isEmpty(questionsData) && (
                <Spinner
                  title={translations.t('general.loading')}
                  hideLogo
                  spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
                  titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                />
              )}
            </div>
          </ListComponent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  questions: state.questions,
  skills: state.skills
});

export default connect(mapStateToProps)(withStyles(styles)(QuestionsView));
