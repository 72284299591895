export function orderDatasetByMode(data, mode) {
  const combined = data.labels.map((label, index) => ({
    label,
    value: data.datasets[0].data[index]
  }));

  combined.sort((a, b) => (mode === 'asc' ? a.value - b.value : b.value - a.value));

  const sortedLabels = combined.map((item) => item.label);
  const sortedData = combined.map((item) => item.value);

  return {
    labels: sortedLabels,
    datasets: [
      {
        ...data.datasets[0],
        data: sortedData
      }
    ]
  };
}

export function calculateBubbleSize(value, minSize = 10, maxSize = 80) {
  const minValue = 0;
  const maxValue = 100;

  const clampedValue = Math.max(minValue, Math.min(value, maxValue));

  const size = ((clampedValue - minValue) / (maxValue - minValue)) * (maxSize - minSize) + minSize;

  return size;
}

export const drawTextInBubble = {
  afterDatasetsDraw: function (chart) {
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bubble, index) => {
        const dataPoint = dataset.data[index];

        if (dataPoint.showText) {
          const { x, y } = bubble._model;
          const radius = dataPoint.r;
          const fontSize = Math.min(radius * 0.7, 30);
          ctx.save();
          ctx.font = `bold ${fontSize}px Nunito`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = 'white';
          ctx.fillText(dataPoint.x, x, y);
          ctx.restore();
        }
      });
    });
  }
};
