import _ from 'lodash';
import Immutable from 'seamless-immutable';

import {
  ADD_OPTION,
  APPEND_QUESTIONS,
  DELETE_OPTION,
  EDIT_OPTION,
  RESET_OPTIONS,
  RESET_QUESTIONS_DATA,
  RESET_QUESTIONS_FILTERS,
  SAVE_QUESTIONS,
  SAVE_QUESTION,
  SAVE_QUESTIONS_PAGINATION,
  SET_QUESTIONS_FILTER,
  SET_SELECTED_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION_FROM_STATE,
} from '../actions/actionTypes/questions';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  selectedQuestion: {
    options: [],
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_QUESTIONS:
      return state.setIn(['data', 'content'], action.questions);
    case SAVE_QUESTION:
      return state.setIn(['data', 'content', action.question.id], action.question);
    case SAVE_QUESTIONS_PAGINATION:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_QUESTIONS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_QUESTIONS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case RESET_QUESTIONS_DATA:
      return state.setIn(['data', 'content'], []);
    case APPEND_QUESTIONS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.questions]);
    case SET_SELECTED_QUESTION:
      return state.set('selectedQuestion', action.question);
    case UPDATE_QUESTION: {
      const index = _.findIndex(state.data.content, (data) => data.id === action.question.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.question);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.question]);
    }
    case ADD_OPTION: {
      if (_.isEmpty(state.selectedQuestion.options)) {
        return state.setIn(['selectedQuestion', 'options'], [action.option]);
      }
      return state.setIn(['selectedQuestion', 'options'], [...state.selectedQuestion.options, action.option]);
    }
    case DELETE_OPTION: {
      return state.setIn(
        ['selectedQuestion', 'options'],
        _.filter(
          state.selectedQuestion.options,
          (option) => option.optionNumber !== action.option.optionNumber,
        ),
      );
    }
    case DELETE_QUESTION_FROM_STATE: {
      const questions = _.filter(state.data.content, (questionState) => questionState.id !== action.questionId);
      return state.setIn(['data', 'content'], questions);
    }
    case RESET_OPTIONS:
      return state.setIn(['selectedQuestion', 'options'], []);
    case EDIT_OPTION: {
      const index = _.findIndex(
        state.selectedQuestion.options,
        (option) => option.optionNumber === action.option.optionNumber,
      );
      return state.setIn(['selectedQuestion', 'options', index], action.option);
    }
    default:
      return state;
  }
}
