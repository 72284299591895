import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DomainInfoView from '../../components/DomainComponents/DomainInfoView';
import translations from '../../translations/i18next';
import NavigationBar from '../NavigationViews/NavigationBar';
import DomainIcon from '../../components/Icons/DomainIcon';

const styles = (theme) => ({
  container: {
    padding: 20,
  },
  title: {
    margin: 10,
    color: '#5a5c69',
    fontWeight: '200',
  },
  chartsContainer: {
    marginTop: 10,
  },
});
class DomainManagementView extends Component {
  render() {
    const {
      domain: { data: domainData },
      classes,
    } = this.props;
    return (
      <>
        <NavigationBar title={translations.t('header.domain')} icon={<DomainIcon color="white" />} />
        <div className={classes.container}>
          {domainData && !_.isEmpty(domainData) ? (
            <DomainInfoView domainData={domainData} onLogout={() => this.onLogoutRequest()} />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  domain: state.domain,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(DomainManagementView)));
