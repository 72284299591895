import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import Spinner from '../Spinner/Spinner';
import ListSearchBar from './ListSearchBar';

const ListComponent = ({
  title,
  subtitle,
  pagination,
  onLoadMore,
  children,
  containerstyle,
  actionCardStyle,
  newButtonStyle,
  onSearch,
  onResetFilters,
  onCreateNew,
  newElementText,
  searchFieldLabel,
  isLoading,
  options
}) => (
  <div style={{ ...containerstyle }}>
    <div style={{ backgroundColor: 'white', borderRadius: 8, borderWidth: 10, ...actionCardStyle }}>
      {title && <h4 style={{ marginBottom: 10 }}>{title}</h4>}
      {subtitle && <h4 style={{ marginBottom: 10, fontSize: 15, fontWeight: 100 }}>{subtitle}</h4>}
      {onSearch && (
        <ListSearchBar
          searchFieldLabel={searchFieldLabel}
          onSubmit={(searchValues) => onSearch(searchValues)}
          onResetFilters={() => onResetFilters()}
          options={options}
        />
      )}
      {onCreateNew && (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            ...newButtonStyle
          }}
        >
          <div
            role="button"
            tabIndex={0}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => onCreateNew()}
            onKeyDown={() => onCreateNew()}
          >
            <AddCircleIcon style={{ color: EDULAI_BLUE, fontSize: 80 }} />
            <h4 style={{ fontWeight: 800, fontSize: 16, color: EDULAI_BLUE, marginLeft: 8 }}>
              {(newElementText || translations.t('forms.createNew')).toUpperCase()}
            </h4>
          </div>
        </div>
      )}
    </div>
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loadMore={pagination ? () => onLoadMore(pagination.number + 1) : null}
      hasMore={pagination && pagination.number + 1 < pagination.totalPages}
      loader={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div className="loader" key={0}>
          {translations.t('general.loading')}
        </div>
      }
    >
      {isLoading && (
        <Spinner
          title={translations.t('general.loading')}
          hideLogo
          spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
          titleStyle={{ color: '#80888d', marginTop: 5, fontSize: 12 }}
        />
      )}
      {!isLoading && children}
    </InfiniteScroll>
  </div>
);

export default ListComponent;
