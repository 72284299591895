import _ from 'lodash';
import ReactGA from 'react-ga4';

import * as AuthorizationAPI from '../../api/authorizationAPI';
import * as edulaiInstance from '../../api/instances/edulaiInstance';
import * as UserAPI from '../../api/userAPI';
import { getDefaultAPIBaseURL, LANGUAGE_LOCAL_MAP } from '../../config/configurations';
import AbilityProvider from '../../utils/PermissionsUtils/AbilityProvider';
import PermissionsParser from '../../utils/PermissionsUtils/PermissionsParser';
import { saveDataToLocalStorage } from '../../utils/utilsFunctions';
import {
  LOGOUT,
  SAVE_PERSONAL_ACCESS_RULES,
  SAVE_USER_DATA,
  SET_USER_AUTHENTICATION,
  SET_INSTITUTION_TYPE
} from './actionTypes/user';
import * as AnalyticsActions from './analytics.actions';
import * as DomainsActions from './domain.actions';
import * as PlatformUsersActions from './platformUsers.actions';
import * as QuestionsActions from './questions.actions';
import * as QuizzesActions from './quizzes.actions';
import * as RolesActions from './roles.actions';
import * as SkillsActions from './skills.actions';
import * as UtilsActions from './utils.actions';

/* APIS */
export function saveUserData(userData) {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE_USER_DATA,
      userData
    });
  };
}

export function setUserAuthenticated(value, saveToLocalStorage = true) {
  if (saveToLocalStorage) saveDataToLocalStorage('isAuthenticated', value);
  return {
    type: SET_USER_AUTHENTICATION,
    authenticated: value
  };
}

export function saveUserAccessRules(companyId, accessRules) {
  return {
    type: SAVE_PERSONAL_ACCESS_RULES,
    companyId,
    accessRules
  };
}

export function resetUserData() {
  return { type: LOGOUT };
}

export function setInstitutionType(institutionType) {
  return {
    type: SET_INSTITUTION_TYPE,
    institutionType
  };
}

function setApplicationInterceptors(token) {
  edulaiInstance.setRequestInterceptor(token);
}

export function userLogout() {
  return (dispatch) => {
    dispatch(resetUserData());
    dispatch(setUserAuthenticated(false));
    dispatch(QuizzesActions.resetQuizzesData());
    dispatch(QuestionsActions.resetQuestionsData());
    dispatch(DomainsActions.resetDomainData());
    dispatch(PlatformUsersActions.resetPlatformUsersData());
    dispatch(RolesActions.resetRolesAndPermissions());
    dispatch(SkillsActions.resetSkillsState());
    dispatch(AnalyticsActions.resetAnalyticsData());
    localStorage.clear();
  };
}

export function fetchUser() {
  return async (dispatch, getState) => {
    try {
      if (localStorage.token) {
        const response = await UserAPI.fetchUser();
        if (response && response.data) {
          const { data: userData } = response;
          dispatch(saveUserData(userData));
          const userLanguage = userData && userData.language;
          let languageToSet = LANGUAGE_LOCAL_MAP.ITALIAN;
          if (userLanguage) languageToSet = LANGUAGE_LOCAL_MAP[userLanguage];

          dispatch(UtilsActions.setLanguage(languageToSet.translation));

          return userData;
        }
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function localLogin(token) {
  return async (dispatch, getState) => {
    try {
      setApplicationInterceptors(token);
      const user = await dispatch(fetchUser());
      ReactGA.set({ userId: user.id });
      try {
        await dispatch(SkillsActions.fetchSkills());
      } catch (error) {}
      try {
        await dispatch(DomainsActions.fetchDomainData(1));
      } catch (error) {}
      await dispatch(RolesActions.fetchRoles(user.role.id));
      if (user && user.role && user.role.id) {
        const rolePermissions = await dispatch(RolesActions.fetchRolePermission(user && user.role && user.role.id));
        setUserDomainAbilityProvider(rolePermissions);
      }
      try {
        const response = await AuthorizationAPI.fetchConfiguration();
        const config = response.data;
        if (config.institutionType) {
          dispatch(setInstitutionType(config.institutionType));
        }
      } catch (error) {}
      dispatch(setUserAuthenticated(true));
      return getState().user;
    } catch (error) {
      throw error;
    }
  };
}

export function login(username, password) {
  return async (dispatch) => {
    try {
      edulaiInstance.interceptorEjectRequest();
      dispatch(setUserAuthenticated(true));
      const authResponse = await AuthorizationAPI.authenticate(
        username && username.trim(),
        password && password.trim()
      );
      if (authResponse.data) {
        const { access_token } = authResponse.data;
        saveDataToLocalStorage('token', access_token);
        setApplicationInterceptors(access_token);
        try {
          await dispatch(localLogin(access_token));
        } catch (error) {}
        return authResponse;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function setUserInfoFromLocalStorage() {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (_.isEmpty(user.data)) {
      if (localStorage.token) {
        dispatch(setUserAuthenticated(true));
        try {
          return await dispatch(localLogin(localStorage.token));
        } catch (err) {
          throw new Error();
        }
      }
      throw new Error();
    }
    return user;
  };
}

export function setUserDomainAbilityProvider(permissions) {
  const role = permissions.name;
  const permissionsToParse = _.map(permissions.permissions, (p) => p.name);
  const domainPremissionsParsed = PermissionsParser.parseArray(permissionsToParse); // parsing permissions as { subject: 'X', action: 'Y }
  const abilityProviderHelper = AbilityProvider.getDomainAbilityHelper();
  abilityProviderHelper.setUserRole(null); // reset before write
  abilityProviderHelper.updateAbilities(domainPremissionsParsed);
  abilityProviderHelper.setUserRole(role);
}

export function updateUserLanguage(language) {
  return async (dispatch, getState) => {
    const {
      user: { data: userData }
    } = getState();
    try {
      const updatedUserData = {
        language,
        ..._.omit(userData, 'language')
      };
      const updatedProfile = await UserAPI.updatePersonalProfile(userData.id, updatedUserData);
      if (updatedProfile && updatedProfile.data) {
        return updatedProfile.data;
      }
      return updatedProfile;
    } catch (error) {
      return {};
    }
  };
}

export async function setInstanceConfigurations() {
  let config = {
    baseUrl: getDefaultAPIBaseURL()
  };
  try {
    if (process.env.NODE_ENV === 'production') {
      const response = await AuthorizationAPI.fetchConfiguration();
      config = response.data;
    }
    edulaiInstance.setBaseUrl(config.baseUrl);
    if (config.googleAnalyticsUUID) {
      ReactGA.initialize(config.googleAnalyticsUUID);
    }
    return edulaiInstance;
  } catch (error) {
    throw error;
  }
}
