import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';


const styles = theme => ({
  formControl: {
    width: '100%',
  },
});

class MDSelectView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 80,
    };
  }
  handleChange(event) {
    const { onSelect } = this.props;
    if (onSelect) onSelect(event.target.value);
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.labelRef).offsetWidth,
    });
  }


  render() {
    const { classes, title, value, options, hasError, disabled } = this.props;
    const { labelWidth } = this.state;
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          ref={(ref) => { this.labelRef = ReactDOM.findDOMNode(ref); }}
          htmlFor="outlined-select-simple"
        >
          {title || 'Select'}
        </InputLabel>
        <Select
          value={value}
          disabled={disabled}
          onChange={event => this.handleChange(event)}
          input={(
            <OutlinedInput
              error={hasError}
              labelWidth={labelWidth}
              name="select"
              id="outlined-select-simple"
            />
          )}
        >
          {_.map(options, option => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(MDSelectView);
