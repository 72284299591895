import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import translations from '../../../translations/i18next';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.username)) {
    errors.username = 'required';
  }
  return errors;
};


const ResetPasswordForm = (props) => (
  <div style={{ marginRight: 20 }}>
    <Field
      name="username"
      component={MDTextInputField}
      placeholder={translations.t('login.insertEmail')}
      label="Email"
      required
      multiline
      onFormatText={(text) => text.toLowerCase()}
      containerstyle={{ marginTop: 20 }}
    />
  </div>
);

export default connect()(reduxForm({
  form: 'ResetPasswordForm',
  validate,
})(ResetPasswordForm));
