import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export async function createAnswer(answerInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/answers`, answerInDTO);
}

export async function updateAnswer(answerId, answerInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/answers/${answerId}`, answerInDTO);
}

export async function getAnswers(quizId, userId, params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/answers/${quizId}/${userId}`, paramsToSend);
}

export async function getAnswersBySubmission(submissionId, params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/answers/bySubmission/${submissionId}`, paramsToSend);
}
