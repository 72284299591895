//
// ──────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: P E R M I S S I O N   C O M P O N E N T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────
//


import React from 'react';
import _ from 'lodash';
import { USER_ROLES } from '../../config/configurations';


/*
* This component will render its children only if the
* current user has a specific permission
*/
export default class Permission extends React.Component {
  render() {
    const {
      children,
      do: canDo,
      on,
      roles,
      rolesNot,
      abilityHelper, 
    } = this.props;

    let isAllowed = false;

    if (roles || rolesNot) {
      let rolesToWhitelist = roles;
      if (rolesNot) {
        rolesToWhitelist = _.without(_.values(USER_ROLES), ...rolesNot);
      }
      isAllowed = abilityHelper.hasRolesAndPermissions(rolesToWhitelist, canDo, on);
    } else {
      isAllowed = abilityHelper.hasPermission(canDo, on);
    }
    return (isAllowed ? children : null);
  }
}
