import Immutable from 'seamless-immutable';

import {
  SAVE_BAD_USER,
  SAVE_BEST_USER,
  SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS,
  SAVE_QUIZ_USER_SKILLS_ANALYTICS,
  SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS,
  SAVE_SKILLS_ANALYTICS,
  SAVE_USER_SKILLS_ANALYTICS,
  SAVE_USER_SUBSKILLS_ANALYTICS,
  SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY,
  SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME,
  SAVE_USERS_SUMMARY_SCORES,
  SAVE_USERS_SKILLS_SCORES,
  RESET_ANALYTICS_DATA,
} from '../actions/actionTypes/analytics';

const initialState = Immutable({
  user: {},
  quizzes: {},
  bestUser: {},
  badUser: {},
  selectedQuiz: {},
  skills: {},
  skillsMeans: {
    meansSummary: {},
    meansByTime: {},
  },
  usersScores: {
    summaryScores: {},
    skillsScores: {},
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_SKILLS_ANALYTICS:
      return state.setIn(['user', 'skills'], action.analytics);
    case SAVE_USER_SUBSKILLS_ANALYTICS:
      return state.setIn(['user', 'subskills', action.skill], action.analytics);
    case SAVE_QUIZ_SUBMISSIONS_ENTRIES_ANALYTICS:
      return state.setIn(['quizzes', action.quiz], action.analytics);
    case SAVE_BEST_USER:
      return state.setIn(['bestUser'], action.user);
    case SAVE_BAD_USER:
      return state.setIn(['badUser'], action.user);
    case SAVE_QUIZ_USER_SKILLS_ANALYTICS:
      return state.setIn(['selectedQuiz', action.user, 'skills'], action.analytics);
    case SAVE_QUIZ_USER_SUBSKILLS_ANALYTICS:
      return state.setIn(['selectedQuiz', action.user, 'subskills', action.skill], action.analytics);
    case SAVE_SKILLS_ANALYTICS:
      return state.setIn(['skills'], action.analytics);
    case SAVE_SKILLS_ANALYTICS_MEANS_SUMMARY:
      return state.setIn(['skillsMeans', 'meansSummary'], action.means);
    case SAVE_SKILLS_ANALYTICS_MEAN_BY_TIME:
      return state.setIn(['skillsMeans', 'meansByTime'], action.meansByTime);
    case SAVE_USERS_SUMMARY_SCORES:
      return state.setIn(['usersScores', 'summaryScores'], action.scores);
    case SAVE_USERS_SKILLS_SCORES:
      return state.setIn(['usersScores', 'skillsScores'], action.scores);
    case RESET_ANALYTICS_DATA:
      return initialState;
    default:
      return state;
  }
}
