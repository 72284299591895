//
// ────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: Q U I Z Z E S   A C T I O N   T Y P E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────
//

export const APPEND_QUIZZES = 'APPEND_QUIZZES';
export const RESET_QUIZZES_DATA = 'RESET_QUIZZES_DATA';
export const RESET_QUIZZES_FILTERS = 'RESET_QUIZZES_FILTERS';
export const SAVE_QUIZZES = 'SAVE_QUIZZES';
export const SAVE_QUIZZES_PAGINATION = 'SAVE_QUIZZES_PAGINATION';
export const SET_QUIZZES_FILTER = 'SET_QUIZZES_FILTER';
export const SET_SELECTED_QUIZ = 'SET_SELECTED_QUIZ';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
