/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
import { Divider } from '@material-ui/core';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import { USER_ROLES } from '../../config/configurations';
import * as AnalyticsActions from '../../redux/actions/analytics.actions';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import translations from '../../translations/i18next';
import RadarChart from '../Charts/RadarChart';
import MDAccordion from '../MDAccordion/MDAccordion';
import Spinner from '../Spinner/Spinner';
import StarRateView from '../StarRateView/StarRateView';
import { EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import SubSkillDescription from './SubSkillDescription';
import SkillDescription from './SkillDescription';
import ListSkillChart from '../Charts/ListSkillChart';
import DownloadPdfButton from '../Buttons/DownloadPdfButton';
import ChangeViewButton from '../Buttons/ChangeViewButton';
import InstructionLinkButton from '../Buttons/InstructionLinkButton';

class QuizUserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selfAssessmentData: null,
      isRadarView: true
    };
  }

  async componentDidMount() {
    const {
      dispatch,
      quiz,
      user,
      hideSubSkills,
      skills: {
        data: { content: skillsData }
      }
    } = this.props;
    try {
      await dispatch(AnalyticsActions.fetchUserSkillsAnalytics(quiz, user));
      if (!hideSubSkills) {
        const subSkillsAnalyticsPromises = _.map(skillsData, async (skill) =>
          dispatch(AnalyticsActions.fetchUserSubSkillsAnalytics(skill.id, quiz, user))
        );
        await Promise.all(subSkillsAnalyticsPromises);
      }
      const userData = await dispatch(PlatformUsersActions.fetchUserDetails(user));
      const userSelfAssessmentData =
        userData && userData.selfAssessmentDTO && userData.selfAssessmentDTO.skillSelfAssessmentDTOSet
          ? userData.selfAssessmentDTO.skillSelfAssessmentDTOSet
          : [];
      this.setState({ selfAssessmentData: userSelfAssessmentData, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      skills: {
        data: { content: skillsData },
        subSkills
      },
      analytics: { selectedQuiz: quizUsersAnalytics },
      user,
      hideSubSkills,
      selectedQuiz,
      userData,
      containerStyle,
      onDownloadReport,
      canDownloadReport
    } = this.props;
    const { isLoading, selfAssessmentData } = this.state;

    const subSkillsFlat = _(subSkills).flatten().groupBy('parentId').value();

    const isAnalyticsLoaded = quizUsersAnalytics && quizUsersAnalytics[`${user}`];

    const skillsAnalytics = _.map(skillsData, (skill) => {
      const current = isAnalyticsLoaded && _.find(quizUsersAnalytics[`${user}`]['skills'], { skillId: skill.id });
      return {
        name: skill.name,
        id: skill.id,
        value: current ? current.score : 0
      };
    });

    const skillsAnalyticsValue = _.map(skillsAnalytics, 'value');
    const selfAssessmentDataValue = _.map(
      _.orderBy(selfAssessmentData, (skill) => skill.skillId),
      'score'
    );

    const chartDatasets = {
      datasets: [
        {
          backgroundColor: 'rgba(72, 61, 139, 0.2)',
          borderColor: EDULAI_PURPLE,
          pointBackgroundColor: EDULAI_PURPLE,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.currentScores'),
          data: skillsAnalyticsValue
        },
        {
          backgroundColor: 'rgba(245, 125, 36,0.2)', // It is the same orange with opacity
          borderColor: EDULAI_ORANGE,
          pointBackgroundColor: EDULAI_ORANGE,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          label: translations.t('analytics.selfAssessmentScores'),
          data: selfAssessmentDataValue
        }
      ],
      labels: _.map(skillsAnalytics, (item) => item.name)
    };
    const canSeeScore =
      selectedQuiz.hideScore === false || (selectedQuiz.hideScore === true && userData.role.name !== USER_ROLES.USER);
    return (
      <div style={{ width: '100%', ...containerStyle }}>
        {isLoading && (
          <Spinner
            title={translations.t('general.loading')}
            hideLogo
            spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
            titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
          />
        )}
        {!isLoading && (
          <div style={{ width: '100%', height: '100%' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <h2 style={{ fontWeight: 'normal', color: EDULAI_PURPLE }}>
                  {translations.t('analytics.userQuizSkillsScore')}
                </h2>
                <InstructionLinkButton />
              </div>
              {canSeeScore && canDownloadReport ? (
                <DownloadPdfButton
                  onClick={() =>
                    onDownloadReport({
                      skillsAnalytics,
                      skillsData,
                      subSkillsFlat,
                      subSkills: quizUsersAnalytics[`${user}`]['subskills'],
                      selfAssessmentData,
                      language: this.props.language
                    })
                  }
                />
              ) : null}
            </div>
            {canSeeScore ? (
              <div
                style={{
                  backgroundColor: '#F9FAFB',
                  padding: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {this.state.isRadarView ? (
                  <RadarChart testId={`chart-${user}`} data={chartDatasets} min={0} max={5} stepSize={1} />
                ) : (
                  <div style={{ width: '100%' }}>
                    <ListSkillChart
                      testId={`chart-${user}`}
                      skillsAnalytics={skillsAnalytics}
                      selfAssessmentData={_.orderBy(selfAssessmentData, (skill) => skill.skillId)}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', width: '100vh', marginTop: 10, marginBottom: 20 }}>
                <WarningIcon style={{ color: 'red' }} />
                <h4 style={{ fontSize: 18, fontWeight: '100', margin: 0, marginLeft: 10 }}>
                  {translations.t('quizzes.hideScoreAnalyticsDescription')}
                </h4>
              </div>
            )}
            {canSeeScore ? (
              <>
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: 20
                  }}
                >
                  <ChangeViewButton onClick={() => this.setState({ isRadarView: !this.state.isRadarView })} />
                </div>
                <SkillDescription
                  skillsAnalytics={skillsAnalytics}
                  selfAssessmentData={selfAssessmentData}
                  testId={`skills-description-${user}`}
                />
              </>
            ) : null}
            {canSeeScore ? (
              !hideSubSkills ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2 style={{ fontWeight: 'normal', color: EDULAI_PURPLE }}>
                    {translations.t('analytics.subskillsScores')}
                  </h2>
                  <InstructionLinkButton />
                </div>
              ) : null
            ) : null}
            <div id={`test-all-skills`}>
              {canSeeScore
                ? _.map(skillsData, (skill) => {
                    const skillData = _.find(skillsAnalytics, (d) => d.id === skill.id);
                    const skillTotalPoints = skillData && skillData.value ? parseFloat(skillData.value).toFixed(1) : 0;
                    return hideSubSkills ? (
                      <div id={`test-${user}-${skill.id}`}>
                        <h3 style={{ marginBottom: 0, color: EDULAI_PURPLE }}>{skill.name}</h3>
                        <StarRateView
                          value={skillTotalPoints}
                          totalValues={5}
                          starStyle={{ width: 20 }}
                          unselectedStarStyle={{ width: 20 }}
                          titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0 }}
                        />
                      </div>
                    ) : (
                      <MDAccordion
                        testId={`test-${user}-${skill.id}`}
                        title={skill.name}
                        containerstyle={{ marginTop: 10, borderWidth: 1, borderColor: 'black', BorderStyle: 'solid' }}
                        titleStyle={{ fontSize: 20, color: EDULAI_PURPLE }}
                        key={`userAnalytics-${skill.id}-${user}-${moment().valueOf()}`}
                        accordionStyle={{
                          borderWidth: 1,
                          borderColor: '#D1D5DB',
                          borderStyle: 'solid',
                          borderRadius: 8,
                          boxShadow: 'none'
                        }}
                        subtitle={
                          <StarRateView
                            value={skillTotalPoints}
                            totalValues={5}
                            starStyle={{ width: 20 }}
                            unselectedStarStyle={{ width: 20 }}
                            titleStyle={{ fontWeight: 100, fontSize: 15, margin: 0, color: EDULAI_PURPLE }}
                          />
                        }
                      >
                        {isAnalyticsLoaded && (
                          <SubSkillDescription
                            subSkills={subSkillsFlat[skill.id]}
                            userAnalytics={quizUsersAnalytics[`${user}`]['subskills'][skill.id]}
                            skill={skill}
                          />
                        )}
                        <Divider />
                        <div style={{ padding: 10, backgroundColor: '#FAFBFB' }}>
                          {_.map(subSkillsFlat[skill.id], (subSkill, key) => {
                            const subSkillValue =
                              isAnalyticsLoaded &&
                              _.find(quizUsersAnalytics[`${user}`]['subskills'][skill.id], { skillId: subSkill.id });
                            return (
                              <div key={subSkill.id} style={{ marginBottom: 20 }}>
                                <StarRateView
                                  title={subSkill.name}
                                  value={subSkillValue ? subSkillValue.score : 0}
                                  totalValues={5}
                                  starStyle={{ width: 20 }}
                                  unselectedStarStyle={{ width: 20 }}
                                  starContainerStyle={{ marginBottom: 15, marginTop: 10 }}
                                  titleContainerStyle={{ marginBottom: 10 }}
                                  titleStyle={{ fontWeight: 100, fontSize: 18, margin: 0 }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </MDAccordion>
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  analytics: state.analytics,
  skills: state.skills,
  userData: state.user.data,
  selectedQuiz: state.quizzes.selectedQuiz
});

export default connect(mapStateToProps)(QuizUserAnalytics);
