import { map } from 'lodash';
import React from 'react';
import { EDULAI_ORANGE, EDULAI_PURPLE } from '../../styles/styleConsts';
import { isMobileBrowser } from '../../utils/utilsFunctions';
import { LinearProgress, Tooltip, withStyles } from '@material-ui/core';
import translations from '../../translations/i18next';

export const SkillLinearProgess = withStyles({
  root: {
    height: 8,
    borderRadius: 4
  },
  colorPrimary: {
    backgroundColor: '#D0D5D9'
  },
  bar: {
    backgroundColor: EDULAI_PURPLE
  }
})(LinearProgress);

export const SelfValueSKillLinearProgess = withStyles({
  root: {
    height: 8,
    borderRadius: 4
  },
  colorPrimary: {
    backgroundColor: '#D0D5D9'
  },
  bar: {
    backgroundColor: EDULAI_ORANGE
  }
})(LinearProgress);

const showUINumber = (value) => {
  if (!value) return 0;
  const num = parseFloat(value);
  if (isNaN(num)) {
    throw new TypeError('Input is not a valid number');
  }

  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
};

const ListSkillChartItem = ({ skill, selfAssesmentValue }) => {
  return (
    <div>
      <h5 style={{ color: EDULAI_PURPLE, fontSize: 16, margin: 0, marginBottom: 5 }}>{skill.name}</h5>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: '90%' }}>
          <Tooltip title={showUINumber(skill.value)} placement="top" arrow>
            <SkillLinearProgess variant="determinate" value={(skill.value / 5) * 100} style={{ width: '100%' }} />
          </Tooltip>
        </div>
        <div style={{ width: '10%', display: 'flex' }}>
          <h3 style={{ margin: 0, padding: 0, color: EDULAI_PURPLE, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
            {showUINumber(skill.value)}
          </h3>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0 }}>
        <div style={{ width: '90%' }}>
          <Tooltip title={showUINumber(selfAssesmentValue)} placement="top" arrow>
            <SelfValueSKillLinearProgess
              variant="determinate"
              value={(selfAssesmentValue / 5) * 100}
              style={{ width: '100%' }}
            />
          </Tooltip>
        </div>
        <div style={{ width: '10%', display: 'flex' }}>
          <h3 style={{ margin: 0, padding: 0, color: EDULAI_ORANGE, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
            {showUINumber(selfAssesmentValue)}
          </h3>
        </div>
      </div>
    </div>
  );
};

const ListSkillChart = ({ skillsAnalytics, selfAssessmentData, testId }) => {
  return (
    <div id={testId}>
      <div
        style={{
          gridTemplateColumns: isMobileBrowser() ? 'none' : `repeat(2, 6fr)`,
          rowGap: '20px',
          columnGap: '80px',
          display: 'grid'
        }}
      >
        {map(skillsAnalytics, (skill, index) => (
          <ListSkillChartItem skill={skill} selfAssesmentValue={selfAssessmentData[index].score} />
        ))}
      </div>
      <div style={{ marginTop: 60 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div
              style={{
                height: 18,
                width: 45,
                border: `3px solid ${EDULAI_PURPLE}`,
                backgroundColor: 'rgba(72, 61, 139, 0.2)'
              }}
            />
            <p style={{ margin: 0, marginLeft: 10, color: '#6B7280', fontSize: 15 }}>
              {translations.t('analytics.currentScores')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 60
            }}
          >
            <div
              style={{
                height: 18,
                width: 45,
                border: `3px solid ${EDULAI_ORANGE}`,
                backgroundColor: 'rgba(245, 125, 36,0.2)'
              }}
            />
            <p style={{ margin: 0, marginLeft: 10, color: '#6B7280', fontSize: 15 }}>
              {translations.t('analytics.selfAssessmentScores')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSkillChart;
