import _ from 'lodash';
import moment from 'moment';
import * as DomainsAPI from '../../api/domainAPI';
import { SAVE_DOMAIN_DATA, RESET_DOMAIN_DATA } from './actionTypes/domains';

export function saveDomainData(domainData) {
  return {
    type: SAVE_DOMAIN_DATA,
    domainData,
  };
}

export function saveParsedDomainData(domainData) {
  return (dispatch) => {
    const domainDataToSave = _.omit(domainData, 'customFields');
    let { customFields } = domainData;
    try {
      customFields = JSON.parse(domainData.customFields);
    } catch (error) {}
    dispatch(saveDomainData({ ...domainDataToSave, customFields }));
  };
}

export function resetDomainData() {
  return { type: RESET_DOMAIN_DATA };
}

export function fetchDomainData() {
  return async (dispatch) => {
    try {
      const domainInfoResponse = await DomainsAPI.fetchDomainInfo(1);
      if (domainInfoResponse && domainInfoResponse.data) {
        dispatch(saveParsedDomainData(domainInfoResponse.data));
        return domainInfoResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
} 

export function createCustomField(customFieldDTO) {
  return async (dispatch, getState) => {
    const { domain: { data: domainData } } = getState();
    try {
      let newCustomFields;
      let oldCustomFields = domainData.customFields;
      const customFieldId = String(moment().valueOf()).substr(-6);
      const customFieldData = { id: _.toNumber(customFieldId), ...customFieldDTO };
      try { oldCustomFields = JSON.parse(domainData.customFields); } catch (error) {};
      if (_.isEmpty(oldCustomFields)) {
        newCustomFields = [customFieldData];
      } else {
        newCustomFields = [...oldCustomFields, customFieldData];
      }
      const customFieldsToSave = { customFields: JSON.stringify(newCustomFields) };
      const customFieldResponse = await DomainsAPI.createCustomField(1, customFieldsToSave);
      if (customFieldResponse && customFieldResponse.data) {
        dispatch(saveParsedDomainData(customFieldResponse.data));
        return customFieldResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function editCustomField(customFieldDTO) {
  return async (dispatch, getState) => {
    const { domain: { data: domainData } } = getState();
    try {
      let newCustomFields;
      let oldCustomFields = domainData.customFields;
      // New custom field id is composed by the last 6 digit of unixtimestamp. This is done to avoid a ReduxForm bug for long field ids
      try { oldCustomFields = JSON.parse(domainData.customFields); } catch (error) {};
      if (_.isEmpty(oldCustomFields)) {
        newCustomFields = [customFieldDTO];
      } else {
        // Remove and then add it again;
        newCustomFields = _.filter(oldCustomFields, (oldCustomField) => oldCustomField.id !== customFieldDTO.id);
        newCustomFields = [...newCustomFields, customFieldDTO];
      }
      const customFieldsToSave = { customFields: JSON.stringify(newCustomFields) };
      const customFieldResponse = await DomainsAPI.createCustomField(1, customFieldsToSave);
      if (customFieldResponse && customFieldResponse.data) {
        dispatch(saveParsedDomainData(customFieldResponse.data));
        return customFieldResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function deleteCustomField(customFieldDTO) {
  return async (dispatch, getState) => {
    const { domain: { data: domainData } } = getState();
    try {
      let newCustomFields;
      let oldCustomFields = domainData.customFields;
      try { oldCustomFields = JSON.parse(domainData.customFields); } catch (error) {};
      if (_.isEmpty(oldCustomFields)) {
        newCustomFields = [];
      } else {
        newCustomFields = _.filter(oldCustomFields, (oldCustomField) => oldCustomField.id !== customFieldDTO.id);
      }
      const customFieldToSave = { customFields: JSON.stringify(newCustomFields) };
      const customFieldResponse = await DomainsAPI.createCustomField(1, customFieldToSave);
      if (customFieldResponse && customFieldResponse.data) {
        dispatch(saveParsedDomainData(customFieldResponse.data));
        return customFieldResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function updateDomainType(domainTypeDTO) {
  return async (dispatch) => {
    try {
      const domainUpdateResponse = await DomainsAPI.updateDomainType(1, domainTypeDTO);
      if (domainUpdateResponse && domainUpdateResponse.data) {
        dispatch(saveParsedDomainData(domainUpdateResponse.data));
        return domainUpdateResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}


export function addDomainCredits(creditsDTO) {
  return async (dispatch) => {
    try {
      const updateCreditsResponse = await DomainsAPI.addDomainCredits(1, creditsDTO);
      if (updateCreditsResponse && updateCreditsResponse.data) {
        dispatch(saveParsedDomainData(updateCreditsResponse.data));
        return updateCreditsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function setDomainCredits(creditsDTO) {
  return async (dispatch) => {
    try {
      const addCreditsResponse = await DomainsAPI.setDomainCredits(1, creditsDTO);
      if (addCreditsResponse && addCreditsResponse.data) {
        dispatch(saveParsedDomainData(addCreditsResponse.data));
        return addCreditsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}



export function updateDomainEnableStatus(domainEnableDTO) {
  return async (dispatch) => {
    try {
      const updateDomainEnableResponse = await DomainsAPI.updateDomainEnableStatus(1, domainEnableDTO);
      if (updateDomainEnableResponse && updateDomainEnableResponse.data) {
        dispatch(saveDomainData(updateDomainEnableResponse.data));
        return updateDomainEnableResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}