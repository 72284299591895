import IconSave from '@material-ui/icons/Save';
import React from 'react';
import { connect } from 'react-redux';
import MDButton from '../MDButton/MDButton';
import { EDULAI_BLUE } from '../../styles/styleConsts';

class CSVButton extends React.Component {
  render() {
    const { fetchCSVData, buttonStyle, containerstyle } = this.props;
    return (
      <div style={{ ...containerstyle }}>
        <MDButton
          containerstyle={{ width: '100%', marginTop: 0, ...buttonStyle }}
          titleStyle={{ marginRight: 0 }}
          backgroundColor={EDULAI_BLUE}
          icon={<IconSave style={{ color: 'white' }} />}
          onClick={() => fetchCSVData()}
        />
      </div>
    );
  }
}

export default connect()(CSVButton);
