import { withStyles } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import _ from 'lodash';
import React from 'react';

import translations from '../../../../translations/i18next';
import ListComponent from '../../../ListComponents/ListComponent';
import Spinner from '../../../Spinner/Spinner';
import SelectableRow from './SelectableRow';
import { EDULAI_BLUE } from '../../../../styles/styleConsts';

const styles = () => ({
  container: {
    padding: 20
  },
  title: {
    color: '#5a5c69',
    fontWeight: '200'
  },
  noQuizzesContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  noQuizzesText: {
    margin: 0,
    color: '#7F888F'
  },
  noQuizzesIcon: {
    fontSize: 40,
    color: '#7F888F',
    marginRight: 20
  }
});

class SelectableRowField extends React.Component {
  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  onClick(o) {
    const { input, isSingleChoice } = this.props;
    const checkedItems = isSingleChoice ? null : input.value || [];
    if (_.includes(checkedItems, o.id) || _.isEqual(checkedItems, o.id)) {
      // if already selected
      this.onHandleChange(
        _.filter(checkedItems, (i) => i !== o.id),
        input
      );
    } else if (isSingleChoice) {
      this.onHandleChange(o.id, input);
    } else {
      this.onHandleChange([...checkedItems, o.id], input);
    }
  }

  render() {
    const {
      options,
      input,
      meta,
      title,
      mandatory,
      containerStyle,
      classes,
      onInfoClick,
      isLoading,
      customOptionElement,
      titleStyle
    } = this.props;
    const checkedItems = input.value || [];
    return (
      <div style={containerStyle}>
        {title ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h5 style={{ fontSize: 15, color: meta.touched && meta.error ? '#EF5350' : 'black', ...titleStyle }}>
              {title}
            </h5>
            {mandatory && <h5 style={{ color: 'red' }}>*</h5>}
          </div>
        ) : null}
        <div>
          <ListComponent containerstyle={{ padding: 0 }}>
            <div
              style={{
                overflowY: 'scroll',
                maxHeight: 400
              }}
            >
              {!isLoading &&
                options &&
                _.map(options, (option) => (
                  <SelectableRow
                    key={option.id}
                    hasError={meta.touched && meta.error}
                    onClick={() => this.onClick(option)}
                    customOptionElement={customOptionElement}
                    isChecked={_.includes(checkedItems, option.id) || _.isEqual(checkedItems, option.id)}
                    title={option.name}
                    skills={option.measuredSkills}
                    onInfoClick={() => onInfoClick(option)}
                  />
                ))}
              {!isLoading && _.isEmpty(options) && (
                <div className={classes.noQuizzesContainer}>
                  <QuestionAnswerIcon className={classes.noQuizzesIcon} />
                  <h4 className={classes.noQuizzesText}>{translations.t('questions.noQuestionsFound')}</h4>
                </div>
              )}
              {isLoading && (
                <Spinner
                  title={translations.t('general.loading')}
                  hideLogo
                  spinnerStyle={{ color: EDULAI_BLUE, marginTop: 10 }}
                  titleStyle={{ color: '#3f3f3f', marginTop: 5 }}
                />
              )}
            </div>
          </ListComponent>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SelectableRowField);
