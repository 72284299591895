
import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export function fetchUser() {
  return edulaiInstance.get(`/api/${API_VERSION}/users/me`);
}

export function confirmUserRegistration(params) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/activate`, params);
}

export function confirmPassword(params) {
  return edulaiInstance.post(`/api/${API_VERSION}/users/savePassword`, params);
}

export function updatePersonalProfile(userId, userData) {
  return edulaiInstance.put(`/api/${API_VERSION}/users/${userId}`, userData);
}
