import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export async function getSubmissionByQuiz(quizId, submissionsStatusType, params) {
  const paramsToSend = {
    params,
    submissionsStatusType,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/submissions/byQuiz/${quizId}`, paramsToSend);
}

export async function getSubmissionsByUser(userId, params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/submissions/byUser/${userId}`, paramsToSend);
}

export async function createSubmission(submissionInDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/submissions`, submissionInDTO);
}

export async function updateSubmission(submissionId, submissionStatusType) {
  return edulaiInstance.put(`/api/${API_VERSION}/submissions/${submissionId}`, { submissionStatusType });
}
