import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export const fetchUserSkillsAnalytics = (userId, quizId) => {
  const paramsToSend = {
    params: {
      quizId,
    },
  };
  if (quizId) {
    return edulaiInstance.get(`/api/${API_VERSION}/analytics/skillsAnalytics/${userId}`, paramsToSend);
  }
  return edulaiInstance.get(`/api/${API_VERSION}/analytics/skillsAnalytics/${userId}`);
};

export const fetchUserSubSkillsAnalytics = (userId, quizId, skillId) => {
  const paramsToSend = {
    params: {
      skillId,
    },
  };
  if (quizId) {
    paramsToSend.params.quizId = quizId;
  }
  return edulaiInstance.get(`/api/${API_VERSION}/analytics/skillsAnalytics/${userId}`, paramsToSend);
};

export const getSubmissionsEntriesAnalyticsPerQuiz = (quizId) => (
  edulaiInstance.get(`/api/${API_VERSION}/analytics/submissionsEntriesPerQuiz/${quizId}`)
);

export const getBadUserAnalytics = () => (
  edulaiInstance.get(`/api/${API_VERSION}/analytics/badUser`)
);

export const getBestUserAnalytics = () => (
  edulaiInstance.get(`/api/${API_VERSION}/analytics/bestUser`)
);

export const getSkillAnalyticsByTime = (skillsFilterInDTO, userId = null) => {
  const paramsToSend = {
    params: {
      userId,
    },
  };
  return edulaiInstance.post(`/api/${API_VERSION}/analytics/skillsAnalyticsByTime`, skillsFilterInDTO, paramsToSend);
};
