import Immutable from 'seamless-immutable';
import {
  SAVE_DOMAIN_DATA,
  RESET_DOMAIN_DATA,
} from '../actions/actionTypes/domains';

const initialState = Immutable({
  data: [],
  domainId: 1,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_DOMAIN_DATA:
      return state.set('data', action.domainData);
    case RESET_DOMAIN_DATA:
      return initialState;
    default:
      return state;
  }
}
