import {
  SHOW_MODAL,
  HIDE_MODAL,
} from './actionTypes/modal';

export function showModal(id, data) {
  return {
    type: SHOW_MODAL,
    id,
    data,
  };
}

export function hideModal(id) {
  return {
    type: HIDE_MODAL,
    id,
  };
}
