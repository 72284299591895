//
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P A N Y   A N D   O R G A N I Z A T I O N   A B I L I T Y   P R O V I D E R : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
//


import AbilityHelper from './AbilityHelper';

class AbilityProvider {
  domainAbilityHelper = new AbilityHelper(); // For the outer level

  getDomainAbilityHelper() {
    return this.domainAbilityHelper;
  }

}

export default new AbilityProvider();
