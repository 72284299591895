import { StyleSheet, View } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import ReportText from './ReportText';

const styles = StyleSheet.create({
  header: {
    marginTop: 32,
    marginLeft: 45,
    marginBottom: 25
  },
  titleText: {
    fontSize: 12,
    color: '#333',
    fontWeight: 'bold'
  },
  date: {
    fontSize: 10,
    color: '#333'
  }
});

const Header = ({ user }) => {
  return (
    <View style={styles.header} fixed>
      <ReportText style={styles.titleText}>
        {user.name} {user.surname}
      </ReportText>
      <ReportText style={styles.date}>{moment().format('LL')}</ReportText>
    </View>
  );
};

export default Header;
