import { Avatar } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import { isMobileBrowser } from '../../utils/utilsFunctions';

const NavigationBar = ({ title, userData, history, icon, language }) => {
  let userInitials = userData
    ? `${userData.name[0].toUpperCase() || ''}${userData.surname[0].toUpperCase() || ''}`
    : '';
  const isMobile = isMobileBrowser();
  return (
    <>
      {userData && userData.username && !isMobile ? (
        <div
          style={{
            height: 60,
            width: 'calc(100% - 260px)',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 20,
            position: 'fixed',
            zIndex: 1250
          }}
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => history.push('/settings')}
            onKeyDown={(e) => history.push('/settings')}
            style={{
              cursor: 'pointer',
              borderWidth: 1,
              borderColor: '#D1D5DB',
              borderStyle: 'solid',
              borderRadius: 50,
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {userData && <Avatar style={{ backgroundColor: EDULAI_BLUE, color: 'white' }}>{userInitials}</Avatar>}
          </div>
        </div>
      ) : null}
      <div style={{ paddingTop: isMobile ? 0 : 60 }}>
        <div
          style={{
            width: '100%',
            background: 'linear-gradient(to right, #5048E5, #F57D24)',
            height: 85,
            paddingLeft: isMobile ? 20 : 30,
            display: 'flex',
            paddingTop: 5,
            alignItems: 'center'
          }}
        >
          {icon != null ? icon : null}
          <h1 style={{ color: 'white', marginLeft: icon ? 22 : 0 }}>{title}</h1>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.user.data,
    language: state.utils.selectedLanguage
  };
}

export default connect(mapStateToProps)(withRouter(NavigationBar));
