import 'moment/locale/it';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BaseAlert from '../../components/Modals/BaseAlert';
import SpinnerOverlay from '../../components/Spinner/SpinnerOverlay';
import { detectBrowserLanguage } from '../../utils/utilsFunctions';
import * as UtilsActions from '../../redux/actions/utils.actions';

class AppContainer extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    if (localStorage && !localStorage.token) {
      dispatch(UtilsActions.setLanguage(detectBrowserLanguage()));
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <link href="https://fonts.googleapis.com/css?family=Nunito&display=swap" rel="stylesheet" />
          </Helmet>
          {children}
          <BaseAlert />
          <SpinnerOverlay />
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(AppContainer));
