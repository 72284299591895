import * as TagsAPI from '../../api/tagsAPI';
import { SAVE_TAGS } from './actionTypes/tags';

export const saveTags = (tags) => ({
  type: SAVE_TAGS,
  tags,
});

export const fetchTags = (page = 0, pageSize = 100) => (
  async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const response = await TagsAPI.fetchTags(params);
      if (response.data && response.data.content) {
        dispatch(saveTags(response.data.content));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
);
