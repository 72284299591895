//
// ────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: C O N F I R M   R E G I S T R A T I O N   V I E W : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────
//

import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import _ from 'lodash';
import CardContent from '@material-ui/core/CardContent';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import EdulaiLogo from '../../assets/logo.png';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalsActions from '../../redux/actions/modal.actions';
import loginBackground from '../../assets/login.png';
import translations from '../../translations/i18next';
import ConfirmRegistrationForm from '../../components/Forms/LoginRegisterForms/ConfirmRegistrationForm';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import MDButton from '../../components/MDButton/MDButton';
import { LANGUAGE_LOCAL_MAP, PRIVACY_POLICY_LINKS } from '../../config/configurations';

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

class ConfirmRegistrationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      token: null,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const searchParameters = history && history.location && history.location.search;
    const parsedParameters = queryString.parse(searchParameters);
    if (parsedParameters && !_.isEmpty(parsedParameters)) {
      const { lang, token } = parsedParameters;
      this.setState({ lang, token });
      const platfromLanguage = LANGUAGE_LOCAL_MAP[lang || 'ENGLISH'].translation;
      this.onChangeLanguage(platfromLanguage);
    } else {
      history.push('/login');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedLanguage: currentLanguage } = this.props;
    const { selectedLanguage: nextLanguage } = nextProps;
    if (currentLanguage !== nextLanguage) this.onChangeLanguage(nextLanguage);
  }

  onChangeLanguage(language) {
    i18next.changeLanguage(language);
    this.forceUpdate();
  }

  async onConfirmRegistration(registrationData) {
    const { token } = this.state;
    const { dispatch, history } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisible(true, translations.t('login.creatingYourAccount')));
      await dispatch(
        PlatformUsersActions.confirmUserRegistration(token, registrationData.newPassword),
      );
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('ACCOUNT_SUCCESS', {
          modalType: 'MODAL_DIALOG',
          modalProps: {
            title: translations.t('modals.compliment'),
            bodyText: translations.t('login.createAccountSuccess'),
            onConfirm: () => {
              dispatch(ModalsActions.hideModal('ACCOUNT_SUCCESS'));
              history.push('/login');
            },
            confirmText: 'Login',
            hideCancel: true,
          },
        }),
      );
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('CONFIRM_REGISTRATION_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('login.confirmRegistrationError'),
          },
        }),
      );
    }
  }

  render() {
    const { dispatch } = this.props;
    return (
      <div
        style={{
          height: '109vh',
          paddingTop: 20,
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
        }}
      >
        <LanguageSelector
          language={i18next.language}
          onSelectLanguage={(language) => this.onChangeLanguage(language)}
        />
        <Card
          style={{
            width: '50%',
            margin: 'auto',
            padding: 10,
            paddingBottom: 0,
            marginTop: 120,
            borderRadius: 10,
          }}
        >
          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={EdulaiLogo}
                alt="edulai"
                className="logo"
                style={{ margin: 10, marginTop: 0, alignSelf: 'center', height: 65 }}
              />
              <h1 style={{ margin: 5 }}>Skills Checker</h1>
              <ConfirmRegistrationForm
                onSubmit={(registrationData) => this.onConfirmRegistration(registrationData)}
                onOpenPrivacyModal={() =>
                  window.open(PRIVACY_POLICY_LINKS[this.props.selectedLanguage || 'it'])
                }
              />
            </div>
            <div style={{ marginTop: 0 }}>
              <MDButton
                className="gradient-button"
                title={translations.t('forms.confirmRegistration')}
                backgroundColor={EDULAI_BLUE}
                titleStyle={{ fontSize: 18 }}
                containerstyle={{ width: 300, margin: 'auto' }}
                onClick={() => dispatch(submit('ConfirmRegistrationForm'))}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLanguage: state.utils.selectedLanguage,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(ConfirmRegistrationView)));
