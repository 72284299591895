import { IconButton, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import { EDULAI_BLUE } from '../../styles/styleConsts';
import translations from '../../translations/i18next';
import MDButton from '../MDButton/MDButton';

const styles = () => ({
  innerContainer: {
    padding: 20,
    paddingTop: 0,
    paddingLeft: 25,
  },
  actionsContainer: {
    marginBottom: 20,
  },
  bodyText: {
    margin: 0,
    fontWeight: '100',
    fontSize: 18,
  },
});

function Transition(props) {
  return <Grow {...props} />;
}

class MDDialogDelete extends React.Component {
  onClose() {
    const { onCancel, hideModal } = this.props;
    if (onCancel) {
      onCancel();
    } else {
      hideModal();
    }
  }

  onConfirm() {
    const { onConfirm, hideModal } = this.props;
    if (onConfirm) {
      onConfirm();
    } else {
      hideModal();
    }
  }

  onDelete() {
    const { onDelete, hideModal } = this.props;
    if (onDelete) {
      onDelete();
    } else {
      hideModal();
    }
  }

  render() {
    const {
      title,
      bodyText,
      fullWidth,
      fullScreen,
      cancelText,
      content,
      confirmText,
      onConfirm,
      titleStyle,
      bodyTextStyle,
      classes,
    } = this.props;
    return (
      <Dialog
        open
        fullWidth={fullWidth}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullScreen={fullScreen}
        keepMounted
        onClose={() => this.onClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ position: 'relative' }}>
          <IconButton onClick={() => this.onClose()} style={{ position: 'absolute', right: 5, top: 5 }}>
            <CloseIcon />
          </IconButton>
          <DialogTitle id="alert-dialog-slide-title" style={{ marginRight: 40, marginTop: -3 }}>
            <span
              style={{
                fontWeight: 'bold',
                margin: 0,
                marginBottom: 20,
                ...titleStyle,
              }}
            >
              {title}
            </span>
          </DialogTitle>
          <div className={classes.innerContainer}>
            <div id="alert-dialog-slide-description">
              <h5 className={classes.bodyText} style={{ ...bodyTextStyle }}>
                {bodyText || ''}
              </h5>
              {content || ''}
            </div>
          </div>
          <DialogActions className={classes.actionsContainer}>
            <MDButton
              title={cancelText || translations.t('modals.close')}
              onClick={() => this.onDelete()}
              backgroundColor="#DB4437"
              containerstyle={{ margin: 0, marginRight: 20 }}
            />
            {onConfirm && (
              <MDButton
                title={confirmText || translations.t('modals.confirm')}
                onClick={() => this.onConfirm()}
                backgroundColor={EDULAI_BLUE}
                containerstyle={{ margin: 0, marginRight: 20 }}
              />
            )}
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MDDialogDelete);
