
import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';
/*
Questo endpoint può fare la GET delle skill normali se parenId = 0, altrimenti se è valorizzato fetcha le sotto-skill di quel parentId
*/
export function fetchSystemSkills(params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/skills`, paramsToSend);
}


export function fetchSystemSkillsByName(params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/skills/skillByName`, paramsToSend);
}


/*
Lasciare sempre parentId a 0 se si sta creando una skill. Se si desidera creare una sotto-skill usare l'apposito endpoint
{
  "name": "string",
  "parentId": 0
} 
*/
export function createSkill(skillDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/skills`, skillDTO);
}


/*
subSkillDTO, parentId è l'id della skill di riferimento
{
  "name": "string",
  "parentId": 0
}
*/
export function createSubSkill(mainSkillId, subSkillDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/skills/subSkills`, subSkillDTO);
}


export function deleteSkill(skillId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/skills/${skillId}`);
}

export function deleteSubSkill(skillId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/skills/${skillId}`);
}

export function updateSkill(skillId, skillDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/skills/${skillId}`, skillDTO);
}