import _ from 'lodash';
import React from 'react';
import SelectedStar from '../../assets/star_full.png';
import UnselectedStar from '../../assets/star_empty.png';
import StarHalf from '../../assets/star_half.png';
import { EDULAI_ORANGE } from '../../styles/styleConsts';
import InfoButton from '../Buttons/InfoButton';

const StarRateView = ({
  value,
  totalValues,
  onShowInfo,
  titleContainerStyle,
  minimumValue,
  title,
  titleStyle,
  starStyle,
  unselectedStarStyle,
  starContainerStyle
}) => (
  <div style={{ ...starContainerStyle }}>
    <div
      style={{
        display: 'flex',
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center',
        ...titleContainerStyle
      }}
    >
      {title && <h4 style={{ margin: 0, ...titleStyle }}>{title}</h4>}
      {onShowInfo && <InfoButton onClick={() => onShowInfo()} />}
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {_.times(totalValues, (currentValue) => {
        const normalizedCurrentValue = currentValue + 1;
        const isSelected = value >= normalizedCurrentValue || normalizedCurrentValue === minimumValue;
        const isHalf = value > currentValue && value < normalizedCurrentValue;
        return (
          <div key={currentValue}>
            {isSelected && !isHalf && (
              <img
                alt="evaluation"
                src={SelectedStar}
                style={{ ...starStyle, marginRight: 12, color: EDULAI_ORANGE }}
              />
            )}
            {isHalf && <img alt="evaluation" src={StarHalf} style={{ marginRight: 15, ...starStyle }} />}
            {!isSelected && !isHalf && (
              <img
                alt="evaluation"
                src={UnselectedStar}
                style={{ ...unselectedStarStyle, marginRight: 12, marginBottom: 0 }}
              />
            )}
          </div>
        );
      })}
    </div>
  </div>
);

export default StarRateView;
