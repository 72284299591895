import _ from 'lodash';

import * as SkillsAPI from '../../api/skillsAPI';
import {
  RESET_SKILLS_DATA,
  RESET_SKILLS_STATE,
  SAVE_OR_UPDATE_SKILL,
  SAVE_SKILLS,
  SAVE_SUB_SKILLS,
  SET_SELECTED_SKILL,
  SET_SELECTED_SUB_SKILL,
  UPDATE_SUB_SKILL,
  UPDATE_SUB_SKILL_IN_SELECTED_SKILL,
  UPDATE_SUB_SKILL_IN_SUBSKILLS,
  RESET_SUB_SKILLS,
} from './actionTypes/skills';

export function resetSubSkills() {
  return {
    type: RESET_SUB_SKILLS,
  };
}

export function updateSubSkillInState(subSkill) {
  return {
    type: UPDATE_SUB_SKILL,
    subSkill,
  };
}

export function updateSubSkillInSubSkills(subSkill) {
  return {
    type: UPDATE_SUB_SKILL_IN_SUBSKILLS,
    subSkill,
  };
}

export function updateSubSkillInSelected(subSkill) {
  return {
    type: UPDATE_SUB_SKILL_IN_SELECTED_SKILL,
    subSkill,
  };
}

export function saveSkills(skills) {
  return {
    type: SAVE_SKILLS,
    skills,
  };
}

export function saveOrUpdateSkillInState(skill) {
  return {
    type: SAVE_OR_UPDATE_SKILL,
    skill,
  };
}

export function setSelectedSkill(skill) {
  return {
    type: SET_SELECTED_SKILL,
    skill,
  };
}

export function saveSubSkill(skillId, subSkill) {
  return {
    type: SAVE_SUB_SKILLS,
    skillId,
    subSkill,
  };
}

export function setSelectSubSkill(subSkill) {
  return {
    type: SET_SELECTED_SUB_SKILL,
    subSkill,
  };
}


export function resetSkillsData() {
  return { type: RESET_SKILLS_DATA };
}

export function resetSkillsState() {
  return { type: RESET_SKILLS_STATE };
}

export function fetchSkills() {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.fetchSystemSkills({ pageSize: 100 });
      if (skillsResponse && skillsResponse.data) {
        dispatch(saveSkills(skillsResponse.data));
        return skillsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function fetchSkillsAndSubSkills() {
  return async (dispatch, getState) => {
    try {
      const skillsResponse = await SkillsAPI.fetchSystemSkills({ pageSize: 100 });
      if (skillsResponse && skillsResponse.data) {
        const skills = skillsResponse.data.content;
        dispatch(saveSkills(skillsResponse.data));
        const approvationCalls = _.map(skills, async (skill) => {
          try {
            const subSkills = await dispatch(fetchSubSkills(skill.id));
            return subSkills;
          } catch (error) {
            return skills;
          }
        });
        dispatch(resetSubSkills());
        await Promise.all(approvationCalls);
        return skills;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function fetchSubSkills(skillId) {
  return async (dispatch) => {
    try {
      const subSkillsResponse = await SkillsAPI.fetchSystemSkills({ parentId: skillId, pageSize: 100 });
      if (subSkillsResponse && subSkillsResponse.data && subSkillsResponse.data.content) {
        dispatch(saveSubSkill(skillId, subSkillsResponse.data.content));
        return subSkillsResponse;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}


export function createSkill(skillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.createSkill({ parentId: null, ...skillDTO });
      if (skillsResponse && skillsResponse.data) {
        dispatch(saveOrUpdateSkillInState(skillsResponse.data));
        return skillsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function deleteSkill(skillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.deleteSkill(skillDTO.id);
      if (skillsResponse && skillsResponse.data) {
        dispatch(saveOrUpdateSkillInState(skillsResponse.data));
        return skillsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function updateSkill(skillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.updateSkill(skillDTO.id, skillDTO);
      if (skillsResponse && skillsResponse.data) {
        dispatch(saveOrUpdateSkillInState(skillsResponse.data));
        return skillsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function updateSubSkill(subSkillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.updateSkill(subSkillDTO.id, subSkillDTO);
      if (skillsResponse && skillsResponse.data) {
        dispatch(updateSubSkillInState(skillsResponse.data));
        dispatch(updateSubSkillInSelected(skillsResponse.data));
        dispatch(updateSubSkillInSubSkills(skillsResponse.data));
        return skillsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createSubSkill(mainSkill, subSkillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.createSubSkill(mainSkill.id, { parentId: mainSkill.id, ...subSkillDTO });
      if (skillsResponse && skillsResponse.data) {
        const updatedSkill = { ...mainSkill, subSkills: [...mainSkill.subSkills, skillsResponse.data] };
        dispatch(saveOrUpdateSkillInState(updatedSkill));
        return updatedSkill;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}

export function deleteSubSkill(mainSkill, subSkillDTO) {
  return async (dispatch) => {
    try {
      const skillsResponse = await SkillsAPI.deleteSubSkill(subSkillDTO.id);
      if (skillsResponse) {
        const filteredSubSkills = _.filter(mainSkill.subSkills, (subSkill) => subSkill.id !== subSkillDTO.id);
        const updatedSkill = { ..._.omit(mainSkill, 'subSkills'), subSkills: filteredSubSkills };
        dispatch(saveOrUpdateSkillInState(updatedSkill));
        return updatedSkill;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  }
}


