import _ from 'lodash';
import ReactGA from 'react-ga4';
import * as SubmissionsAPI from '../../api/submissionsAPI';
import { SUBMISSION_STATUS_TYPES } from '../../config/configurations';
import { SAVE_QUIZ_SUBMISSIONS, SAVE_USER_SUBMISSIONS } from './actionTypes/submissions';

export function saveQuizSubmissions(quizId, submissions) {
  return {
    type: SAVE_QUIZ_SUBMISSIONS,
    quizId,
    submissions,
  };
}

export function saveUserSubmissions(submissions) {
  return {
    type: SAVE_USER_SUBMISSIONS,
    submissions,
  };
}

export function fetchQuizSubmissions(
  quizId,
  submissionsStatusType = null,
  page = 0,
  pageSize = 100,
) {
  return async (dispatch) => {
    let params = {
      page,
      pageSize,
    };
    try {
      let response = await SubmissionsAPI.getSubmissionByQuiz(
        quizId,
        submissionsStatusType,
        params,
      );
      if (!response || !response.data) throw new Error();
      const submissions = response && response.data ? response.data.content : [];
      while (!response.data.last) {
        params = {
          ...params,
          page: response.data.number + 1,
        };
        response = await SubmissionsAPI.getSubmissionByQuiz(
          quizId,
          submissionsStatusType,
          params,
        );
        if (!response || !response.data) throw new Error();
        submissions.push(...response.data.content);
      }
      dispatch(saveQuizSubmissions(quizId, submissions));
      return response.data.content;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllSubmittedSubmissions(quizz) {
  return async (dispatch, getState) => {
    try {
      const {
        user: { data: { id: userId } },
      } = getState();
      let submissionsParams = {
        quizId: quizz.id,
        submissionsStatusType: SUBMISSION_STATUS_TYPES.SUBMITTED,
        page: 0,
        pageSize: 100,
      };
      let submissions = await SubmissionsAPI.getSubmissionByQuiz(quizz.id, userId, submissionsParams);
      let allSubmissions = submissions && submissions.data && submissions.data.content ? submissions.data.content : [];
      while (!submissions.data.last) {
        submissionsParams = {
          quizId: quizz.id,
          submissionsStatusType: SUBMISSION_STATUS_TYPES.SUBMITTED,
          page: submissions.data.number + 1,
          pageSize: 100,
        };
        submissions = await SubmissionsAPI.getSubmissionByQuiz(quizz.id, userId, submissionsParams);
        if (submissions && submissions.data && submissions.data.content) {
          allSubmissions.push(...submissions.data.content);
        }
      }
      return allSubmissions;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSubmissionsByUser(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    const {
      user: { data: user },
    } = getState();
    const params = {
      page,
      pageSize,
    };
    try {
      const response = await SubmissionsAPI.getSubmissionsByUser(user.id, params);
      if (response && response.data) {
        dispatch(saveUserSubmissions(response.data.content));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function startNewSubmission() {
  return async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
      user: { data: user },
    } = getState();
    const submissionInDTO = {
      quizId: quiz.id,
      userId: user.id,
    };
    try {
      const response = await SubmissionsAPI.createSubmission(submissionInDTO);
      if (response && response.data) {
        ReactGA.event({
          category: 'Submissions',
          action: 'USER_START_SUBMISSION',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${user.name} ${user.surname}`,
        });
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function finishSubmission() {
  return async (dispatch, getState) => {
    const {
      quizzes: { selectedQuiz: quiz },
      submissions: { data: { user: submissionsData } },
      user: { data: user },
    } = getState();
    const submission = _.find(submissionsData, { quizId: quiz.id });
    try {
      const response = await SubmissionsAPI.updateSubmission(
        submission.id,
        SUBMISSION_STATUS_TYPES.SUBMITTED,
      );
      if (response && response.data) {
        ReactGA.event({
          category: 'Submissions',
          action: 'USER_FINISH_QUIZ_SUBMISSION',
          label: `Quiz: ${quiz.id} ${quiz.name} - User: ${user.name} ${user.surname}`,
        });
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}
