import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

export async function fetchQuestions(params) {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/questions`, paramsToSend);
}

/* Question creation from backoffice
questionInDTO
{
  "body": "string",
  "measuredSkillsIds": [
    0
  ],
  "name": "string",
  "options": "string"
}
*/
export function createQuestion(questionInDTO) {
  return edulaiInstance.post(`/api/${API_VERSION}/questions`, questionInDTO);
}

export function updateQuestion(questionUpdateInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/questions/updateQuestion`, questionUpdateInDTO);
}

export async function getQuestion(questionId) {
  return edulaiInstance.get(`/api/${API_VERSION}/questions/${questionId}`);
}

export function deleteQuestion(questionId) {
  return edulaiInstance.delete(`/api/${API_VERSION}/questions/${questionId}`, questionId);
}
