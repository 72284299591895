//
// ────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: Q U E S T I O N S   A C T I O N   T Y P E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────
//

export const SAVE_QUESTIONS = 'SAVE_QUESTIONS';
export const SAVE_QUESTIONS_PAGINATION = 'SAVE_QUESTIONS_PAGINATION';
export const RESET_QUESTIONS_FILTERS = 'RESET_QUESTIONS_FILTERS';
export const SET_QUESTIONS_FILTER = 'SET_QUESTIONS_FILTER';
export const RESET_QUESTIONS_DATA = 'RESET_QUESTIONS_DATA';
export const APPEND_QUESTIONS = 'APPEND_QUESTIONS';
export const SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const ADD_OPTION = 'ADD_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';
export const RESET_OPTIONS = 'RESET_OPTIONS';
export const EDIT_OPTION = 'EDIT_OPTION';
export const SAVE_QUESTION = 'SAVE_QUESTION';
export const DELETE_QUESTION_FROM_STATE = 'DELETE_QUESTION_FROM_STATE';