import { Card, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BackArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit, initialize } from 'redux-form';
import loginBackground from '../assets/login.png';
import EdulaiLogo from '../assets/logo.png';
import OnboardingForm from '../components/Forms/UsersForm/OnboardingForm';
import MDButton from '../components/MDButton/MDButton';
import * as PlatformUsersActions from '../redux/actions/platformUsers.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import * as ModalsActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../styles/styleConsts';
import translations from '../translations/i18next';
import { isMobileBrowser } from '../utils/utilsFunctions';
import CircleLogo from '../components/Logo/CircleLogo';
import { Translations } from '../translations/Translations';

const styles = (theme) => ({
  container: {
    padding: isMobileBrowser() ? 0 : 20,
    height: isMobileBrowser() ? '170vh' : 'unset',
    overflowY: 'scroll',
    paddingBottom: 200
  },
  title: {
    margin: 20,
    marginLeft: 250,
    marginRight: 250,
    fontWeight: '200',
    textAlign: 'center',
    color: EDULAI_PURPLE
  },
  chartsContainer: {
    marginTop: 10
  },
  cardInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: isMobileBrowser() ? 50 : 0
  },
  backToLoginButton: {
    position: 'absolute',
    top: 5,
    left: 5
  }
});

class UserOnboardingView extends Component {
  componentDidMount() {
    const {
      dispatch,
      skills: { content: skillsData }
    } = this.props;
    dispatch(initialize('OnboardingForm', { skillsData }));
  }

  async onOnboardingFinish(onBoardingData) {
    const { history, user, dispatch } = this.props;
    try {
      const userId = user && user.data && user.data.id;
      dispatch(UtilsActions.setSpinnerVisible(true));
      await dispatch(PlatformUsersActions.confirmUserOnboarding(userId, true, onBoardingData));
      try {
        await dispatch(PlatformUsersActions.updateUserCustomFieldsFromOnboarding(userId, onBoardingData));
      } catch (error) {}
      await dispatch(UserActions.fetchUser());
      dispatch(UtilsActions.setSpinnerVisible(false));
      history.push('/quizzes');
    } catch (error) {
      // Fallback on quizz anyway. The onboarding will be proposed on the next login.
      dispatch(UtilsActions.setSpinnerVisible(false));
      dispatch(
        ModalsActions.showModal('ONBOARDING_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: translations.t('users.onboardingFailed')
          }
        })
      );
    }
  }

  onBackToLogin() {
    const { dispatch, history } = this.props;
    dispatch(UserActions.userLogout());
    history.push('/login');
  }

  render() {
    const {
      classes,
      domain: { data: domainData },
      form,
      language,
      dispatch,
      skills: { content: skillsData }
    } = this.props;
    const isMobile = isMobileBrowser();
    return (
      <div
        className={classes.container}
        style={{
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Card
          style={{
            marginTop: 50,
            marginBottom: 50,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 8,
            width: isMobile ? 'unset' : '90%'
          }}
        >
          <div className={classes.cardInnerContainer}>
            <IconButton className={classes.backToLoginButton} onClick={() => this.onBackToLogin()}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BackArrowIcon />
                <h4 style={{ margin: 0 }}>{translations.t('forms.backtoLogin')}</h4>
              </div>
            </IconButton>
            <img
              src={EdulaiLogo}
              alt="edulai"
              className="logo"
              style={{ margin: 10, marginTop: 40, alignSelf: 'center', height: 120 }}
            />
            <div style={{ position: 'absolute', top: 20, right: 0, zIndex: 1000000 }}>
              <CircleLogo height={isMobile ? 70 : 100} />
            </div>
            <h2 className={classes.title}>
              <Translations>{translations.t('forms.onboardingDescription')}</Translations>
            </h2>
            <OnboardingForm
              customFields={domainData.customFields}
              currentForm={form}
              skills={skillsData}
              language={language}
              onSubmit={(onBoardingData) => this.onOnboardingFinish(onBoardingData)}
            />
          </div>
        </Card>
        <MDButton
          className="gradient-button"
          title={translations.t('forms.startEdulai')}
          backgroundColor={EDULAI_BLUE}
          titleStyle={{ fontSize: 18 }}
          containerstyle={{
            width: isMobile ? 'unset' : '100%',
            height: '100%',
            margin: 'auto',
            marginTop: 100,
            marginBottom: 100,
            marginLeft: isMobile ? 20 : 0,
            marginRight: isMobile ? 20 : 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => dispatch(submit('OnboardingForm'))}
          buttonStyle={{ width: isMobile ? 'unset' : '30%' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage,
  skills: state.skills.data,
  form: state.form.OnboardingForm,
  user: state.user,
  domain: state.domain
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(UserOnboardingView)));
