import Immutable from 'seamless-immutable';
import { SET_SPINNER_VISIBILITY, SET_SELECTED_LANGUAGE } from '../actions/actionTypes/utils';

const initialState = Immutable({
  spinner: {
    isVisible: false,
    title: null,
  },
  selectedLanguage: '',
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SPINNER_VISIBILITY:
      return state.setIn(['spinner', 'isVisible'], action.spinnerVisible).setIn(['spinner', 'title'], action.title);
    case SET_SELECTED_LANGUAGE:
      return state.set('selectedLanguage', action.language);
    default:
      return state;
  }
}
