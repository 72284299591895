//
// ──────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A L L   Q U I Z   S T A T U S E S   V I E W : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────────────
//

import _ from 'lodash';
import React from 'react';
import QuizDoneNoAnswerView from './QuizStatusViews/QuizDoneNoAnswerView';
import QuizPausedView from './QuizStatusViews/QuizPausedView';
import QuizReadyView from './QuizStatusViews/QuizReadyView';
import { EDULAI_BACKGROUND_GRADIENT_COLOR } from '../../styles/styleConsts';
import { SUBMISSION_STATUS_TYPES } from '../../config/configurations';

const statusViewComponents = {
  DONE: QuizDoneNoAnswerView,
  PAUSED: QuizPausedView,
  READY: QuizReadyView
};
/*
 * This component will render the right shooting status view
 * depending on the quiz.quizStatusType variable.
 */
const BaseQuizStatusView = (props) => {
  const { quiz } = props;

  if (!quiz || _.isEmpty(quiz)) {
    return null;
  }
  if (quiz.quizStatusType === 'DONE') {
    const showComponent =
      !props.quizSubmission ||
      (props.quizSubmission && props.quizSubmission.submissionStatusType === SUBMISSION_STATUS_TYPES.DRAFT);
    if (!showComponent) return null;
  }
  const Component = statusViewComponents[quiz.quizStatusType];
  if (!Component) return null;

  return (
    <div
      style={{
        textAlign: 'center',
        margin: 'auto',

        borderRadius: 8,
        width: '60%',
        marginTop: 50
      }}
    >
      <div
        style={{
          background: EDULAI_BACKGROUND_GRADIENT_COLOR,
          height: 90,
          display: 'flex',
          alignItems: 'center',
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        }}
      >
        <h1 style={{ color: 'white', marginLeft: 20 }}>{quiz.name}</h1>
      </div>
      <div style={{ padding: 30, backgroundColor: 'white' }}>
        <Component key={quiz.id} quiz={quiz} {...props} />
      </div>
    </div>
  );
};

export default BaseQuizStatusView;
