import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import IntroPage from './IntroductionPage';
import CoverPage from './CoverPage';
import SkillsTablePage from './SkillsTablePage';
import { map } from 'lodash';
import ChartPage from './ChartPage';
import NunitoRegular from './assets/fonts/Nunito-Regular.ttf';
import NunitoBold from './assets/fonts/Nunito-Bold.ttf';
import NunitoLight from './assets/fonts/Nunito-Light.ttf';

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal'
    },
    {
      src: NunitoBold,
      fontWeight: 'bold'
    },
    {
      src: NunitoLight,
      fontWeight: 'light'
    }
  ]
});

const styles = StyleSheet.create({
  cover: {
    position: 'relative',
    padding: 50,
    flexDirection: 'column'
  },
  page: {
    position: 'relative',
    paddingLeft: 64,
    paddingRight: 50,
    paddingBottom: 30
  }
});

// Create PDF document with background image and content
export const UserPdfReport = (props) => (
  <Document>
    <Page size="A4" style={styles.cover}>
      <CoverPage user={props.data.user} />
    </Page>

    <IntroPage user={props.data.user} skills={props.data.skillsData} />

    <Page size="A4" style={styles.page}>
      <ChartPage
        chart={props.data.chart}
        user={props.data.user}
        skillsAnalytics={props.data.skillsAnalytics}
        selfAssessmentData={props.data.selfAssessmentData}
        language={props.data.language}
      />
    </Page>
    {map(props.data.skillsData, (skill, index) => (
      <Page key={index} size="A4" style={styles.page}>
        <SkillsTablePage
          index={index}
          user={props.data.user}
          skill={skill}
          skillsAnalytics={props.data.skillsAnalytics}
          subSkillsAnalytics={props.data.subSkills[skill.id]}
          subSkills={props.data.subSkillsFlat[skill.id]}
          language={props.data.language}
        />
      </Page>
    ))}
  </Document>
);
