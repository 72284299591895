import Immutable from 'seamless-immutable';

import { SAVE_ANSWERS, SAVE_SUBMISSION_ANSWERS } from '../actions/actionTypes/answers';

const initialState = Immutable({
  data: {
    content: [],
  },
  submissions: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ANSWERS:
      return state.setIn(['data', 'content'], action.answers);
    case SAVE_SUBMISSION_ANSWERS:
      return state.setIn(['submissions', action.submission], action.answers);
    default:
      return state;
  }
}
