//
// ──────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: Q U E S T I O N S   A C T I O N S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────
//
import _ from 'lodash';

import * as QuestionsAPI from '../../api/questionsAPI';
import {
  ADD_OPTION,
  APPEND_QUESTIONS,
  DELETE_OPTION,
  EDIT_OPTION,
  RESET_OPTIONS,
  RESET_QUESTIONS_DATA,
  RESET_QUESTIONS_FILTERS,
  SAVE_QUESTIONS,
  SAVE_QUESTION,
  SAVE_QUESTIONS_PAGINATION,
  SET_QUESTIONS_FILTER,
  SET_SELECTED_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION_FROM_STATE,
} from './actionTypes/questions';

function saveQuestion(question) {
  return {
    type: SAVE_QUESTION,
    question,
  };
}

export function saveQuestions(questions) {
  return {
    type: SAVE_QUESTIONS,
    questions,
  };
}

export function saveQuestionsPagination(pagination) {
  return {
    type: SAVE_QUESTIONS_PAGINATION,
    pagination,
  };
}

export function setQuestionsFilter(field, value) {
  return {
    type: SET_QUESTIONS_FILTER,
    field,
    value,
  };
}

export function resetQuestionsFilters() {
  return {
    type: RESET_QUESTIONS_FILTERS,
  };
}

export function resetQuestionsData() {
  return { type: RESET_QUESTIONS_DATA };
}

export function appendQuestions(questions) {
  return {
    type: APPEND_QUESTIONS,
    questions,
  };
}

export function setSelectedQuestion(question) {
  return {
    type: SET_SELECTED_QUESTION,
    question,
  };
}

export function updateQuestionInState(question) {
  return {
    type: UPDATE_QUESTION,
    question,
  };
}

export function addOption(option) {
  return {
    type: ADD_OPTION,
    option,
  };
}

export function deleteOption(option) {
  return {
    type: DELETE_OPTION,
    option,
  };
}

export function resetOptions() {
  return {
    type: RESET_OPTIONS,
  };
}

export function editOption(option) {
  return {
    type: EDIT_OPTION,
    option,
  };
}

export function deleteQuestionFromState(questionId) {
  return {
    type: DELETE_QUESTION_FROM_STATE,
    questionId,
  };
}

export function fetchQuestions(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const {
        questions: { data: { filters } },
      } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await QuestionsAPI.fetchQuestions(params);
      if (response && response.data && response.data.content) {
        if (page === 0) {
          dispatch(saveQuestions(response.data.content));
        } else {
          dispatch(appendQuestions(response.data.content));
        }
        dispatch(saveQuestionsPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createQuestion(questionDTO) {
  return async (dispatch, getState) => {
    const {
      questions: { selectedQuestion: { options } },
    } = getState();
    try {
      const measuredSkillsIds = _.map(
        questionDTO.skills,
        (subskill) => subskill.value,
      );

      const newQuestion = {
        ...questionDTO,
        measuredSkillsIds,
        options: {
          optionDTOS: _.map(options, (option, index) => ({
            ...option,
            optionNumber: index + 1,
            skills: _.map(option.skills, (value, key) => ({
              skillId: parseInt(key.slice(1), 10),
              score: value,
            })),
          })),
        },
      };

      const response = await QuestionsAPI.createQuestion(newQuestion);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function modifyQuestion(questionDTO) {
  return async (dispatch, getState) => {
    const {
      questions: { selectedQuestion: { options } },
    } = getState();
    let updatedQuestion = {};

    const measuredSkillsIds = _.map(
      questionDTO.skills,
      (subskill) => subskill.value,
    );

    const newQuestion = {
      ...questionDTO,
      measuredSkillsIds,
      options: {
        optionDTOS: _.map(options, (option) => ({
          ...option,
          skills: _.map(option.skills, (value, key) => ({
            skillId: parseInt(key.slice(1), 10),
            score: value,
          })),
        })),
      },
    };

    try {
      const response = await QuestionsAPI.updateQuestion(newQuestion);
      if (response && response.data) updatedQuestion = response.data;
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
    dispatch(updateQuestionInState(updatedQuestion));
    return updatedQuestion;
  };
}

export function fetchQuestionById(questionId) {
  return async (dispatch) => {
    try {
      const response = await QuestionsAPI.getQuestion(questionId);
      if (response && response.data) {
        dispatch(saveQuestion(response.data));
      }
      return response.data;
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function fetchAllQuizQuestions(quiz) {
  return async (dispatch) => {
    try {
      const approvationCalls = _.map(quiz.questionsIds, async (questionId) => {
        const response = await QuestionsAPI.getQuestion(questionId);
        if (response && response.data) {
          return response.data;
        }
      });
      const questions = await Promise.all(approvationCalls);
      return questions;
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function deleteQuestion(questionId) {
  return async (dispatch) => {
    try {
      const questionDeleteResponse = await QuestionsAPI.deleteQuestion(questionId);
      if (questionDeleteResponse && questionDeleteResponse.data) {
        dispatch(deleteQuestionFromState(questionId));
        return questionDeleteResponse.data;
      }
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  }
}
