//
// ──────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: R O L E S   A C T I O N   T Y P E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────
//


module.exports = {
  SAVE_DOMAIN_DATA: 'SAVE_DOMAIN_DATA',
  RESET_DOMAIN_DATA: 'RESET_DOMAIN_DATA',
};