import React from 'react';
import logo from '../../assets/logo.png';

const EdulaiSkillCheckerLogo = ({ height }) => (
  <img
    src={logo}
    alt="edulai"
    className="logo"
    style={{
      margin: 10,
      marginTop: 0,
      alignSelf: 'center',
      height,
    }}
  />
);

export default EdulaiSkillCheckerLogo;
