
import { edulaiInstance } from './instances/edulaiInstance';
import { API_VERSION } from '../config/configurations';


export function fetchDomainInfo(domainId) {
  return edulaiInstance.get(`/api/${API_VERSION}/domains/${domainId}`);
}


export function createCustomField(domainId, domainUpdateInDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/domains/${domainId}`, domainUpdateInDTO);
}

/* domainTypeDTO = { domainType: FLAT / PPU } */
export function updateDomainType(domainId, domainTypeDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/domains/${domainId}/domainType`, domainTypeDTO);
}

/* domainEnabledDTO = { credits: X } */
export function addDomainCredits(domainId, domainCreditsDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/domains/${domainId}/addCredits`, domainCreditsDTO);
}

export function setDomainCredits(domainId, domainCreditsDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/domains/${domainId}/setCredits`, domainCreditsDTO);
}


/* domainEnabledDTO = { enabled: true } */
export function updateDomainEnableStatus(domainId, domainEnabledDTO) {
  return edulaiInstance.put(`/api/${API_VERSION}/domains/${domainId}/enabled`, domainEnabledDTO);
}