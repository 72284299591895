import { API_VERSION } from '../config/configurations';
import { edulaiInstance } from './instances/edulaiInstance';

/* eslint-disable-next-line import/prefer-default-export */
export const fetchTags = (params) => {
  const paramsToSend = {
    params,
  };
  return edulaiInstance.get(`/api/${API_VERSION}/userTags`, paramsToSend);
};
