import { IconButton } from '@material-ui/core';
import React from 'react';
import { EDULAI_BLUE } from '../../styles/styleConsts';
import { InfoOutlined } from '@material-ui/icons';

const InfoButton = ({ onClick }) => {
  return (
    <div style={{ backgroundColor: EDULAI_BLUE, marginLeft: 20, borderRadius: 32 }}>
      <IconButton onClick={onClick} style={{ margin: 0, padding: 8 }}>
        <InfoOutlined fontSize="medium" style={{ color: 'white' }} />
      </IconButton>
    </div>
  );
};

export default InfoButton;
