import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm } from 'redux-form';

import translations from '../../../translations/i18next';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import SubSkillScoreField from '../FormsComponents/SubSkillScore/SubSkillScoreField';
import { EDULAI_BLUE } from '../../../styles/styleConsts';

const validate = (values) => {
  const errors = {};
  if (!values.body) {
    errors.body = translations.t('forms.required');
  }
  return errors;
};

const styles = () => ({
  formContainer: {
    width: 800
  },
  title: {
    margin: 0,
    marginTop: 20
  },
  headerTitle: {
    marginLeft: 20
  },
  subtitle: {
    margin: 0,
    fontWeight: '100',
    marginBottom: 20
  },
  enabledText: {
    color: '#66c0b0',
    margin: 0
  },
  disabledText: {
    color: 'red',
    margin: 0
  },
  statusContainer: {
    marginLeft: 10,
    marginBottom: 20
  },
  statusTag: {
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_BLUE },
    secondary: { main: '#CC0033' }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

const AnswerForm = (props) => {
  const { classes, subskills } = props;

  const subskillsScores = _.map(_.sortBy(subskills, ['value']), (subskill, index) => (
    <Field
      name={`_${subskill.value}`}
      containerstyle={{ marginBottom: 10 }}
      component={SubSkillScoreField}
      label={subskill.label}
      required
      validate={(value) => {
        if (!value) {
          return translations.t('forms.required');
        }
        return '';
      }}
    />
  ));

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.formContainer}>
        <Field
          name="body"
          variant="outlined"
          containerstyle={{ marginBottom: 20 }}
          component={MDTextInputField}
          label={translations.t('forms.text')}
          required
          multiline
          rows="3"
        />
        <FormSection name="skills">
          <div style={{ display: 'grid' }}>{subskillsScores}</div>
        </FormSection>
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  skills: state.skills
});

export default _.flow([
  connect(mapStateToProps),
  reduxForm({
    form: 'AnswerForm',
    validate,
    destroyOnUnmount: false
  }),
  withStyles(styles)
])(AnswerForm);
