import React from 'react';
import { connect } from 'react-redux';

export default function fetchConfig(fetchConfFn, DefaultComponent = null) {
  return (Component) => {
    class ConfiguredComponent extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          canRender: true,
        };
      }

      async componentDidMount() {
        try {
          await fetchConfFn();
          this.setState({ canRender: true });
        } catch (error) {
          this.setState({ canRender: false });
        }
      }

      render() {
        const { canRender } = this.state;
        if (!canRender) {
          return DefaultComponent ? <DefaultComponent /> : null;
        }
        return <Component {...this.props} {...this.state} />;
      }
    }

    return connect()(ConfiguredComponent);
  };
}
