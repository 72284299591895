import Immutable from 'seamless-immutable';

import { SAVE_TAGS } from '../actions/actionTypes/tags';

const initialState = Immutable({
  data: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_TAGS:
      return state.set('data', action.tags);
    default:
      return state;
  }
}
