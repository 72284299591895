import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import translations from '../../../translations/i18next';
import MDCheckBoxField from '../FormsComponents/MDCheckbox/MDCheckBoxField';
import { EDULAI_BLUE } from '../../../styles/styleConsts';
import { PRIVACY_POLICY_LINKS } from '../../../config/configurations';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
    errors.email = 'required';
  }
  if (!values.name) {
    errors.name = 'required';
  }
  if (!values.surname) {
    errors.surname = 'required';
  }
  if (!values.privacyAccepted) {
    errors.privacyAccepted = translations.t('forms.required');
  }
  return errors;
};


const RegisterForm = (props) => (
  <div style={{ marginRight: 20 }}>
    <Field
      name="name"
      label={translations.t('forms.firstName')}
      placeholder={translations.t('login.insertFirstName')}
      component={MDTextInputField}
      required
      multiline
      containerstyle={{ marginTop: 20 }}
    />
    <Field
      name="surname"
      label={translations.t('forms.lastName')}
      placeholder={translations.t('login.insertLastName')}
      component={MDTextInputField}
      required
      multiline
      containerstyle={{ marginTop: 20 }}
    />
    <Field
      name="email"
      component={MDTextInputField}
      label="Email"
      placeholder={translations.t('login.insertEmail')}
      onFormatText={(text) => text.toLowerCase()}
      required
      multiline
      containerstyle={{ marginTop: 20 }}
    />
    <Field
      name="privacyAccepted"
      component={MDCheckBoxField}
      label={
        (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <h4 id="circular-bold-label" style={{ color: '#3f3f3f', fontSize: 15, margin: 0, marginRight: 5, fontWeight: 100 }}>{translations.t('forms.iAcceptThe')}</h4>
            <h4 id="circular-bold-label" className="link-label" style={{ fontSize: 15, margin: 0, color: EDULAI_BLUE, fontWeight: 100 }} onClick={(e) => { e.preventDefault(); window.open(PRIVACY_POLICY_LINKS[props.language || "it"]); }}>
              {translations.t('forms.privacyPolicy')}<span style={{ color: '#D71F4B' }}>*</span>
            </h4>
          </div>
        )
      }
      required
      showErrorLabel
      containerstyle={{ marginTop: 20 }}
    />
  </div>
);

const mapStateToProps = (state) => ({
  language: state.utils.selectedLanguage
});

export default connect(mapStateToProps)(reduxForm({
  form: 'RegisterForm',
  validate,
})(RegisterForm));
