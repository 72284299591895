import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import layout from '../../../assets/layout_report.png';
import Footer from './Footer';
import Header from './Header';
import { compact, filter, map, maxBy, orderBy } from 'lodash';
import { EDULAI_BLUE, EDULAI_ORANGE, EDULAI_PURPLE } from '../../../styles/styleConsts';
import translations from '../../../translations/i18next';
import ReportText from './ReportText';
import { joinWithCommas } from '../../../utils/Report/ReportUtils';
import strenghtImage from '../../../assets/strengths.png';
import weaknessImage from '../../../assets/weaknesses.png';
import ReportTextHtml from './ReportTextHtml';

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
    color: EDULAI_PURPLE
  },
  section: {
    flex: 1,
    margin: 10,
    fontSize: 12,
    borderRadius: 8
  },
  leftHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 8,
    color: EDULAI_ORANGE,
    alignSelf: 'center'
  },
  rightHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 8,
    color: EDULAI_BLUE,
    alignSelf: 'center'
  },
  leftBackgroundContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#f57d24',
    opacity: 0.12,
    borderRadius: 8
  },
  rightBackgroundContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#4d66b9',
    opacity: 0.08,
    borderRadius: 8
  }
});

const Chart = ({ skillsAnalytics, selfAssessmentData, user, chart, language }) => {
  const selfAssessmentDataValue = orderBy(selfAssessmentData, (skill) => skill.skillId);

  const scoreDifference = compact(
    map(skillsAnalytics, (skill, index) => {
      if (!skill.value) {
        return null;
      }
      return {
        name: skill.name,
        value: Number(selfAssessmentDataValue[index].score) - Number(skill.value)
      };
    })
  );

  const diff1 = filter(scoreDifference, (diff) => diff.value <= -1);
  const diff2 = filter(scoreDifference, (diff) => diff.value >= 1);
  const diff3 = filter(scoreDifference, (diff) => Math.abs(diff.value) < 0.9);

  const countSkillMap = [
    { key: 'skillReport1', count: diff1.length, skills: map(diff1, 'name') },
    { key: 'skillReport2', count: diff2.length, skills: map(diff2, 'name') },
    { key: 'skillReport3', count: diff3.length, skills: map(diff3, 'name') }
  ];

  const max = maxBy(countSkillMap, 'count');

  const highScores = filter(skillsAnalytics, (skill) => Number(skill.value) >= 4);
  const lowScores = filter(skillsAnalytics, (skill) => Number(skill.value) <= 2 && Number(skill.value) > 0);
  const threeScoresList = filter(skillsAnalytics, (skill) => Number(skill.value) > 2 && Number(skill.value) < 4);

  return (
    <>
      <Image style={styles.backgroundImage} src={layout} fixed />
      <Header user={user} />
      <ReportText style={{ marginTop: 5, color: EDULAI_BLUE, fontSize: 14, fontWeight: 'bold' }}>
        {translations.t('skillsReport.skillsChartResultsTitle')}
      </ReportText>
      <View style={{ marginTop: 20 }}>
        <Image src={chart} />
      </View>
      <View style={{ marginTop: 30 }}>
        {map(countSkillMap, (skill, index) => {
          if (skill.count && skill.count === max.count) {
            return (
              <ReportTextHtml key={index} style={styles.text}>
                {translations.t(`skillsReport.${skill.key}`, {
                  skills: joinWithCommas(skill.skills, language)
                })}
              </ReportTextHtml>
            );
          } else {
            return null;
          }
        })}
      </View>

      <View
        style={{ paddingHorizontal: 20, paddingTop: 10, display: 'flex', flexDirection: 'row' }}
        minPresenceAhead={60}
        break
      >
        <View style={styles.section}>
          <View style={styles.leftBackgroundContainer} />
          <View style={{ paddingVertical: 15, paddingHorizontal: 10 }}>
            <Image src={strenghtImage} style={{ height: 40, alignSelf: 'center' }} />
            <ReportText style={styles.leftHeader}>{translations.t(`skillsReport.strenghts`)}</ReportText>
            {highScores.length ? (
              <ReportTextHtml style={styles.text}>
                {translations.t(`skillsReport.skillStrenghtMessage`, {
                  skills: joinWithCommas(map(highScores, 'name'), language)
                })}
              </ReportTextHtml>
            ) : (
              <ReportText style={styles.text}>{translations.t(`skillsReport.noStrenghts`)}</ReportText>
            )}
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.rightBackgroundContainer} />
          <View style={{ paddingVertical: 15, paddingHorizontal: 10 }}>
            <Image src={weaknessImage} style={{ height: 40, alignSelf: 'center' }} />
            <ReportText style={styles.rightHeader}>{translations.t(`skillsReport.weaknesses`)}</ReportText>
            {lowScores.length || threeScoresList.length ? (
              <>
                <ReportTextHtml style={styles.text}>
                  {lowScores.length !== 0
                    ? translations.t(`skillsReport.skillWeaknessLowMessage`, {
                        skills: joinWithCommas(map(lowScores, 'name'), language)
                      })
                    : ''}
                </ReportTextHtml>
                <ReportTextHtml style={styles.text}>
                  {threeScoresList.length !== 0
                    ? translations.t(`skillsReport.skillWeaknessMediumMessage`, {
                        skills: joinWithCommas(map(threeScoresList, 'name'), language)
                      })
                    : ''}
                </ReportTextHtml>
              </>
            ) : (
              <ReportText style={styles.text}>{translations.t(`skillsReport.noWeaknesses`)}</ReportText>
            )}
          </View>
        </View>
      </View>
      <Footer />
    </>
  );
};

export default Chart;
